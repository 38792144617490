import React, { useState } from 'react';
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {getDistanceBetweenTwoCordinates} from 'app/shared/model/Map';
import { IRoute } from 'app/shared/model/route.model';
import { IWatotoCareSettings } from 'app/shared/model/watotocare-settings-model';
import { toCents } from 'app/shared/util/money-utils';
import { convertMinutesToSeconds, convertSecondsToMinutes } from 'app/shared/util/date-utils';

interface IRouteUpdateParams {
  opened: boolean;
  toggle: () => void;
  watotocareSettings: IWatotoCareSettings;
  routeEntity: IRoute;
  onSave: (route: IRoute) => void;
}


export const RouteForm = (props: IRouteUpdateParams) => {
  const [isActive, setIsActive] = useState(props.routeEntity?.isActive ?? false);
  const [distance, setDistance] = useState(props.routeEntity?.distance ?? 0);
  const [overridePrice, setOverridePrice] = useState(props.routeEntity?.overridePrice ?? 0);
  const [durationMinutes, setDurationMinutes] = useState(convertSecondsToMinutes(props.routeEntity?.durationSeconds));

  const { routeEntity, watotocareSettings } = props;

  // const handleClose = () => {
  //   props.history.push('/route' + props.location.search);
  // };

  const handleIsActive = (event) => {
    setIsActive(event.target.value);
  };

  const handleDistanceChange = (event) => {
    setDistance(event.target.value);
  };

  const handleOverridePriceChange = (event) => {
    setOverridePrice(event.target.value);
  };

  const handleDurationMinutesChange = (event) => {
    setDurationMinutes(event.target.value);
  };

  function calculateRoutePrice(): number {
    return watotocareSettings[0]?.costKm ? distance * watotocareSettings[0].costKm : distance * watotocareSettings.costKm;
  }

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...routeEntity,
        ...values,
        overridePrice: toCents(values.overridePrice ?? routeEntity.overridePrice),
        durationSeconds: convertMinutesToSeconds(durationMinutes) ?? routeEntity.durationSeconds
      };

      props.onSave(entity);
    }
  };

  const pathLocation = {
    startLocation: {
      latitude: routeEntity.startType === 'VILLAGE' ? routeEntity.startVillage?.locationLat : routeEntity.startFacility?.locationLat,
      longitude: routeEntity.startType === 'VILLAGE' ? routeEntity.startVillage?.locationLon : routeEntity.startFacility?.locationLon
    },

    endLocation: {
      latitude: routeEntity.endType === 'VILLAGE' ? routeEntity.endVillage?.locationLat : routeEntity.endFacility?.locationLat,
      longitude: routeEntity.endType === 'VILLAGE' ? routeEntity.endVillage?.locationLon : routeEntity.endFacility?.locationLon
    }

  };

  const places = [
    { latitude: pathLocation.startLocation.latitude, longitude: pathLocation.startLocation.longitude },
    { latitude: pathLocation.endLocation.latitude, longitude: pathLocation.endLocation.longitude }
  ];

  console.log('pathLocation', pathLocation, places, routeEntity.startVillage);
  return (
    <Modal className="modal-xl" isOpen={props.opened} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>Edit a route</ModalHeader>
      <ModalBody id="watotocareApp.route.edit.form">
        <Row className="justify-content-center">
          <Col md="8">
            <AvForm id="edit-form" model={routeEntity} onSubmit={saveEntity}>
              {(
                <AvGroup>
                  <Label for="route-id">ID</Label>
                  <AvInput id="route-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              )}
              <AvGroup>
                <Label id="distanceLabel" for="route-distance">
                  Distance
                </Label>
                <AvField
                  id="route-distance"
                  type="text"
                  className="form-control"
                  name="distance"
                  onChange={handleDistanceChange}
                  validate={{
                    number: true,
                    pattern: {
                      value: '^(\\d+)?([.]?\\d{0,1})?$',
                      errorMessage: 'Must enter a number with at most 1 decimal e.g. 123.4'
                    }
                  }}
                />
              </AvGroup>
              <AvGroup>
                <Label id="durationMinutesLabel" for="route-durationMinutes">
                  Duration (Minutes)
                </Label>
                <AvField id="route-durationMinutes" type="number" className="form-control" name="durationMinutes"
                         onChange={handleDurationMinutesChange} value={durationMinutes} />
              </AvGroup>
              <AvGroup>
                <Label id="priceLabel" for="calculatedPrice">
                  Current Price
                </Label>
                <AvField id="calculatedPrice" type="text" className="form-control" name="calculatedPrice"
                         value={distance && calculateRoutePrice()} disabled />
              </AvGroup>
              <AvGroup>
                <Label id="overridePriceLabel" for="route-overridePrice">
                  Override Price
                </Label>
                <AvField
                  id="route-overridePrice"
                  type="text"
                  className="form-control"
                  name="overridePrice"
                  onChange={handleOverridePriceChange}
                  validate={{
                    number: true,
                    pattern: {
                      value: '^(\\d+)?([.]?\\d{0,1})?$',
                      errorMessage: 'Must enter a number with at most 1 decimal e.g. 123.4'
                    }
                  }}
                />
              </AvGroup>
              {/* <AvGroup>
                <Label id="descriptionLabel" for="route-description">
                  Description
                </Label>
                <AvField id="route-description" type="text" name="description" />
              </AvGroup> */}
              <AvGroup check>
                <Label id="isActiveLabel">
                  <AvInput
                    id="route-isActive"
                    type="checkbox"
                    className="form-check-input"
                    name="isActive"
                    checked={isActive}
                    value={isActive}
                    onChange={handleIsActive}
                  />
                  Active
                </Label>
              </AvGroup>
              <AvGroup>
                <Label id="startTypeLabel" for="route-startType">
                  Start Type
                </Label>
                {(
                  <AvField id="route-startType" type="text" className="form-control" name="startType" disabled>
                    {routeEntity.startType}
                  </AvField>
                )}
              </AvGroup>
              <AvGroup>
                <Label id="endTypeLabel" for="route-endType">
                  End Type
                </Label>
                {(
                  <AvField id="route-endType" type="text" className="form-control" name="endType" disabled>
                    {routeEntity.endType}
                  </AvField>
                )}
              </AvGroup>
              {/* <AvGroup>
                <Label id="endTypeLabel" for="route-endType">
                  End Type
                </Label>
                <AvInput
                  id="route-endType"
                  type="select"
                  className="form-control"
                  name="endType"
                  value={(routeEntity.endType) || 'HEALTHCARE_FACILITY'}
                  disabled={true}
                >
                  <option value="HEALTHCARE_FACILITY">HEALTHCARE_FACILITY</option>
                </AvInput>
              </AvGroup> */}
              <AvGroup>
                <Label for="route-startVillage">Start Village</Label>
                {
                  routeEntity.startVillage && (
                    <>
                      <AvField id="route-startVillage" type="hidden" className="form-control" name="startVillage.id"
                               value={routeEntity.startVillage.id} />
                      <AvField id="route-startVillageName" type="text" className="form-control" name="startVillage.name"
                               value={routeEntity.startVillage.name} disabled />
                    </>
                  )
                }
              </AvGroup>
              <AvGroup>
                <Label for="route-endVillage">End Village</Label>
                {
                  routeEntity.endVillage && (
                    <>
                      <AvField id="route-endVillage" type="hidden" className="form-control" name="endVillage.id"
                               value={routeEntity.endVillage.id} />
                      <AvField id="route-endVillageName" type="text" className="form-control" name="endVillage.name"
                               value={routeEntity.endVillage.name} disabled />
                    </>
                  )
                }
              </AvGroup>
              <AvGroup>
                <Label for="route-startFacility">Start Facility</Label>
                {routeEntity.startFacility && (
                  <>
                    <AvField id="route-startFacility" type="hidden" className="form-control" name="startFacility.id"
                             value={routeEntity.startFacility.id} />
                    <AvField id="route-startFacilityName" type="text" className="form-control" name="startFacility.name"
                             value={routeEntity.startFacility.name} disabled />
                  </>
                )}
              </AvGroup>
              <AvGroup>
                <Label for="route-endFacility">End Facility</Label>
                {routeEntity.endFacility && (
                  <>
                    <AvField id="route-endFacility" type="hidden" className="form-control" name="endFacility.id"
                             value={routeEntity.endFacility.id} />
                    <AvField id="route-endFacilityName" type="text" className="form-control" name="endFacility.name"
                             value={routeEntity.endFacility.name} disabled />
                  </>
                )}
              </AvGroup>
              {/* <Map/> * map need to integrate here*/}
            </AvForm>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.toggle}>
          <FontAwesomeIcon icon="ban" />
          &nbsp; Back
        </Button>
        <Button color="primary" id="save-entity" type="submit" form="edit-form" disabled={false}>
          <FontAwesomeIcon icon="save" />
          &nbsp; Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RouteForm;
