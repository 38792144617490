import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity as getPatientBaby, reset as resetPatientBaby } from '../patient-baby/patient-baby.reducer';
import {
  createEntity,
  createOutcomeCall,
  getEntities,
  getSettings as getOutcomeSettings,
  reset,
  updateEntity
} from '../patient-outcome/patient-outcome.reducer';
import { reset as resetPatient } from 'app/entities/patient/patient.reducer';
import {
  getEntities as getPatientVisitEntities,
  reset as resetPatientVisit
} from 'app/entities/patient-visit/patient-visit.reducer';
import './patient-outcome-update.scss';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';
import {
  getMasterEntitiesByEnumNames,
  getMasterEntitiesByGroupNames
} from 'app/entities/watotocare-entity/watotocare-entity.reducer';
import { IPatientOutcome } from 'app/shared/model/patient-outcome.model';
import { dateInUserTimezone, daysSince } from 'app/shared/util/date-utils';
import { IWatotoCareEntityMaster } from 'app/shared/model/watotocare-entity-master.model';
import moment from 'moment';
import { IWatotoCareEntityDto } from 'app/shared/model/watotocare-entity-dto.model';
import WatotocareEntityFormGroup from 'app/shared/components/watotocare-entity-form/watotocare-entity-form-group';

export interface IPatientOutcomeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{
  patientBabyId: string;
}> {
}

// eslint-disable-next-line complexity
export const PatientOutcomeUpdate = (props: IPatientOutcomeUpdateProps) => {
    const {
      entities,
      patientBabyEntity,
      patientVisitEntities,
      watotoCareEntityList,
      watotoCareEntitiesByGroup,
      outcomeSettings,
      loading,
      fetchSuccess,
      updating
    } = props;
    const [callSuccessStatus, setCallSuccessStatus] = useState(null);
    const [attemptRequiredStatus, setAttemptRequiredStatus] = useState(null);
    const [overrideAttemptRequiredStatus, setOverrideAttemptRequiredStatus] = useState(null);
    const [motherAliveStatus, setMotherAliveStatus] = useState(null);
    const [babyAliveStatus, setBabyAliveStatus] = useState(null);
    const [babyHivStatus, setBabyHivStatus] = useState(null);
    const [totalVisitCount, setTotalVisitCount] = useState(null);
    const [outcomeNumber, setOutcomeNumber] = useState(null);
    const [outcomeCallDay, setOutcomeCallDay] = useState(null);
    const [isNew, setIsNew] = useState(true);
    const [outcomeExists, setOutcomeExists] = useState(false);
    const [firstCallDay, setFirstCallDay] = useState(null);
    const [existingPatientOutcome, setExistingPatientOutcome] = useState<IPatientOutcome>(null);
    const [secondCallDay, setSecondCallDay] = useState(null);
    const [failureReasonEntityDetails, setFailureReasonEntityDetails] = useState([] as IWatotoCareEntityDetail[]);

    const [patientOutcomeMasterEntities, setPatientOutcomeMasterEntities] = useState<IWatotoCareEntityMaster[]>([]);
    const [patientBabyOutcomeMasterEntities, setPatientBabyOutcomeMasterEntities] = useState<IWatotoCareEntityMaster[]>([]);

    const [patientEntityFormInitialValues, setPatientEntityFormInitialValues] = useState<IWatotoCareEntityDto[]>([]);
    const [patientEntityFormSelectedValues, setPatientEntityFormSelectedValues] = useState<IWatotoCareEntityDto[]>([]);

    const [patientBabyEntityFormInitialValues, setPatientBabyEntityFormInitialValues] = useState<IWatotoCareEntityDto[]>([]);
    const [patientBabyEntityFormSelectedValues, setPatientBabyEntityFormSelectedValues] = useState<IWatotoCareEntityDto[]>([]);

    const WATOTOCARE_ENTITY_ENUM_NAMES = ['OUTCOME_FAILURE_REASON'];
    const WATOTOCARE_ENTITY_PATIENT_GROUP_PREFIX = 'PATIENT_OUTCOME_';
    const WATOTOCARE_ENTITY_PATIENT_BABY_GROUP_PREFIX = 'PATIENT_BABY_OUTCOME_';

    const [failureReason, setFailureReason] = useState(null);
    const [redirecting, setRedirecting] = useState(false);

    const handleClose = (message = '') => {
      if (!redirecting) {
        if (message && message !== '') {
          alert(message);
        }
        setRedirecting(true);
        props.history.goBack();
      }
    };

    const getPatientBabyId = () => {
      const queryParams = new URLSearchParams(props.location.search);
      if (!queryParams.get('patientBabyId')) {
        handleClose('Invalid Baby Id');
        return null;
      }
      return queryParams.get('patientBabyId') || null;
    };

    useEffect(() => {
      const patientBabyId = getPatientBabyId();
      props.getEntities(patientBabyId);
      props.getPatientBaby(patientBabyId);
      props.getPatientVisitEntities(patientBabyId);
    }, []);

    useEffect(() => {
      if (patientVisitEntities.length > 0) {
        setTotalVisitCount(patientVisitEntities.length);
      }
    }, [patientVisitEntities]);

    useEffect(() => {
      if (entities.length > 0 && outcomeNumber !== null && !outcomeExists) {
        // find if outcome exists for the outcomeCallDay, if it does set isNew to false and set previous outcome data
        if (entities.find(entity => entity.outcomeNumber === outcomeNumber)) {
          setOutcomeExists(true);
          setIsNew(false);
          const existingOutcome = entities.find(entity => entity.outcomeNumber === outcomeNumber);
          setExistingPatientOutcome(existingOutcome);
          setMotherAliveStatus(existingOutcome.motherIsAlive);
          setBabyAliveStatus(existingOutcome.babyIsAlive);
          setBabyHivStatus(existingOutcome.babyHasHiv);
          setTotalVisitCount(existingOutcome.followupVisitsCompleted);
        }
      }
    }, [entities, outcomeNumber]);

    useEffect(() => {
      // set entity values for previous patient outcome if it exists
      if (!isNew && Object.keys(existingPatientOutcome).length > 0 && patientOutcomeMasterEntities && patientOutcomeMasterEntities.length > 0) {
        setPatientEntityFormInitialValues(existingPatientOutcome.patientOutcomeEntities);
      }
    }, [patientOutcomeMasterEntities, isNew, existingPatientOutcome]);

    useEffect(() => {
      // set entity values for previous patient baby outcome if it exists
      if (!isNew && Object.keys(existingPatientOutcome).length > 0 && patientBabyOutcomeMasterEntities && patientBabyOutcomeMasterEntities.length > 0) {
        setPatientBabyEntityFormInitialValues(existingPatientOutcome.patientBabyOutcomeEntities);
      }
    }, [patientBabyOutcomeMasterEntities, isNew, existingPatientOutcome]);

    useEffect(() => {
      if (patientBabyEntity?.patient?.countryId) {
        props.getOutcomeSettings(patientBabyEntity.patient.countryId);
      }
    }, [patientBabyEntity]);

    useEffect(() => {
      if (patientBabyEntity.id && fetchSuccess === true && outcomeSettings.length > 0) {
        if (patientBabyEntity?.patientCase?.patientBabyIsAlive === false) {
          handleClose('Baby deceased, outcome call not required');
          return;
        }
        if (patientBabyEntity?.patientCase?.patientIsAlive === false) {
          setMotherAliveStatus(false);
        }

        const babyAgeInDays = moment()
          .startOf('day')
          .diff(moment(patientBabyEntity.birthDate).startOf('day'), 'days');

        // get unique set of outcome call days in descending order from WATOTOCARE_ENTITY_GROUP_NAMES
        let outcomeCallDaysAsc = outcomeSettings.map(setting => setting.callDay)
          .sort((a, b) => a - b);
        outcomeCallDaysAsc = [...new Set(outcomeCallDaysAsc)];

        if (outcomeCallDaysAsc.length < 2) {
          handleClose('Invalid outcome call days data');
          return;
        }

        const tempFirstCallDay = outcomeCallDaysAsc[0];
        const tempSecondCallDay = outcomeCallDaysAsc[1];
        setFirstCallDay(tempFirstCallDay);
        setSecondCallDay(tempSecondCallDay);
        let patientOutcomeCallDay = tempFirstCallDay;

        const params = new URLSearchParams(props.location.search);
        const outcomeParam = params.get('outcome') ? parseInt(params.get('outcome'), 10) : null;
        if (!outcomeParam) {
          // loop through outcome call days to find the one lower than daysDifference
          let nearestCallDay = 0;
          for (let i = outcomeCallDaysAsc.length - 1; i >= 0; i--) {
            if (outcomeCallDaysAsc[i] <= babyAgeInDays) {
              nearestCallDay = outcomeCallDaysAsc[i];
              break;
            }
          }

          if (nearestCallDay === 0) {
            handleClose('Too early for outcome call');
            return;
          }

          // set call day as first call day if no outcome calls made
          if (entities.length !== 0) {
            patientOutcomeCallDay = nearestCallDay;
          }
        } else {
          const outcomeCallDayParam = outcomeSettings.find(setting => setting.index === outcomeParam)?.callDay || null;
          if (!outcomeCallDaysAsc.includes(outcomeCallDayParam)) {
            handleClose('Invalid outcome call day');
            return;
          }

          if (babyAgeInDays < outcomeCallDayParam) {
            handleClose('Too early for outcome call');
            return;
          }

          if (entities.length < outcomeParam - 1) {
            handleClose(`${outcomeCallDaysAsc[entities.length]} day outcome is not yet complete, please proceed with ${outcomeCallDaysAsc[entities.length]} day outcome first`);
            return;
          }

          patientOutcomeCallDay = outcomeCallDayParam;
        }
        setOutcomeCallDay(patientOutcomeCallDay);

        const patientOutcomeCallNumber = outcomeSettings.find(setting => setting.callDay === patientOutcomeCallDay).index;
        setOutcomeNumber(patientOutcomeCallNumber);

        // get previous outcome data if it exists
        const previousOutcome = entities.find(entity => entity.outcomeNumber === patientOutcomeCallNumber - 1);
        if (previousOutcome) {
          if (previousOutcome.babyIsAlive === false) {
            if (!redirecting) {
              handleClose('Baby deceased, outcome call not required');
            }
            return;
          }
          if (previousOutcome.motherIsAlive === false) {
            setMotherAliveStatus(false);
          }
        }

        // get entities
        props.getMasterEntitiesByEnumNames(WATOTOCARE_ENTITY_ENUM_NAMES, patientBabyEntity.patient.countryId);
        props.getMasterEntitiesByGroupNames(
          [WATOTOCARE_ENTITY_PATIENT_GROUP_PREFIX, WATOTOCARE_ENTITY_PATIENT_BABY_GROUP_PREFIX].map(prefix => `${prefix}${patientOutcomeCallNumber}`),
          patientBabyEntity.patient.countryId
        );
      }
    }, [patientBabyEntity, entities, outcomeSettings]);

    useEffect(() => {
      if (watotoCareEntityList.length > 0) {
        // set watotoCareEntityDetails for each entity in watotoCareEntityList
        const failureReasonEntity = watotoCareEntityList.find(entity => entity.enumName === 'OUTCOME_FAILURE_REASON');
        if (failureReasonEntity) {
          setFailureReasonEntityDetails(failureReasonEntity.watotoCareEntityDetails
            .filter(entity => entity.isActive)
            .sort((a, b) => a.displayOrder - b.displayOrder));
        }
      }
    }, [watotoCareEntityList]);

    useEffect(() => {
      if (Object.keys(watotoCareEntitiesByGroup).length > 0 && fetchSuccess && outcomeNumber !== null) {
        setPatientOutcomeMasterEntities(watotoCareEntitiesByGroup[`${WATOTOCARE_ENTITY_PATIENT_GROUP_PREFIX}${outcomeNumber}`]);
        setPatientBabyOutcomeMasterEntities(watotoCareEntitiesByGroup[`${WATOTOCARE_ENTITY_PATIENT_BABY_GROUP_PREFIX}${outcomeNumber}`]);
      }
    }, [watotoCareEntitiesByGroup, outcomeNumber]);

    useEffect(() => {
      if (props.updateSuccess) {
        props.reset();
        handleClose();
      }
    }, [props.updateSuccess]);

    useEffect(() => () => {
      props.reset();
      props.resetPatient();
      props.resetPatientBaby();
      props.resetPatientVisit();
    }, []);

    const saveEntity = (event, errors, values) => {
      if (errors.length === 0) {
        // if call success status is false, create outcome call entity and return
        if (callSuccessStatus === false) {
          const outcomeCallEntity = {
            patientCaseId: patientBabyEntity.patientCase.id,
            outcomeNumber,
            failureReason,
            failureReasonComment: failureReason === failureReasonEntityDetails[failureReasonEntityDetails.length - 1].id ? values.failureReasonComment : null,
            outcomeAttemptRequired: attemptRequiredStatus,
            outcomeCallDate: attemptRequiredStatus === true ? values.outcomeCallDate : null
          };
          props.createOutcomeCall(outcomeCallEntity);
          return;
        }

        // if call success status is true, create outcome entity
        const patientBabyId = getPatientBabyId();
        if (!patientBabyId) {
          return;
        }

        const entity = {
          ...values,
          patientBabyId,
          patientCaseId: patientBabyEntity.patientCase.id,
          patientOutcomeEntityIds: patientEntityFormSelectedValues,
          patientBabyOutcomeEntityIds: patientBabyEntityFormSelectedValues,
          outcomeNumber,
          motherIsAlive: motherAliveStatus,
          babyIsAlive: babyAliveStatus,
          babyHasHiv: babyHivStatus,
          followupVisitsCompleted: totalVisitCount
        };

        if (overrideAttemptRequiredStatus === true) {
          entity.outcomeAttemptRequired = true;
        }

        if (isNew) {
          props.createEntity(entity);
        } else {
          entity.id = existingPatientOutcome.id;
          props.updateEntity(entity);
        }
      }
    };

    return (
      <div>
        {!loading && (
          <>
            <Row className="justify-content-center">
              <Col md="8">
                {outcomeCallDay !== null && <h2 id="watotocareApp.patient.baby.home.createOrEditLabel">
                  {isNew ? '' : 'Edit '} Outcome Call After {outcomeCallDay} Days
                </h2>
                }
              </Col>
            </Row>
            <br />
            <Row className="justify-content-center">
              <Col md="4">
                <h6 className="text-primary">Mother Details</h6>
                <div>
                  watotoCare ID: {patientBabyEntity?.patient?.wcId}
                  <br />
                  Mother Name: {`${patientBabyEntity?.patient?.firstName} ${patientBabyEntity?.patient?.lastName}`}
                </div>
              </Col>
              <Col md="4">
                <h6 className="text-primary">Baby Details</h6>
                <div>
                  Date of Birth: {patientBabyEntity.birthDate} (Age in Days: {daysSince(patientBabyEntity.birthDate)})
                  <br />
                  Baby
                  Name: {patientBabyEntity.firstName ? `${patientBabyEntity.firstName} ${patientBabyEntity.lastName}` : '-'}
                </div>
              </Col>
            </Row>
          </>
        )}

        <br />
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : existingPatientOutcome} onSubmit={saveEntity}>
                <div className="mt-3">
                  {isNew && (
                    <>
                      <Row className="justify-content-left">
                        {/* True/False button group for call success status */}
                        <Col md="12">
                          <AvGroup>
                            <Label id="callSuccessStatus" for="outcome-callSuccessStatus">
                              Was the Call Successful?
                            </Label>
                            <ButtonGroup style={{ marginLeft: '30px' }} id="outcome-callSuccessStatus">
                              <Button
                                color={callSuccessStatus === true ? 'primary' : 'secondary'}
                                onClick={() => {
                                  setCallSuccessStatus(true);
                                }}
                              >
                                Yes
                              </Button>

                              <Button
                                color={callSuccessStatus === false ? 'primary' : 'secondary'}
                                onClick={() => {
                                  setCallSuccessStatus(false);
                                }}
                              >
                                No
                              </Button>
                            </ButtonGroup>
                            <AvField
                              name="callSuccessStatus"
                              type="hidden"
                              value={callSuccessStatus === null ? null : callSuccessStatus.toString()}
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </AvGroup>
                        </Col>
                        { /* call success status is false */}
                        {callSuccessStatus === false && (
                          <>
                            {/* AvInput select for failure reason with options loaded from failureReasonEntityDetails */}
                            <Col md="6">
                              <AvGroup>
                                <Label id="failureReason" for="outcome-failureReason">
                                  Reason the Call Failed
                                </Label>
                                <AvInput
                                  id="outcome-failureReason"
                                  type="select"
                                  name="failureReason"
                                  onChange={e => setFailureReason(e.target.value)}
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                >
                                  <option disabled selected value="">-- select an option --</option>
                                  {failureReasonEntityDetails.map(val => (
                                    <option value={val.id} key={val.id}>
                                      {val.enDescription}
                                    </option>
                                  ))}
                                </AvInput>
                              </AvGroup>
                            </Col>

                            {/* AvInput text for failure reason comment */}
                            {failureReasonEntityDetails.length > 0 && failureReason === failureReasonEntityDetails[failureReasonEntityDetails.length - 1].id && (
                              <Col md="6">
                                <AvGroup>
                                  <Label id="failureReasonComment" for="outcome-failureReasonComment">
                                    If Other, Please Specify
                                  </Label>
                                  <AvInput
                                    id="outcome-failureReasonComment"
                                    type="text"
                                    name="failureReasonComment"
                                    validate={{
                                      required: { value: true, errorMessage: 'This field is required.' }
                                    }}
                                  />
                                </AvGroup>
                              </Col>
                            )}

                            {/* AvInput button group for attempt required status */}
                            <Col md="12" className="mt-3">
                              <AvGroup>
                                <Label id="attemptRequiredStatus" for="outcome-attemptRequiredStatus">
                                  Is Another Attempt Required?
                                </Label>
                                <ButtonGroup style={{ marginLeft: '30px' }} id="outcome-attemptRequiredStatus">
                                  <Button
                                    color={attemptRequiredStatus === true ? 'primary' : 'secondary'}
                                    onClick={() => {
                                      setAttemptRequiredStatus(true);
                                    }}
                                  >
                                    Yes
                                  </Button>

                                  <Button
                                    color={attemptRequiredStatus === false ? 'primary' : 'secondary'}
                                    onClick={() => {
                                      setAttemptRequiredStatus(false);
                                    }}
                                  >
                                    No
                                  </Button>
                                </ButtonGroup>
                                <AvField
                                  name="attemptRequiredStatus"
                                  type="hidden"
                                  value={attemptRequiredStatus === null ? null : attemptRequiredStatus.toString()}
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                />
                              </AvGroup>
                            </Col>

                            { /* attempt required status is true */}
                            {attemptRequiredStatus === true && (
                              <>
                                {/* AvInput date for outcome call date */}
                                <Col md="6">
                                  <AvGroup>
                                    <Label id="outcomeCallDate" for="outcome-outcomeCallDate">
                                      Outcome Call Date
                                    </Label>
                                    <AvField
                                      id="outcome-outcomeCallDate"
                                      type="date"
                                      name="outcomeCallDate"
                                      defaultValue={dateInUserTimezone()}
                                      validate={{
                                        required: { value: true, errorMessage: 'This field is required.' },
                                        dateRange: {
                                          start: { value: 0, units: 'days' },
                                          end: { value: 1, units: 'years' },
                                          errorMessage: 'Please select a future date'
                                        }
                                      }}
                                    />
                                  </AvGroup>
                                </Col>
                              </>
                            )}
                          </>
                        )}
                      </Row>
                    </>
                  )}
                  {!isNew && existingPatientOutcome.outcomeAttemptRequired === false && (
                    <Row className="justify-content-left">
                      <Col md="12">
                        <h5 className="text-danger">
                          Outcome call marked as not required!
                        </h5>
                      </Col>
                      <Col md="12" className="mt-3">
                        <AvGroup>
                          <Label id="overrideAttemptRequiredStatus" for="outcome-overrideAttemptRequiredStatus">
                            Override Attempt Required?
                          </Label>
                          <ButtonGroup style={{ marginLeft: '30px' }} id="outcome-overrideAttemptRequiredStatus">
                            <Button
                              color={overrideAttemptRequiredStatus === true ? 'primary' : 'secondary'}
                              onClick={() => {
                                setOverrideAttemptRequiredStatus(true);
                              }}
                            >
                              Yes
                            </Button>

                            <Button
                              color={overrideAttemptRequiredStatus === false ? 'primary' : 'secondary'}
                              onClick={() => {
                                setOverrideAttemptRequiredStatus(false);
                              }}
                            >
                              No
                            </Button>
                          </ButtonGroup>
                        </AvGroup>
                      </Col>
                    </Row>
                  )}
                  {((!isNew && (existingPatientOutcome.outcomeAttemptRequired === true || overrideAttemptRequiredStatus === true))
                    || callSuccessStatus === true) && (
                    <>
                      <Row className="justify-content-left">
                        <>
                          { /* date of outcome call */}
                          <Col md="6" className="mt-3">
                            <AvGroup>
                              <Label id="createdAt" for="outcome-createdAt">
                                When was the Outcome Call Made?
                              </Label>
                              <AvField
                                id="outcome-createdAt"
                                type="date"
                                name="createdAt"
                                defaultValue={isNew ? dateInUserTimezone() : existingPatientOutcome.createdAt}
                                validate={{
                                  required: { value: true, errorMessage: 'This field is required.' },
                                  dateRange: {
                                    start: { value: -1, units: 'years' },
                                    end: { value: 0, units: 'days' },
                                    errorMessage: 'Please select a past date'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </>
                      </Row>

                      <br />
                      <h5 className="text-primary">Baby Outcome Details</h5>

                      <br />
                      <Row className="justify-content-left">
                        {/* True/False button group for baby alive status */}
                        <Col md="12">
                          <AvGroup>
                            <Label id="babyAliveStatus" for="outcome-babyAliveStatus">
                              Is Baby Alive?
                            </Label>
                            <ButtonGroup style={{ marginLeft: '30px' }} id="outcome-babyAliveStatus">
                              <Button
                                color={babyAliveStatus === true ? 'primary' : 'secondary'}
                                disabled={!isNew && !overrideAttemptRequiredStatus}
                                onClick={() => {
                                  setBabyAliveStatus(true);
                                }}
                              >
                                Yes
                              </Button>

                              <Button
                                color={babyAliveStatus === false ? 'primary' : 'secondary'}
                                disabled={!isNew && !overrideAttemptRequiredStatus}
                                onClick={() => {
                                  setBabyAliveStatus(false);
                                }}
                              >
                                No
                              </Button>
                            </ButtonGroup>
                            <AvField
                              name="babyAliveStatus"
                              type="hidden"
                              value={babyAliveStatus === null ? null : babyAliveStatus.toString()}
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </AvGroup>
                        </Col>
                      </Row>
                      {babyAliveStatus === true && (
                        <>
                          {outcomeCallDay === secondCallDay && (
                            <>
                              <Row className="justify-content-left">
                                {/* AvInput button group for baby HIV status */}
                                <Col md="12" className="mt-3">
                                  <AvGroup>
                                    <Label id="babyHivStatus">
                                      HIV Status
                                    </Label>
                                    <ButtonGroup style={{ marginLeft: '30px' }}>
                                      <Button
                                        color={babyHivStatus === true ? 'primary' : 'secondary'}
                                        onClick={() => {
                                          setBabyHivStatus(true);
                                        }}
                                      >
                                        Positive
                                      </Button>

                                      <Button
                                        color={babyHivStatus === false ? 'primary' : 'secondary'}
                                        onClick={() => {
                                          setBabyHivStatus(false);
                                        }}
                                      >
                                        Negative
                                      </Button>
                                    </ButtonGroup>
                                  </AvGroup>
                                </Col>
                              </Row>
                            </>
                          )}

                          <WatotocareEntityFormGroup
                            prefix="patient-baby-outome"
                            entities={patientBabyOutcomeMasterEntities}
                            initialValues={patientBabyEntityFormInitialValues}
                            onChange={(values) => setPatientBabyEntityFormSelectedValues(values)}
                          />

                          {outcomeCallDay === secondCallDay && (
                            <>
                              <Row className="justify-content-left">
                                {/* AvInput text for last recorded weight */}
                                <Col md="6">
                                  <AvGroup>
                                    <Label id="lastRecordedWeight" for="outcome-lastRecordedWeight">
                                      Last Recorded Weight(kg)
                                    </Label>
                                    <AvInput
                                      id="outcome-lastRecordedWeight"
                                      type="number"
                                      min="0"
                                      name="lastRecordedWeight"
                                      validate={{
                                        required: { value: true, errorMessage: 'This field is required.' }
                                      }}
                                    />
                                  </AvGroup>
                                </Col>

                                {/* AvInput date for weight recorded at */}
                                <Col md="6">
                                  <AvGroup>
                                    <Label id="weightRecordedAt" for="outcome-weightRecordedAt">
                                      When was the weight recorded?
                                    </Label>
                                    <AvInput
                                      id="outcome-weightRecordedAt"
                                      type="date"
                                      name="weightRecordedAt"
                                      validate={{
                                        required: { value: true, errorMessage: 'This field is required.' }
                                      }}
                                    />
                                  </AvGroup>
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}


                      <br />
                      <h5 className="text-primary">Mother Outcome Details</h5>

                      <br />
                      <Row className="justify-content-left">
                        <Col md="12">
                          <AvGroup>
                            <Label id="motherAliveStatus" for="outcome-motherAliveStatus">
                              Is Mother Alive?
                            </Label>
                            <ButtonGroup style={{ marginLeft: '30px' }} id="outcome-motherAliveStatus">
                              <Button
                                color={motherAliveStatus === true ? 'primary' : 'secondary'}
                                disabled={!isNew && !overrideAttemptRequiredStatus}
                                onClick={() => {
                                  setMotherAliveStatus(true);
                                }}
                              >
                                Yes
                              </Button>

                              <Button
                                color={motherAliveStatus === false ? 'primary' : 'secondary'}
                                disabled={!isNew && !overrideAttemptRequiredStatus}
                                onClick={() => {
                                  setMotherAliveStatus(false);
                                }}
                              >
                                No
                              </Button>
                            </ButtonGroup>
                            <AvField
                              name="motherAliveStatus"
                              type="hidden"
                              value={motherAliveStatus === null ? null : motherAliveStatus.toString()}
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </AvGroup>
                        </Col>
                      </Row>

                      {motherAliveStatus === true && (
                        <>
                          <WatotocareEntityFormGroup
                            prefix="patient-outome"
                            entities={patientOutcomeMasterEntities}
                            initialValues={patientEntityFormInitialValues}
                            onChange={(values) => setPatientEntityFormSelectedValues(values)}
                          />

                          {outcomeCallDay === secondCallDay && (
                            <Row className="justify-content-left">
                              <Col md="6" className="mt-3">
                                <AvGroup>
                                  <Label id="facilityName" for="outcome-facilityName">
                                    If not Sengerema, where were postnatal visits attended?
                                  </Label>
                                  <AvInput
                                    id="outcome-facilityName"
                                    type="text"
                                    name="facilityName"
                                    placeholder="Name of Hospital/Facility"
                                  />
                                </AvGroup>
                              </Col>
                            </Row>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <br />
                <Button tag={Link} id="cancel-save" to="/patient" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
;

const mapStateToProps = (storeState: IRootState) => ({
  patientBabyEntity: storeState.patientBaby.entity,
  patientVisitEntities: storeState.patientVisit.entities,
  loading: storeState.patientOutcome.loading,
  fetchSuccess: storeState.patientOutcome.fetchSuccess,
  updating: storeState.patientOutcome.updating,
  updateSuccess: storeState.patientOutcome.updateSuccess,
  watotoCareEntityList: storeState.watotoCareEntity.masterEntities,
  entities: storeState.patientOutcome.entities,
  watotoCareEntitiesByGroup: storeState.watotoCareEntity.masterEntitiesByGroup,
  outcomeSettings: storeState.patientOutcome.settings
});

const mapDispatchToProps = {
  getEntities,
  getPatientBaby,
  createEntity,
  updateEntity,
  createOutcomeCall,
  reset,
  resetPatient,
  resetPatientBaby,
  resetPatientVisit,
  getPatientVisitEntities,
  getMasterEntitiesByEnumNames,
  getMasterEntitiesByGroupNames,
  getOutcomeSettings
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientOutcomeUpdate);
