import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './patient.reducer';

export interface IPatientDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const PatientDetail = (props: IPatientDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { patientEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          Patient [<b>{patientEntity.wcId}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="eligible">Eligible</span>
          </dt>
          <dd>{patientEntity.eligible ? 'Yes' : 'No'}</dd>
          {patientEntity.eligible === false && (
            <>
              <dt>
                <span id="ineligibilityReason">Reason for Ineligibility</span>
              </dt>
              <dd>
                {
                  patientEntity.patientEnrollmentEntities
                    ?.filter(val => val.entityDetail?.entityMaster?.enumName === 'INELIGIBILITY_REASON')
                    ?.map(val => val.entityDetail?.enDescription).join(', ')
                }
              </dd>
              <dt>
                <span id="ineligibilityComment">Comment for Ineligibility</span>
              </dt>
              <dd>{patientEntity.ineligibilityComment}</dd>
            </>
          )}
          <dt>
            <span id="consent">Consent</span>
          </dt>
          <dd>{patientEntity.consent ? 'Yes' : 'No'}</dd>
          {patientEntity.consent === false && (
            <>
              <dt>
                <span id="noConsentReason">Reason for not Consenting</span>
              </dt>
              <dd>
                {
                  patientEntity.patientEnrollmentEntities
                    ?.filter(val => val.entityDetail?.entityMaster?.enumName === 'NO_CONSENT_REASON')
                    ?.map(val => val.entityDetail?.enDescription).join(', ')
                }
              </dd>
              <dt>
                <span id="noConsentComment">Comment for not Consenting</span>
              </dt>
              <dd>{patientEntity.noConsentComment}</dd>
            </>
          )}
          <dt>
            <span id="patientName">Name</span>
          </dt>
          <dd>{patientEntity.firstName + ' ' + patientEntity.lastName}</dd>
          <dt>
            <span id="rchCardNumber">RCH-4 Card No.</span>
          </dt>
          <dd>{patientEntity.rchCardNumber}</dd>
          <dt>
            <span id="nidaNumber">Mother&apos;s NIDA number</span>
          </dt>
          <dd>{patientEntity.nidaNumber}</dd>
          <dt>
            <span id="dob">Age Range</span>
          </dt>
          <dd>{patientEntity.ageRangeEntity?.enDescription}</dd>
          <dt>
            <span id="address">{patientEntity.consent === true ? 'Pick-up' : 'Home'} Village</span>
          </dt>
          <dd>{patientEntity.village?.name}</dd>
          {patientEntity.consent === true && (
            <>
              <dt>
                <span id="address">Nearby Landmarks</span>
              </dt>
              <dd>{patientEntity.nearbyLandmarks}</dd>
            </>
          )}
          <dt>
            <span id="phoneNumber">Mobile Number</span>
          </dt>
          <dd>{patientEntity.phoneNumber}</dd>
          <dt>
            <span id="parity">Parity</span>
          </dt>
          <dd>{patientEntity.parity}</dd>
          {patientEntity.consent === true && (
            <>
              <dt>
                <span id="caregiverName">Name of Caregiver</span>
              </dt>
              <dd>{patientEntity.caregiverName}</dd>
              <dt>
                <span id="caregiverPhoneNumber">Mobile number of caregiver</span>
              </dt>
              <dd>{patientEntity.caregiverPhoneNumber}</dd>
              <dt>
                <span id="secondaryContactName">Alternative Contact Person</span>
              </dt>
              <dd>{patientEntity.secondaryContactName}</dd>
              <dt>
                <span id="secondaryPhoneNumber">Alternative Contact Person: Mobile</span>
              </dt>
              <dd>{patientEntity.secondaryPhoneNumber}</dd>
            </>
          )}
        </dl>
        <Button tag={Link} to="/patient" replace color="info">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/patient/${patientEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ patient }: IRootState) => ({
  patientEntity: patient.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetail);
