import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Popover, PopoverBody, PopoverHeader, Row, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { IRootState } from 'app/shared/reducers';
import { getEntities, reset as resetJourneys } from './journey.reducer';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import { getAllFacility } from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';
import { getVillageName } from 'app/entities/village/village';
import { JhiItemCount, JhiPagination } from 'react-jhipster';
import { getVillagesByCountry as getVillages } from 'app/entities/village/village.reducer';
import { getEntities as getDrivers } from 'app/entities/driver/driver.reducer';
import { getEntities as getPhysicians } from 'app/entities/physician/physician.reducer';
import { JourneyType } from 'app/shared/model/enumerations/journey-type.model';
import { JourneyStatus } from 'app/shared/model/enumerations/journey-status.model';
import moment from 'moment';

export interface IJourneyProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const Journey = (props: IJourneyProps) => {
  const {
    journeyList,
    totalItems,
    match,
    loading,
    isAdmin,
    userCountryId,
    countries,
    drivers,
    driverLoading,
    physicians,
    physicianLoading,
    allHealthcareFacilities,
    healthcareFacilitiesLoading,
    villages,
    villagesLoading
  } = props;

  const urlParams = new URLSearchParams(window.location.search);
  const [filterList, setFilterList] = useState({
    countryId: '',
    patientName: '',
    driverId: '',
    physicianId: '',
    startVillageId: '',
    endHealthcareFacilityId: '',
    scheduledDate: urlParams.get('scheduledDate') || '',
    upcomingOnly: true,
    activePage: 0,
    itemsPerPage: 25,
    sort: { sortOrder: 'desc', sortType: 'createdAt' }
  });
  const [patientPopoverOpen, setPatientPopoverOpen] = useState(null);
  const [driverPopoverOpen, setDriverPopoverOpen] = useState(null);
  const [physicianPopoverOpen, setPhysicianPopoverOpen] = useState(null);

  useEffect(() => {
    props.resetJourneys();
    props.getCountries();
  }, []);

  useEffect(() => {
    if (countries.length > 0 && userCountryId !== '') {
      const country = countries.find(c => c.id === userCountryId);
      if (country) {
        setFilterList({ ...filterList, countryId: userCountryId });
      }
    }
  }, [countries, userCountryId]);

  useEffect(() => {
    if (filterList.countryId && filterList.countryId !== '') {
      props.getAllFacility(filterList.countryId);
      props.getVillages(undefined, undefined, undefined, filterList.countryId);
      props.getDrivers(undefined, undefined, undefined, filterList.countryId);
      props.getPhysicians(filterList.countryId);
    }
  }, [filterList.countryId]);

  useEffect(() => {
    const stringifiedFilterList = JSON.stringify(filterList);
    props.getEntities(null, JSON.parse(stringifiedFilterList));
  }, [JSON.stringify(filterList)]);

  const regCountriesList = props.countries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === userCountryId;
  });

  const updateFilters = (filters) => {
    if (filters.activePage === undefined) filters.activePage = 0;
    const filterListObj = { ...filterList, ...filters };
    setFilterList(filterListObj);
  };

  const getHfName = (id, hfList) => {
    const hf = hfList.find(v => v.id === id);
    return !hf ?
      { short: '', full: '', council: '', district: '' }
      :
      {
        short: hf.name,
        council: hf?.council?.name,
        district: hf?.council?.parent?.name,
        full: (hf.name + ' / ' + hf?.council?.name + ' / ' + hf?.council?.parent?.name)
      };
  };

  // AvTypeahead Data
  const hfOptionList = allHealthcareFacilities ?
    allHealthcareFacilities.map(hf => ({
      ...getHfName(hf.id, allHealthcareFacilities),
      id: hf.id
    })) : [];

  const villageOptionList = villages ?
    villages.map(village => ({
      ...getVillageName(village.id, villages),
      id: village.id
    })) : [];

  const driverOptionList = drivers ?
    drivers.map(driver => ({
      short: `${driver.firstName} ${driver.lastName}`,
      full: `${driver.firstName} ${driver.lastName}`,
      id: driver.id
    })) : [];


  const physicianOptionList = physicians ?
    physicians.map(physician => ({
      short: `${physician.firstName} ${physician.lastName}`,
      full: `${physician.firstName} ${physician.lastName}`,
      id: physician.id
    })) : [];

  const handleSort = (sortType) => {
    const sortOrder = filterList.sort.sortType === sortType && filterList.sort.sortOrder === 'asc' ? 'desc' : 'asc';
    updateFilters({ sort: { sortType, sortOrder } });
  };

  const getJourneyType = (type) => {
    if (type) {
      if (type === JourneyType.TELECONSULTATION) {
        return 'Teleconsultation';
      } else if (type === JourneyType.PHYSICAL_TRANSPORT) {
        return 'Physical Transport';
      } else if (type === JourneyType.BYPASS) {
        return 'Journey Skipped';
      }
    }
    return '-';
  };

  const getJourneyStatus = (status, scheduledSlotStart) => {
    if (status) {
      if (status === JourneyStatus.COMPLETE) {
        return { journeyStatus: 'Needs to update conditions', journeyStatusColor: 'yellowgreen' };
      } else if (status === JourneyStatus.IN_PROGRESS) {
        return { journeyStatus: 'In Progress', journeyStatusColor: 'blue' };
      } else if (status === JourneyStatus.CANCELED) {
        return { journeyStatus: 'Journey Canceled', journeyStatusColor: 'red' };
      } else if (status === JourneyStatus.SCHEDULED) {
        // check if scheduledSlotStart is for today or some other day
        const scheduledSlotStartMoment = moment(scheduledSlotStart);
        const today = moment();
        if (scheduledSlotStartMoment.isSame(today, 'day')) {
          // convert scheduled slot to readable format with am pm
          const start = moment(scheduledSlotStart).format('hh:mm A');
          return { journeyStatus: `Scheduled for today@${start}`, journeyStatusColor: 'orange' };
        } else {
          // Format start time to format like July 15th, 2021
          const start = moment(scheduledSlotStart).format('MMMM Do, YYYY');
          return { journeyStatus: `Scheduled for ${start}`, journeyStatusColor: 'orange' };
        }
      } else {
        return { journeyStatus: 'Needs an appointment', journeyStatusColor: 'red' };
      }
    }
    return { journeyStatus: 'Needs an appointment', journeyStatusColor: 'red' };
  };

  const getJourneyScheduledDate = (scheduledSlotStart) => {
    if (scheduledSlotStart) {
      return moment(scheduledSlotStart).format('MMMM Do, YYYY hh:mm A');
    }
    return '-';
  };

  return (
    <div>
      <h2 id="journey-heading">
        Upcoming Journeys
      </h2>
      <br />
      <AvForm>
        {regCountriesList &&
          <AvGroup>
            <Label for="country-filter">Country</Label>
            <AvInput
              type="select"
              name="country-filter"
              id="country-filter"
              onChange={(event) => {
                updateFilters({ countryId: event.target.value });
              }}
              value={filterList.countryId}
            >
              {regCountriesList.map((country, i) => (
                <option value={country.id} key={`country-${i}`}>
                  {country.name}
                </option>
              ))}
            </AvInput>
          </AvGroup>
        }
        <AvGroup>
          <Label for="patient-name-filter">Patient Name</Label>
          <AvInput
            onChange={(event) => {
              updateFilters({ patientName: event.target.value });
            }}
            id="patient-name-filter"
            type="input"
            className="form-control"
            name="patient-name-filter"
            value={filterList.patientName}
          />
        </AvGroup>

        {/* TypeAhead for Driver */}
        <AvGroup>
          <Label for="driver-filter">Driver</Label>
          <VillageAvTypeahead
            labelKey="name"
            valueKey="id"
            data={driverOptionList}
            id="journey-driverId"
            loading={driverLoading}
            onChange={(id) => {
              updateFilters({ driverId: id });
            }}
            input={{
              id: 'journey-driver',
              name: 'driverId'
            }}
          />
        </AvGroup>

        {/* TypeAhead for Physician */}
        <AvGroup>
          <Label for="physician-filter">Physician</Label>
          <VillageAvTypeahead
            labelKey="name"
            valueKey="id"
            data={physicianOptionList}
            id="journey-physicianId"
            loading={physicianLoading}
            onChange={(id) => {
              updateFilters({ physicianId: id });
            }}
            input={{
              id: 'journey-physician',
              name: 'physicianId'
            }}
          />
        </AvGroup>

        {/* TypeAhead for Village */}
        <AvGroup>
          <Label for="start-village-filter">Start Village</Label>
          <VillageAvTypeahead
            labelKey="name"
            valueKey="id"
            data={villageOptionList}
            id="journey-startVillageId"
            loading={villagesLoading}
            onChange={(id) => {
              updateFilters({ startVillageId: id });
            }}
            input={{
              id: 'journey-startVillage',
              name: 'startVillageId'
            }}
          />
        </AvGroup>

        {/* TypeAhead for Health Facility */}
        <AvGroup>
          <Label for="healcare-facility-id-filter">End Health Facility</Label>
          <VillageAvTypeahead
            labelKey="name"
            valueKey="id"
            data={hfOptionList}
            id="journey-endHealthcareFacilityId"
            loading={healthcareFacilitiesLoading}
            onChange={(id) => {
              updateFilters({ endHealthcareFacilityId: id });
            }}
            input={{
              id: 'journey-endVillage',
              name: 'endHealthcareFacilityId'
            }}
          />
        </AvGroup>

        {/* Date Filter */}
        <AvGroup>
          <Label for="scheduled-date-filter">Scheduled Date</Label>
          <AvInput
            onChange={(event) => {
              updateFilters({ scheduledDate: event.target.value });
            }}
            id="scheduled-date-filter"
            type="date"
            className="form-control"
            name="scheduled-date-filter"
            value={filterList.scheduledDate}
          />
        </AvGroup>

      </AvForm>
      <div className="table-responsive">
        {journeyList && journeyList.length > 0 ? (
          <>
            <Table responsive>
              <thead>
              <tr>
                <th className="hand border-top-0" onClick={() => handleSort('patientCase.patient.firstName')}>
                  Patient Name&nbsp;
                  <FontAwesomeIcon
                    icon={filterList.sort.sortType === 'patientCase.patient.firstName' ? (filterList.sort.sortOrder === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
                <th className="hand border-top-0" onClick={() => handleSort('type')}>
                  Type&nbsp;
                  <FontAwesomeIcon
                    icon={filterList.sort.sortType === 'type' ? (filterList.sort.sortOrder === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
                <th className="hand border-top-0" onClick={() => handleSort('startVillage.name')}>
                  Start Village&nbsp;
                  <FontAwesomeIcon
                    icon={filterList.sort.sortType === 'startVillage.name' ? (filterList.sort.sortOrder === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
                <th className="hand border-top-0" onClick={() => handleSort('endHealthcareFacility.name')}>
                  End Health Facility&nbsp;
                  <FontAwesomeIcon
                    icon={filterList.sort.sortType === 'endHealthcareFacility.name' ? (filterList.sort.sortOrder === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
                <th className="hand border-top-0" onClick={() => handleSort('driver.firstName')}>
                  Driver Name&nbsp;
                  <FontAwesomeIcon
                    icon={filterList.sort.sortType === 'driver.firstName' ? (filterList.sort.sortOrder === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
                <th className="hand border-top-0" onClick={() => handleSort('physician.firstName')}>
                  Physician Name&nbsp;
                  <FontAwesomeIcon
                    icon={filterList.sort.sortType === 'physician.firstName' ? (filterList.sort.sortOrder === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
                <th className="hand border-top-0" onClick={() => handleSort('scheduledSlotStart')}>
                  Scheduled for&nbsp;
                  <FontAwesomeIcon
                    icon={filterList.sort.sortType === 'scheduledSlotStart' ? (filterList.sort.sortOrder === 'asc' ? 'sort-up' : 'sort-down') : 'sort'} />
                </th>
              </tr>
              </thead>
              <tbody>
              {journeyList.map((journey, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    {journey.patientCase?.patient?.firstName} {journey.patientCase?.patient?.lastName}&nbsp;
                    <span className="superscript-icon" id={`patient-popover-${journey.id}`}
                          onMouseEnter={() => setPatientPopoverOpen(journey.id)}
                          onMouseLeave={() => setPatientPopoverOpen(null)}
                          onClick={(e) => {
                            e.stopPropagation();
                            setPatientPopoverOpen(journey.id);
                          }}
                    >
                      <FontAwesomeIcon icon="info-circle" />
                     </span>
                    <Popover
                      flip
                      target={`patient-popover-${journey.id}`}
                      isOpen={patientPopoverOpen === journey.id}
                    >
                      <PopoverHeader>
                        Patient Info
                      </PopoverHeader>
                      <PopoverBody>
                        <Row>
                          <Col md="12">Mother Mobile Number: {journey.patientCase?.patient?.phoneNumber}</Col>
                          <Col md="12">Caregiver Name: {journey.patientCase?.patient?.caregiverName}</Col>
                          <Col md="12">Caregiver Mobile
                            Number: {journey.patientCase?.patient?.caregiverPhoneNumber}</Col>
                          <Col md="12">Alternate Contact
                            Name: {journey.patientCase?.patient?.secondaryContactName}</Col>
                          <Col md="12">Alternate Mobile
                            Number: {journey.patientCase?.patient?.secondaryPhoneNumber}</Col>
                        </Row>
                      </PopoverBody>
                    </Popover>
                  </td>
                  <td>{getJourneyType(journey.type)}</td>
                  <td>{journey.startVillage?.name ? `${journey.startVillage?.name}/${journey.startVillage?.council?.name}/${journey.startVillage?.council?.parent?.name}` : '-'}</td>
                  <td>{journey.endHealthcareFacility?.name ? `${journey.endHealthcareFacility?.name}/${journey.endHealthcareFacility?.council?.name}/${journey.endHealthcareFacility?.council?.parent?.name}` : '-'}</td>
                  <td>
                    {journey.driver?.firstName} {journey.driver?.lastName}&nbsp;
                    {journey.driverId &&
                      <>
                        <span className="superscript-icon" id={`driver-popover-${journey.id}`}
                              onMouseEnter={() => setDriverPopoverOpen(journey.id)}
                              onMouseLeave={() => setDriverPopoverOpen(null)}
                              onClick={(e) => {
                                e.stopPropagation();
                                setDriverPopoverOpen(journey.id);
                              }}
                        >
                          <FontAwesomeIcon icon="info-circle" />
                        </span>
                        <Popover
                          flip
                          target={`driver-popover-${journey.id}`}
                          isOpen={driverPopoverOpen === journey.id}
                        >
                          <PopoverHeader>
                            Driver Info
                          </PopoverHeader>
                          <PopoverBody>
                            <Row>
                              <Col md="12">Driver Mobile Number: {journey.driver?.phoneNumber}</Col>
                              <Col md="12">Driver Home Village: {journey.driver?.homeVillage?.name}</Col>
                              <Col md="12">Driver M-PESA Number: {journey.driver?.mpesaNumber}</Col>
                            </Row>
                          </PopoverBody>
                        </Popover>
                      </>
                    }
                  </td>
                  <td>
                    {journey.physician?.firstName} {journey.physician?.lastName}&nbsp;
                    {journey.physicianId &&
                      <>
                        <span className="superscript-icon" id={`physician-popover-${journey.id}`}
                              onMouseEnter={() => setPhysicianPopoverOpen(journey.id)}
                              onMouseLeave={() => setPhysicianPopoverOpen(null)}
                              onClick={(e) => {
                                e.stopPropagation();
                                setPhysicianPopoverOpen(journey.id);
                              }}
                        >
                          <FontAwesomeIcon icon="info-circle" />
                        </span>
                        <Popover
                          flip
                          target={`physician-popover-${journey.id}`}
                          isOpen={physicianPopoverOpen === journey.id}
                        >
                          <PopoverHeader>
                            Physician Info
                          </PopoverHeader>
                          <PopoverBody>
                            <Row>
                              <Col md="12">Physician Mobile Number: {journey.physician?.phoneNumber}</Col>
                              <Col md="12">Physician
                                Unit/Department: {journey.physician?.healthcareFacility?.name}</Col>
                              <Col md="12">Physician Facility
                                Department: {journey.physician?.facilityDepartmentEntityDetail?.enDescription}</Col>
                              <Col md="12">Physician M-PESA Number: {journey.physician?.mpesaNumber}</Col>
                            </Row>
                          </PopoverBody>
                        </Popover>
                      </>
                    }
                  </td>
                  <td>{getJourneyScheduledDate(journey.scheduledSlotStart)}</td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link}
                              to={`/patient-visit/journey?patientBabyId=${journey.patientCase.patientBabyId}`}
                              color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">View/Edit</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
            {totalItems ?
              <div>
                <Row className="justify-content-center">
                  <JhiItemCount page={filterList.activePage + 1} total={totalItems}
                                itemsPerPage={filterList.itemsPerPage} />
                </Row>
                <Row className="justify-content-center">
                  <JhiPagination
                    activePage={filterList.activePage + 1}
                    onSelect={(currentPage) => {
                      updateFilters({ activePage: currentPage - 1 });
                    }}
                    maxButtons={5}
                    itemsPerPage={filterList.itemsPerPage}
                    totalItems={totalItems}
                  />
                </Row>
              </div>
              :
              <></>
            }
          </>
        ) : (
          !loading && <div className="alert alert-warning">No Journeys found</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
                           journey,
                           authentication,
                           healthcareFacility,
                           region,
                           village,
                           driver,
                           physician
                         }: IRootState) => ({
  journeyList: journey.entities,
  loading: journey.loading,
  totalItems: journey.totalItems,
  userCountryId: authentication.userCountryId,
  countries: region.countries,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN']),
  healthcareFacilitiesLoading: healthcareFacility.loading,
  allHealthcareFacilities: healthcareFacility.allFacility,
  villages: village.entities,
  villagesLoading: village.loading,
  drivers: driver.entities,
  driverLoading: driver.loading,
  physicians: physician.entities,
  physicianLoading: physician.loading
});

const mapDispatchToProps = {
  resetJourneys,
  getEntities,
  getAllFacility,
  getVillages,
  getDrivers,
  getPhysicians,
  getCountries
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Journey);
