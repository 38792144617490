import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { getNearestDrivers, resetNearestDrivers } from 'app/entities/driver/driver.reducer';

interface INearestDriverModalProps extends StateProps, DispatchProps {
  isOpen: boolean;
  toggle: () => void;
  villageId: string;
  onDriverSelect: (driver: any) => void;
}

const NearestDriverModal = (props: INearestDriverModalProps) => {
  const { isOpen, toggle, villageId, nearestDrivers, loading, onDriverSelect } = props;

  const PAGE_SIZE = 5;
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(PAGE_SIZE);

  const toggleModal = () => {
    setPage(0);
    setSize(PAGE_SIZE);
    toggle();
  };

  useEffect(() => {
    if (isOpen) {
      props.resetNearestDrivers();
      props.getNearestDrivers(villageId, page, size);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      props.getNearestDrivers(villageId, page, size);
    }
  }, [size, page]);

  const loadMore = () => {
    setSize(size + PAGE_SIZE);
  };

  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Nearest Drivers</ModalHeader>
      <ModalBody>
        <table className="table">
          <thead>
          <tr>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Home Village</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          {nearestDrivers && nearestDrivers.length > 0 ? nearestDrivers.map(driver => (
              <tr key={driver.id}>
                <td>{driver.firstName} {driver.lastName}</td>
                <td>{driver.phoneNumber}</td>
                <td>{driver.homeVillageName}</td>
                <td>
                  <Button color="primary" onClick={() => {
                    onDriverSelect(driver.id);
                    toggleModal();
                  }}>
                    Select Driver
                  </Button>
                </td>
              </tr>
            ))
            :
            <tr>
              <td colSpan={4} className="text-center">
                No drivers found, please add routes.
              </td>
            </tr>
          }
          </tbody>
        </table>
        {loading && <Spinner />}
        {nearestDrivers && nearestDrivers.length > 0 &&
          <div className="mt-3">
            <a href="#" onClick={(e) => {
              e.preventDefault();
              loadMore();
            }}
            >
              Load More
            </a>
          </div>
        }
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  nearestDrivers: storeState.driver.nearestDrivers,
  loading: storeState.driver.loading
});

const mapDispatchToProps = {
  getNearestDrivers,
  resetNearestDrivers
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(NearestDriverModal);
