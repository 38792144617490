import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Journey from './journey';
import JourneyCalendar from './journey-calendar';

const Routes = ({match}) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path='/journey-calendar' component={JourneyCalendar}/>
      <ErrorBoundaryRoute path={match.url} component={Journey}/>
    </Switch>
  </>
);

export default Routes;
