import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row, Table } from 'reactstrap';
import { getSortState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteStartType } from 'app/shared/model/enumerations/route-start-type.model';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { IRegion } from 'app/shared/model/region.model';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { IRootState } from 'app/shared/reducers';
import { getEntities, reset, searchEntities, updateEntity } from './route.reducer';
import { IRoute } from 'app/shared/model/route.model';
import { ILocation } from 'app/shared/model/location.model';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { displaySecondsAsHoursMinutes } from 'app/shared/util/date-utils';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import RouteSearchBar from './route-search-bar';
import { getWatotoCareSettings } from 'app/modules/administration/watotocare-settings/watotocare-settings.reducer';
import { RouteForm } from './route-update-modal';
import { RouteEndType } from 'app/shared/model/enumerations/route-end-type.model';

export interface IRouteProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export interface SearchState {
  startId: string,
  endId: string,
  startType: string,
  endType: string
}

export const Route = (props: IRouteProps) => {
  const [activeRoute, setActiveRoute] = useState<IRoute>();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const { match, loading, totalItems, searchSuccess, watotocareSettings } = props;

  const [searchState, setSearchState] = useState<SearchState>({
    startId: undefined,
    endId: undefined,
    startType: undefined,
    endType: undefined
  });
  const [activeCountry, setCountry] = useState<IRegion>({});
  const [settings, setSettings] = useState<any>([]);
  useEffect(() => {
    const ac = props.RegCountries.find(country => country.id === props.userCountryId);
    setCountry(ac);
    getWatotoCareSettings();
    setSettings(watotocareSettings.filter(set => {
      if (set.country.id === activeCountry?.id) {
        return set;
      } else {
        return set.id === 0;
      }

    }));
  }, [props.RegCountries, props.userCountryId, watotocareSettings]);

  const calculateRoutePrice = (distance: number) => {
    const costPerKm = watotocareSettings.find(setting => setting.country.id === (Object.keys(activeCountry).length > 0 ? activeCountry.id : props.userCountryId));
    console.log('COST>>', activeCountry, watotocareSettings, costPerKm);
    return distance * costPerKm.costKm;
  };

  const getAllEntities = () => {
    const { startId, endId, startType, endType } = searchState;
    if (startId || endId) {
      props.searchEntities(startId, endId, startType, endType, paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    getAllEntities();
  }, [searchState.startId, searchState.endId, searchState.startType, searchState.endType]);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    props.getWatotoCareSettings();
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1]
      });
    }
  }, [props.location.search]);

  // reset on component unmount
  useEffect(() => () => {
    props.reset();
  }, []);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage
    });

  const handleRouteQuery = (fromLocation: ILocation, toLocation: ILocation) => {
    setSearchState({
      startId: fromLocation ? fromLocation.id : undefined,
      endId: toLocation ? toLocation.id : undefined,
      startType: fromLocation ? fromLocation.locationType : undefined,
      endType: toLocation ? toLocation.locationType : undefined
    });
  };

  const handleUpdateRoute = (route: IRoute) => {
    setActiveRoute(route);
  };

  const handleUpdateModalClose = () => {
    setActiveRoute(null);
  };

  const handleSaveRoute = (route: IRoute) => {
    props.updateEntity(route);
    setActiveRoute(null);
  };

  const getRouteStart = (route: IRoute): string => {
    return route.startType === RouteStartType.VILLAGE ? `${route.startVillage?.name} / ${route.startVillage.council.name} / ${route.startVillage.council.parent.name}` : `${route.startFacility?.name} / ${route.startFacility?.council.name} / ${route.startFacility?.council.parent.name}`;
  };

  const getRouteEnd = (route: IRoute): string => {
    return route.endType === RouteEndType.VILLAGE ? `${route.endVillage?.name} / ${route.endVillage?.council.name} / ${route.endVillage?.council.parent.name}` : `${route.endFacility?.name} / ${route.endFacility?.council.name} / ${route.endFacility?.council.parent.name}`;
  };
  const { RegCountries } = props;
  const regCountriesList = RegCountries.filter((ele) => {
    return props.isAdmin ? 1 : ele.id === activeCountry?.id;
  });


  const { routeList } = props;

  return (
    <div>

      {activeRoute && (
        <RouteForm opened={true} routeEntity={activeRoute} watotocareSettings={settings} onSave={handleSaveRoute}
                   toggle={handleUpdateModalClose} />
      )}


      <h2 id="route-heading">
        Routes
      </h2>
      <Row>
        <Col md="12">
          <Link to={`/route/create-route`}
                className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Create new Route
          </Link>
        </Col>
      </Row>
      <Row className="justify-content-left">
        <Col md="12">
          <AvForm model={{}}>
            {regCountriesList ?
              <AvGroup>
                <Label id="typeLabel" for="region-type">
                  Country
                </Label>
                <AvInput
                  id="region-type"
                  type="select"
                  className="form-control"
                  name="type"
                  value={activeCountry?.id}
                  onChange={(event) => {

                    const ac = props.RegCountries.find(country => country.id === event.target.value);
                    setCountry(ac);
                    // setCountry(event.target.value)
                    setSettings(watotocareSettings.filter(set => {
                      if (set.country.id === ac?.id) {
                        return set;
                      } else {
                        return set.id === 0;
                      }

                    }));
                  }}
                >

                  {regCountriesList.length > 0 && regCountriesList?.map((country) => {

                    return <option value={country.id} key={'country-' + country.name}>{country.name}</option>;

                  })}
                </AvInput>
              </AvGroup>
              : ''
            }
          </AvForm>
        </Col>
      </Row>
      <div className="mt-2 mb-2 mr-2">
        <RouteSearchBar activeCountry={activeCountry?.id} test={'test'} onQuery={handleRouteQuery} />
      </div>
      <div className="table-responsive" style={{ minHeight: '200px' }}>
        {routeList && routeList.length > 0 && (
          <Table responsive>
            <thead>
            <tr>
              <th className="hand" onClick={sort('')}>
                Route ID
              </th>
              <th className="hand" onClick={sort('')}>
                Start
              </th>
              <th className="hand" onClick={sort('')}>
                End
              </th>
              <th className="hand" onClick={sort('distance')}>
                Distance (Km)<FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('durationSeconds')}>
                Duration (Min) <FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('')}>
                Price Type
              </th>
              <th className="hand" onClick={sort('price')}>
                Price<FontAwesomeIcon icon="sort" />
              </th>
              <th className="hand" onClick={sort('isActive')}>
                isActive <FontAwesomeIcon icon="sort" />
              </th>
              {/* <th className="hand" onClick={sort('description')}>
                  Description <FontAwesomeIcon icon="sort" />
                </th> */}
              <th />
            </tr>
            </thead>
            <tbody>
            {routeList.map((route, i) => (
              <tr key={`entity-${i}`}>
                <td>{route.id}</td>
                <td>{getRouteStart(route)}{` (${route.startType})`}</td>
                <td>{getRouteEnd(route)}{` (${route.endType})`}</td>
                <td>{route.distance}</td>
                <td>{displaySecondsAsHoursMinutes(route.durationSeconds)}</td>
                <td>{route.overridePrice ? 'Override' : 'Computed'}</td>
                <td>{route.overridePrice || calculateRoutePrice(route.distance)}</td>
                <td>{route.isActive ? 'Active' : 'Inactive'}</td>
                {/* <td>{route.description}</td> */}
                <td className="text-right">
                  <div className="btn-group flex-btn-group-container">
                    <Button tag={Link} to={`${match.url}/route-detail/${route.id}`} color="info" size="sm">
                      <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                    </Button>
                    <Button
                      onClick={() => {
                        handleUpdateRoute(route);
                      }}
                      color="primary"
                      size="sm"
                    >
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                    </Button>
                    <Button
                      tag={Link}
                      to={`${match.url}/${route.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                      color="danger"
                      size="sm"
                    >
                      <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        )}
        {searchSuccess && totalItems === 0 && (
          <div className="alert alert-warning mt-4">
            <span>No Routes found</span>
          </div>
        )}
      </div>
      {props.totalItems ? (
        <div className={routeList && routeList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems}
                          itemsPerPage={paginationState.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ route, watotocareSettings, authentication }: IRootState) => ({
  routeList: route.entities,
  loading: route.loading,
  totalItems: route.totalItems,
  searchSuccess: route.searchSuccess,
  watotocareSettings: watotocareSettings.settings,
  RegCountries: authentication.countries,
  userCountryId: authentication.userCountryId,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN'])
});

const mapDispatchToProps = {
  getEntities,
  getWatotoCareSettings,
  updateEntity,
  searchEntities,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Route);
