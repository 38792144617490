import { IDriver } from 'app/shared/model/driver.model';
import { IPatientCase } from 'app/shared/model/patient-case.model';
import { JourneyStatus } from 'app/shared/model/enumerations/journey-status.model';
import { IVillage } from 'app/shared/model/village.model';
import { IHealthcareFacility } from 'app/shared/model/healthcare-facility.model';
import { IPhysician } from 'app/shared/model/physician.model';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';
import { JourneyType } from 'app/shared/model/enumerations/journey-type.model';
import { IPatientVisit } from 'app/shared/model/patient-visit.model';

export interface IJourney {
  id?: string;
  type?: JourneyType;
  status?: JourneyStatus;
  createdAt?: Date;
  startedAt?: Date;
  completedAt?: Date;
  lastModifiedAt?: Date;
  scheduledSlotStart?: Date;
  scheduledSlotEnd?: Date;
  numberOfPassengers?: number;
  userId?: string;
  incidentReportId?: string;
  incidentReportDetail?: IWatotoCareEntityDetail;
  incidentReportDescription?: string;
  driverFee?: number;
  physicianFee?: number;
  totalDurationSeconds?: number;
  totalDistance?: number;
  driverId?: string;
  driver?: IDriver;
  physicianId?: string;
  physician?: IPhysician;
  startVillageId?: string;
  startVillage?: IVillage;
  endHealthcareFacilityId?: string;
  endHealthcareFacility?: IHealthcareFacility;
  patientCaseId?: string;
  patientCase?: IPatientCase;
  driverPaymentId?: string;
  physicianPaymentId?: string;
  visitCount?: number;
  patientVisitId?: string;
  patientVisit?: IPatientVisit;


  // endVillage?: any;
  // startHealthcareFacility?: any;
  // vehicleType?: any;
  // ambulance?: any;
  // payment?: any;
  // incidentReport?: any;
  // incidentReported?: boolean;
  // route?: IRoute;
}

export const defaultValue: Readonly<IJourney> = {};
