import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, Card, CardHeader, Col, Collapse, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { AvFeedback, AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import { IRootState } from 'app/shared/reducers';
import {
  getEntities as getVisits,
  getSettings as getVisitSettings,
  reset as resetVisit
} from '../patient-visit/patient-visit.reducer';
import { getEntity as getPatientBaby, reset as resetPatientBaby } from '../patient-baby/patient-baby.reducer';
import { createEntity, getEntities, reset, updateEntity } from '../journey/journey.reducer';
import { getEntity as getPatient, reset as resetPatient } from 'app/entities/patient/patient.reducer';
import './patient-visit-journey.scss';
import {
  getWatotoCareSettingsByCountryID
} from 'app/modules/administration/watotocare-settings/watotocare-settings.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import { getVillageName } from 'app/entities/village/village';
import { getAllFacility } from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import { getEntities as getDrivers } from 'app/entities/driver/driver.reducer';
import { getEntities as getPhysicians } from 'app/entities/physician/physician.reducer';
import { getVillagesByCountry as getVillages } from 'app/entities/village/village.reducer';
import { getHfName } from 'app/entities/healthcare-facility/healthcare-facility';
import {
  convertDateTimeFromServer,
  convertDateTimeToServer,
  convertSecondsToMinutes,
  daysSince
} from 'app/shared/util/date-utils';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_ACCESS_TOKEN } from 'app/config/constants';
import { JourneyType } from 'app/shared/model/enumerations/journey-type.model';
import moment from 'moment';
import { JourneyStatus } from 'app/shared/model/enumerations/journey-status.model';
import { getMasterEntitiesByEnumNames } from 'app/entities/watotocare-entity/watotocare-entity.reducer';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';
import { toCents } from 'app/shared/util/money-utils';
import { IPatientVisit } from 'app/shared/model/patient-visit.model';
import NearestDriverModal from 'app/entities/patient-visit/nearest-driver-modal';
import JourneyScheduleModal from 'app/entities/patient-visit/journey-schedule-modal';
import { RiskProfile } from 'app/shared/model/enumerations/risk-profile.model';
import { searchEntitiesAxios } from 'app/entities/route/route.reducer';

export interface IPatientVisitJourneyProps extends StateProps, DispatchProps, RouteComponentProps<{
  patientBabyId: string;
}> {
}

export const PatientVisitJourney = (props: IPatientVisitJourneyProps) => {
  const {
    patientBabyEntity,
    patientVisitJourneys,
    patientVisits,
    visitSettings,
    patientEntity,
    fetchSuccess,
    patientVisitFetchSuccess,
    watotocareSettings,
    loading,
    updating,
    watotoCareEntityList,
    drivers,
    physicians,
    healthcareFacilitiesLoading,
    allHealthcareFacilities,
    villagesLoading,
    villages
  } = props;
  const [redirecting, setRedirecting] = useState(false);
  const [disableButtons, setDisableButtons] = useState(false);
  const [journeys, setJourneys] = useState([]);
  const [previousVisit, setPreviousVisit] = useState<IPatientVisit>(null);
  const [lastDayForVisit, setLastDayForVisit] = useState<Date>(null);
  const [modal, setModal] = useState(false);
  const [nearestDriverModalOpen, setNearestDriverModalOpen] = useState(false);
  const [driverScheduleModalOpen, setDriverScheduleModalOpen] = useState(false);
  const [physicianScheduleModalOpen, setPhysicianScheduleModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [symptomCollapseOpen, setSymptomCollapseOpen] = useState(false);
  const [currentJourneyIndex, setCurrentJourneyIndex] = useState(null);
  const [incidentReportEntity, setIncidentReportEntity] = useState(null);
  const [selectedIncidentReport, setSelectedIncidentReport] = useState(null);
  const [rescheduleReasonEntity, setRescheduleReasonEntity] = useState(null);
  const [selectedRescheduleReason, setSelectedRescheduleReason] = useState(null);
  const [enableReschedule, setEnableReschedule] = useState(false);
  const [mapboxError, setMapboxError] = useState({ path1: false, path2: false });
  const initPathLocation = {
    driverLocation: { latitude: 0, longitude: 0, homeVillageId: null },
    startLocation: { latitude: 0, longitude: 0, villageId: null },
    endLocation: { latitude: 0, longitude: 0, healthcareFacilityId: null }
  };
  const [pathLocation, setPathLocation] = useState(initPathLocation);

  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
  const WATOTOCARE_ENTITY_ENUM_NAMES = ['JOURNEY_INCIDENT_REPORT', 'JOURNEY_RESCHEDULE_REASON'];

  enum ModalType {
    CANCEL = 'CANCEL',
    FINISH = 'FINISH',
  }

  const toggleNearestDriverModal = () => setNearestDriverModalOpen(!nearestDriverModalOpen);
  const toggleDriverScheduleModal = () => setDriverScheduleModalOpen(!driverScheduleModalOpen);
  const togglePhysicianScheduleModal = () => setPhysicianScheduleModalOpen(!physicianScheduleModalOpen);

  const getPatientBabyId = () => {
    const queryParams = new URLSearchParams(props.location.search);
    if (!queryParams.get('patientBabyId')) {
      alert('Invalid Baby Id');
      props.history.push('/patient');
      return null;
    }
    return queryParams.get('patientBabyId') || null;
  };

  const resetData = () => {
    props.reset();
    props.resetVisit();
    props.resetPatient();
    props.resetPatientBaby();
    setJourneys([]);
    setPathLocation({ ...initPathLocation });
  };

  const handleClose = (message = '') => {
    if (!redirecting) {
      if (message && message !== '') {
        alert(message);
      }
      setRedirecting(true);
      props.history.push('/');
    }
  };

  const toggleCollapse = (index: number) => {
    setCurrentJourneyIndex(currentJourneyIndex === index ? null : index);
  };

  const addJourney = () => {
    if (previousVisit.patientCaseId !== patientBabyEntity.patientCase.id || (journeys.length > 0 && journeys[journeys.length - 1].patientCaseId !== patientBabyEntity.patientCase.id)) {
      alert('Previous visit does not belong to this patient. Reloading page...');
      window.location.reload();
      return;
    }

    if (journeys.length < patientVisitJourneys.length + 1) {
      if (journeys.length > 0) {
        const previousJourney = journeys[journeys.length - 1];
        if (previousJourney.status !== JourneyStatus.CANCELED && previousJourney.status !== JourneyStatus.COMPLETE && previousJourney.type !== JourneyType.BYPASS) {
          alert('Previous visit is not completed. Cannot add a new journey');
          return;
        }

        if (previousVisit && previousJourney.status !== JourneyStatus.CANCELED && previousJourney.visitCount === previousVisit?.visitCount + 1) {
          const confirmation = confirm(`No conditions were added to the previous journey. Do you want to proceed adding a new journey for same follow-up #${previousVisit?.visitCount}?`);
          if (!confirmation) {
            return;
          }
        }
      }

      if (previousVisit?.riskProfile === RiskProfile.LOW) {
        const confirmation = confirm('This patient is currently at a LOW risk profile, are you sure you would like to add a new journey?');
        if (!confirmation) {
          return;
        }
      }

      let newJourneyType: JourneyType;
      switch (previousVisit?.riskProfile) {
        case 'HIGH':
          newJourneyType = JourneyType.PHYSICAL_TRANSPORT;
          break;
        case 'MED':
          newJourneyType = JourneyType.TELECONSULTATION;
          break;
        case 'LOW':
          if (watotocareSettings.allowLowRiskVisit) {
            newJourneyType = JourneyType.TELECONSULTATION;
          } else {
            alert('Previous visit has a low risk profile. No need to add a new journey');
            return;
          }
          break;
        default:
          alert('Previous visit does not have a risk profile. Cannot add a new journey');
          return;
      }

      if (previousVisit?.visitCount >= watotocareSettings.patientVisitLimit) {
        alert('You have reached the limit of patient visits');
        return;
      }

      const nextVisitSettings = visitSettings.find(vs => vs.index === previousVisit?.visitCount + 1);
      const nextToNextVisitSettings = visitSettings.find(vs => vs.index === previousVisit?.visitCount + 2);
      const babyAgeInDays = daysSince(patientBabyEntity.birthDate);
      let scheduledSlotStart = moment().set({ minute: 0 }).add(1, 'days').format('YYYY-MM-DDTHH:mm');
      const firstVisitDayForNextVisit = nextVisitSettings?.visitDay;
      let lastVisitDayForNextVisit: number;
      if (nextToNextVisitSettings && nextToNextVisitSettings.visitDay && nextToNextVisitSettings.visitDay > 0) {
        lastVisitDayForNextVisit = nextToNextVisitSettings.visitDay - 1;
      } else {
        lastVisitDayForNextVisit = watotocareSettings.dispatcherDashboardMaxAge;
      }
      if (lastVisitDayForNextVisit && lastVisitDayForNextVisit > 0) {
        setLastDayForVisit(moment(patientBabyEntity.birthDate).add(lastVisitDayForNextVisit, 'days').toDate());
      }
      if (firstVisitDayForNextVisit && firstVisitDayForNextVisit > 0) {
        if (babyAgeInDays < firstVisitDayForNextVisit) {
          scheduledSlotStart = moment().set({ minute: 0 }).add(firstVisitDayForNextVisit - babyAgeInDays, 'days').format('YYYY-MM-DDTHH:mm');
        }
      }

      const scheduledSlotEnd = moment(scheduledSlotStart).add(2, 'hours').format('YYYY-MM-DDTHH:mm');
      setJourneys([...journeys, {
        patientCaseId: patientBabyEntity.patientCase.id,
        type: newJourneyType,
        driverFee: 0,
        physicianFee: watotocareSettings.physicianVisitFee * 100,
        totalDistance: 0,
        totalDurationSeconds: 0,
        visitCount: previousVisit?.visitCount + 1,
        startVillageId: patientEntity.villageId,
        scheduledSlotStart: convertDateTimeToServer(scheduledSlotStart),
        scheduledSlotEnd: convertDateTimeToServer(scheduledSlotEnd)
      }]);
      toggleCollapse(journeys.length);
    }
  };

  const removeJourney = (index: number) => () => {
    const updatedJourneys = [...journeys];
    updatedJourneys.splice(index, 1);
    setJourneys(updatedJourneys);
    toggleCollapse(updatedJourneys.length - 1);
  };

  useEffect(() => {
    resetData();
    const patientBabyId = getPatientBabyId();
    props.getPatientBaby(patientBabyId);
  }, []);

  useEffect(() => {
    if (patientBabyEntity && patientBabyEntity.patientCase) {
      props.getEntities(patientBabyEntity.patientCase.id);
      props.getVisits(patientBabyEntity.patientCase.id);
      props.getPatient(patientBabyEntity.patientId);
    }
  }, [patientBabyEntity]);

  useEffect(() => {
    if (patientVisitFetchSuccess) {
      if (patientVisits.length === 0) {
        props.history.push(`/patient-visit/create?patientBabyId=${patientBabyEntity.id}`);
        return;
      }

      // sort by count and createdAt
      const visitsSortedByCount = [...patientVisits].sort((a, b) => {
        if (a.visitCount === b.visitCount) {
          return a.createdAt.localeCompare(b.createdAt);
        }
        return a.visitCount - b.visitCount;
      });
      const lastVisit = visitsSortedByCount[visitsSortedByCount.length - 1];
      setPreviousVisit(lastVisit);
    }
  }, [patientVisitFetchSuccess]);

  useEffect(() => {
    if (patientEntity && patientEntity.countryId) {
      props.getWatotoCareSettingsByCountryID(patientEntity.countryId);
      props.getVisitSettings(patientEntity.countryId);
      props.getAllFacility(patientEntity.countryId);
      props.getVillages(undefined, undefined, undefined, patientEntity.countryId);
      props.getDrivers(undefined, undefined, undefined, patientEntity.countryId);
      props.getPhysicians(patientEntity.countryId);
      props.getMasterEntitiesByEnumNames(WATOTOCARE_ENTITY_ENUM_NAMES, patientEntity.countryId);
    }
  }, [patientEntity]);

  useEffect(() => {
    if (fetchSuccess) {
      setJourneys([...patientVisitJourneys]);
      setCurrentJourneyIndex(patientVisitJourneys.length - 1);
    }
  }, [fetchSuccess]);


  useEffect(() => {
    if (props.updateSuccess) {
      // refresh the page
      window.location.reload();
    } else {
      if (props.errorMessage) {
        alert(props.errorMessage);
        props.reset();
        setDisableButtons(false);
      }
    }
  }, [props.updateSuccess, props.errorMessage]);

  useEffect(() => () => {
    resetData();
  }, []);

  useEffect(() => {
    if (watotoCareEntityList.length > 0) {
      // set watotoCareEntity for each entity in watotoCareEntityList
      const incidentReportEntityMaster = watotoCareEntityList.find(entity => entity.enumName === 'JOURNEY_INCIDENT_REPORT');
      if (incidentReportEntityMaster) {
        setIncidentReportEntity(incidentReportEntityMaster);
      }

      const rescheduleReasonEntityMaster = watotoCareEntityList.find(entity => entity.enumName === 'JOURNEY_RESCHEDULE_REASON');
      if (rescheduleReasonEntityMaster) {
        setRescheduleReasonEntity(rescheduleReasonEntityMaster);
      }
    }
  }, [watotoCareEntityList]);

  // AvTypeahead Data

  const hfOptionList = allHealthcareFacilities ?
    allHealthcareFacilities.map(hf => ({
      ...getHfName(hf.id, allHealthcareFacilities),
      id: hf.id
    })) : [];

  const villageOptionList = villages ?
    villages.map(village => ({
      ...getVillageName(village.id, villages),
      id: village.id
    })) : [];

  const driverOptionList = drivers ?
    drivers.map(driver => ({
      short: `${driver.firstName} ${driver.lastName}`,
      full: `${driver.firstName} ${driver.lastName}`,
      id: driver.id
    })) : [];


  const physicianOptionList = physicians ?
    physicians.map(physician => ({
      short: `${physician.firstName} ${physician.lastName}`,
      full: `${physician.firstName} ${physician.lastName}`,
      id: physician.id
    })) : [];

  const getRouteDistance = async () => {
    if (journeys.length > 0 && journeys[currentJourneyIndex] && (!journeys[currentJourneyIndex].status || journeys[currentJourneyIndex].status === JourneyStatus.SCHEDULED)) {
      const updatedMapboxError = { path1: false, path2: false };
      let totalCalculatedDistance = 0;
      let totalCalculatedDuration = 0;
      let driverFee = journeys[currentJourneyIndex]?.type === JourneyType.PHYSICAL_TRANSPORT
        ? watotocareSettings.driverWaitingFee
        : watotocareSettings.driverServiceFee;

      if (pathLocation.startLocation.latitude !== 0
        && pathLocation.endLocation.latitude !== 0
        && pathLocation.startLocation.longitude !== 0
        && pathLocation.endLocation.longitude !== 0
        && journeys[currentJourneyIndex]?.type === JourneyType.PHYSICAL_TRANSPORT
      ) {
        const existingVillageToHFRoutes = await searchEntitiesAxios(
          pathLocation.startLocation.villageId,
          pathLocation.endLocation.healthcareFacilityId,
          'VILLAGE',
          'HEALTHCARE_FACILITY',
          0,
          1,
          'distance,asc'
        );
        if (existingVillageToHFRoutes && existingVillageToHFRoutes.length > 0) {
          const villageToHFDistance = 2 * existingVillageToHFRoutes[0].distance;
          totalCalculatedDistance += villageToHFDistance;
          totalCalculatedDuration = 2 * existingVillageToHFRoutes[0].durationSeconds;
          driverFee += totalCalculatedDistance * watotocareSettings.costKm;
        } else {
          const villageToHFResponse = await fetch(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${pathLocation.startLocation.longitude},${pathLocation.startLocation.latitude};${pathLocation.endLocation.longitude},${pathLocation.endLocation.latitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
            { method: 'GET' }
          );
          const villageToHF = await villageToHFResponse.json();
          if (villageToHFResponse.ok && villageToHF.routes[0]?.distance && villageToHF.routes[0]?.duration) {
            const villageToHFDistance = 2 * ((villageToHF.routes[0]?.distance) / 1000);
            totalCalculatedDistance += villageToHFDistance;
            totalCalculatedDuration += 2 * villageToHF.routes[0].duration.toString().split('.')[0];
            driverFee += villageToHFDistance * watotocareSettings.costKm;
          } else {
            updatedMapboxError.path1 = true;
          }
        }
      }

      if (pathLocation.driverLocation.latitude !== 0
        && pathLocation.driverLocation.longitude !== 0
        && pathLocation.startLocation.latitude !== 0
        && pathLocation.startLocation.longitude !== 0
        && pathLocation.driverLocation.latitude !== pathLocation.startLocation.latitude
        && pathLocation.driverLocation.longitude !== pathLocation.startLocation.longitude
      ) {
        const existingDriverToVillageRoutes = await searchEntitiesAxios(
          pathLocation.driverLocation.homeVillageId,
          pathLocation.startLocation.villageId,
          'VILLAGE',
          'VILLAGE',
          0,
          1,
          'distance,asc'
        );
        if (existingDriverToVillageRoutes && existingDriverToVillageRoutes.length > 0) {
          const driverToVillageDistance = 2 * existingDriverToVillageRoutes[0].distance;
          totalCalculatedDistance += driverToVillageDistance;
          totalCalculatedDuration += 2 * existingDriverToVillageRoutes[0].durationSeconds;
          driverFee += totalCalculatedDistance * watotocareSettings.costKm;
        } else {
          const driverToVillageResponse = await fetch(
            `https://api.mapbox.com/directions/v5/mapbox/driving/${pathLocation.driverLocation.longitude},${pathLocation.driverLocation.latitude};${pathLocation.startLocation.longitude},${pathLocation.startLocation.latitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
            { method: 'GET' }
          );
          const driverToVillage = await driverToVillageResponse.json();
          if (driverToVillageResponse.ok && driverToVillage.routes[0]?.distance && driverToVillage.routes[0]?.duration) {
            const driverToVillageDistance = 2 * ((driverToVillage.routes[0]?.distance) / 1000);
            totalCalculatedDistance += driverToVillageDistance;
            totalCalculatedDuration += 2 * driverToVillage.routes[0].duration.toString().split('.')[0];
            driverFee += driverToVillageDistance * watotocareSettings.costKm;
          } else {
            updatedMapboxError.path2 = true;
          }
        }
      }

      journeys[currentJourneyIndex].totalDistance = totalCalculatedDistance;
      journeys[currentJourneyIndex].totalDurationSeconds = totalCalculatedDuration;
      journeys[currentJourneyIndex].driverFee = driverFee * 100;
      setJourneys([...journeys]);
      setMapboxError(updatedMapboxError);
    }
  };

  useEffect(() => {
    getRouteDistance();
  }, [pathLocation]);

  const changeJourneyType = (index: number, value: JourneyType) => {
    const updatedJourneys = [...journeys];
    updatedJourneys[index].type = value;
    setJourneys(updatedJourneys);
    let updatedPathLocation = { ...pathLocation };
    if (value === JourneyType.BYPASS) {
      updatedPathLocation = { ...initPathLocation };
    } else if (value === JourneyType.TELECONSULTATION) {
      updatedPathLocation.endLocation.latitude = 0;
      updatedPathLocation.endLocation.longitude = 0;
    }
    setPathLocation(updatedPathLocation);
  };

  useEffect(() => {
    // set path location for the selected journey
    if (journeys.length > 0 && journeys[currentJourneyIndex] && villageOptionList.length > 0 && hfOptionList.length > 0 && driverOptionList.length > 0) {
      const journey = journeys[currentJourneyIndex];
      const locationData = { ...initPathLocation };
      if (journey.startVillageId) {
        const startVillage = villageOptionList.find(v => v.id === journey.startVillageId);
        if (startVillage) {
          locationData.startLocation.latitude = startVillage.latitude;
          locationData.startLocation.longitude = startVillage.longitude;
          locationData.startLocation.villageId = startVillage.id;
        }
      }
      if (journey.endHealthcareFacilityId) {
        const endHF = hfOptionList.find(v => v.id === journey.endHealthcareFacilityId);
        if (endHF) {
          locationData.endLocation.latitude = endHF.latitude;
          locationData.endLocation.longitude = endHF.longitude;
          locationData.endLocation.healthcareFacilityId = endHF.id;
        }
      }
      if (journey.driverId) {
        const driver = drivers.find(d => d.id === journey.driverId);
        if (driver) {
          locationData.driverLocation.latitude = driver.homeVillage.locationLat;
          locationData.driverLocation.longitude = driver.homeVillage.locationLon;
          locationData.driverLocation.homeVillageId = driver.homeVillage.id;
        }
      }

      // compare pathLocation with locationData to avoid unnecessary re-renders
      if (pathLocation.driverLocation.latitude !== locationData.driverLocation.latitude
        || pathLocation.driverLocation.longitude !== locationData.driverLocation.longitude
        || pathLocation.startLocation.latitude !== locationData.startLocation.latitude
        || pathLocation.startLocation.longitude !== locationData.startLocation.longitude
        || pathLocation.endLocation.latitude !== locationData.endLocation.latitude
        || pathLocation.endLocation.longitude !== locationData.endLocation.longitude
      ) {
        setPathLocation(locationData);
      }
    }
  }, [currentJourneyIndex, villageOptionList, hfOptionList, driverOptionList]);

  const toggleModal = (type = null) => {
    setModal(!modal);
    if (type) {
      setModalType(type);
    }
  };

  const showCancelPopup = (index: number) => () => {
    toggleModal(ModalType.CANCEL);
  };

  const showFinishPopup = (index: number) => () => {
    toggleModal(ModalType.FINISH);
  };

  const beforeSubmit = (event) => {
    event.preventDefault();
    event.nativeEvent.preventDefault();
    event.persist();
  };


  const saveEntity = (event, errors, values) => {
    let journey = journeys[currentJourneyIndex];

    // set only required values for bypassed journeys
    if (values.type === JourneyType.BYPASS) {
      if (journey.physicianFee !== 0 && !values.physicianId) {
        alert('Please select a physician or skip the physician fee');
        return;
      }
      journey = {};
      values = {
        type: JourneyType.BYPASS,
        status: JourneyStatus.COMPLETE,
        patientCaseId: patientBabyEntity.patientCase.id,
        physicianId: values.physicianId ?? null,
        physicianFee: values.physicianFee,
        visitCount: values.visitCount
      };
    }

    if (event.nativeEvent.submitter.id.indexOf('cancel-journey') !== -1) {
      journey = journeys[currentJourneyIndex];
      values.status = JourneyStatus.CANCELED;
    }

    if (event.nativeEvent.submitter.id.indexOf('start-journey') !== -1) {
      if (!values.driverId || !values.physicianId) {
        alert('Please select a driver and physician');
        return;
      }
      values.status = JourneyStatus.IN_PROGRESS;
      values.startedAt = convertDateTimeToServer(new Date());
    }

    if (event.nativeEvent.submitter.id.indexOf('finish-journey') !== -1) {
      values.status = JourneyStatus.COMPLETE;
      values.completedAt = convertDateTimeToServer(new Date());
    }

    // convert datetime values to UTC
    if (values.scheduledSlotStart) {
      values.scheduledSlotStart = convertDateTimeToServer(values.scheduledSlotStart);
    }
    if (values.scheduledSlotEnd) {
      values.scheduledSlotEnd = convertDateTimeToServer(values.scheduledSlotEnd);
    }

    // convert fees to cents
    if (values.driverFee) {
      values.driverFee = toCents(values.driverFee);
    }

    if (values.physicianFee) {
      values.physicianFee = toCents(values.physicianFee);
    }

    if (errors.length === 0 || values.type === JourneyType.BYPASS) {
      const entity = {
        ...journey,
        ...values
      };

      // loop through the entity and remove any empty values
      Object.keys(entity).forEach(key => {
        if (entity[key] === '') {
          delete entity[key];
        }
      });

      setDisableButtons(true);
      if (entity.id) {
        props.updateEntity(entity);
      } else {
        props.createEntity(entity);
      }
    }
  };

  const validateScheduledSlotStart = (value, context) => {
    if (context.id) return true;
    if (!value) return 'Scheduled Slot Start is required';
    return moment(value).isAfter(moment()) || 'Scheduled Slot Start must be in the future';
  };

  const validateScheduledSlotEnd = (value, context) => {
    if (context.id) return true;
    if (!value) return 'Scheduled Slot End is required';

    const scheduledSlotStart = context.scheduledSlotStart;
    if (moment(value).isBefore(moment(scheduledSlotStart))) {
      return 'Scheduled Slot End must be after Scheduled Slot Start';
    }
    if (moment(value).format('YYYY-MM-DD') !== moment(scheduledSlotStart).format('YYYY-MM-DD')) {
      return 'Scheduled Slot End must be on the same day as Scheduled Slot Start';
    }
    return true;
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.patient.baby.home.createOrEditLabel">
            Patient Follow-up Journeys
          </h2>
        </Col>
      </Row>
      <br />
      <Row className="justify-content-center">
        <Col md="8">
          <Row className="justify-content-left">
            <Col md="6">
              <h6 className="text-primary">Mother Details</h6>
              <div>
                watotoCare ID: {patientEntity.wcId}
                <br />
                Mother Name: {`${patientEntity.firstName} ${patientEntity.lastName}`}
              </div>
            </Col>
            <Col md="6">
              <h6 className="text-primary">Baby Details</h6>
              <div>
                Date of Birth: {patientBabyEntity.birthDate} (Age in Days: {daysSince(patientBabyEntity.birthDate)})
                <br />
                Baby
                Name: {patientBabyEntity.firstName ? `${patientBabyEntity.firstName} ${patientBabyEntity.lastName}` : '-'}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-left mt-3">
            <Col md="6">
              <h6 className="text-primary">Case Details</h6>
              <div>
                Enrollment
                Date: {moment(convertDateTimeFromServer(patientBabyEntity.createdAt)).format('MMMM Do, YYYY')}
                <br />
                Discharge
                Date: {patientBabyEntity.patientCase?.babyDischargedAt ? moment(convertDateTimeFromServer(patientBabyEntity.patientCase.babyDischargedAt)).format('MMMM Do, YYYY') : '-'}
              </div>
            </Col>
          </Row>
        </Col>
        <Col md="8" className="mt-3">
          <Card color="light" className="mt-3 p-0">
            <CardHeader style={{ cursor: 'pointer' }} onClick={() => setSymptomCollapseOpen(!symptomCollapseOpen)}>
              <h6>
                Patient Conditions
                <FontAwesomeIcon className="float-right mt-1"
                                 icon={symptomCollapseOpen ? faCaretUp : faCaretDown} />
              </h6>
            </CardHeader>
            <Collapse className="mt-1 mb-3 p-3" isOpen={symptomCollapseOpen}>
              <Row className="justify-content-center">
                <Col md="12">
                  <h4 className="text-center">
                    Risk Profile : <span className="text-danger">{previousVisit?.riskProfile}</span>
                  </h4>
                </Col>
                <Col md="6" className="mt-1">
                  <h6 className="text-primary text-center">Mother Conditions</h6>
                  <div className="pl-5 mt-4">
                    {previousVisit?.motherSymptoms?.length > 0 ? previousVisit?.motherSymptoms.map((symptom, i) => (
                        <div key={`mother-symptom-${i}`}>
                          <FontAwesomeIcon icon="minus" fixedWidth /> &nbsp; {symptom?.symptom?.enCondition}
                        </div>

                      ))
                      :
                      <div>
                        <FontAwesomeIcon icon="minus" fixedWidth /> &nbsp; No Conditions
                      </div>
                    }
                  </div>
                </Col>
                <Col md="6" className="mt-1">
                  <h6 className="text-primary text-center">Baby Conditions</h6>
                  <div className="pl-5 mt-4">
                    {previousVisit?.babySymptoms?.length > 0 ? previousVisit?.babySymptoms.map((symptom, i) => (
                        <div key={`baby-symptom-${i}`}>
                          <FontAwesomeIcon icon="minus" fixedWidth /> &nbsp; {symptom?.symptom?.enCondition}
                        </div>
                      ))
                      :
                      <div>
                        <FontAwesomeIcon icon="minus" fixedWidth /> &nbsp; No Conditions
                      </div>
                    }
                  </div>
                </Col>
              </Row>
            </Collapse>
          </Card>
        </Col>
      </Row>
      <br />
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              <Row className="justify-content-left">
                <Col md="12">
                  <Button
                    color="primary"
                    className="float-right"
                    id="jh-create-entity"
                    onClick={addJourney}
                    disabled={journeys.length >= patientVisitJourneys.length + 1}
                  >
                    <FontAwesomeIcon icon="plus" />
                    &nbsp; Add Journey for Follow-up #{previousVisit?.visitCount ? previousVisit?.visitCount : ''}
                  </Button>
                </Col>
              </Row>
              {/* Loop through journeys */}
              {patientBabyEntity.patientCase?.id && journeys && journeys.length > 0 && journeys[journeys.length - 1].patientCaseId === patientBabyEntity.patientCase.id ? (
                // eslint-disable-next-line complexity
                journeys.map((journey, i) => (
                  <Row key={`journey-${i}`} className="justify-content-center">
                    {/* Collapsible Accordion using Collapse from reactstrap if one is open then collapse others */}
                    <Col md="12">
                      <Card color="light" className="mt-3 p-0">
                        <CardHeader
                          style={{ cursor: 'pointer', backgroundColor: '#e7f1ff', color: '#0c63e4' }}
                          onClick={toggleCollapse.bind(this, i)}
                        >
                          <h6>
                            Journey for Follow-up
                            #{journey.visitCount - 1}
                            {!journey.id ? moment().isAfter(moment(lastDayForVisit)) ? ' (Appointment Overdue)' : ` (Needs appointment before ${moment(lastDayForVisit).format('MMMM Do, YYYY')})` : ''}
                            <FontAwesomeIcon className="float-right mt-1"
                                             icon={currentJourneyIndex === i ? faCaretUp : faCaretDown} />
                          </h6>
                        </CardHeader>
                        <Collapse className="mt-3 p-3" isOpen={currentJourneyIndex === i}>
                          {/* Journey Form */}
                          <AvForm model={journey} onSubmit={saveEntity}
                                  beforeSubmitValidation={beforeSubmit}>
                            <Row className="justify-content-left">
                              {/* Journey Hidden Fields */}
                              {journey.id && <AvField type="hidden" name="id" />}
                              <AvField type="hidden" name="visitCount" />
                              {/* Journey Type */}
                              <Col md={journey.type === JourneyType.BYPASS ? '12' : '6'}>
                                <AvGroup>
                                  <AvField
                                    type="select"
                                    id={'journey-type-' + i}
                                    name="type"
                                    label="Journey Type"
                                    value={journey.type}
                                    disabled={journey.id}
                                    onChange={(e) => changeJourneyType(i, e.target.value)}
                                  >
                                    <option disabled selected value="">-- select an option --</option>
                                    {Object.values(JourneyType).map((type, j) => (
                                      <option key={`journey-type-${j}`} value={type}>
                                        {type}
                                      </option>
                                    ))}
                                  </AvField>
                                </AvGroup>
                              </Col>
                              {journey.type !== JourneyType.BYPASS &&
                                <>
                                  {/* Number of Passengers */}
                                  <Col md="6">
                                    <AvGroup>
                                      <AvField
                                        type="number"
                                        id={'journey-numberOfPassengers-' + i}
                                        name="numberOfPassengers"
                                        label="Number of Passengers"
                                        disabled={journey.id && !enableReschedule}
                                        min="0"
                                        validate={{
                                          required: { value: true, errorMessage: 'This field is required.' }
                                        }}
                                      />
                                    </AvGroup>
                                  </Col>

                                  {/* Scheduled Slot Start */}
                                  <Col md="6">
                                    <AvGroup>
                                      <AvField
                                        type="datetime-local"
                                        id={'journey-scheduledSlotStart-' + i}
                                        name="scheduledSlotStart"
                                        label="Scheduled Slot Start"
                                        value={journey.scheduledSlotStart ? convertDateTimeFromServer(journey.scheduledSlotStart) : ''}
                                        onChange={(e) => {
                                          const updatedJourneys = [...journeys];
                                          updatedJourneys[i].scheduledSlotStart = e.target.value;
                                          updatedJourneys[i].scheduledSlotEnd = moment(e.target.value).add(2, 'hours').format('YYYY-MM-DDTHH:mm');
                                          setJourneys(updatedJourneys);
                                        }}
                                        disabled={journey.id && !enableReschedule}
                                        validate={{
                                          required: { value: true, errorMessage: 'This field is required.' }
                                        }}
                                      />
                                      <AvField
                                        name="scheduledSlotStartValidation"
                                        value={journey.scheduledSlotStart}
                                        type="hidden"
                                        validate={{
                                          custom: (value) => validateScheduledSlotStart(value, journey)
                                        }}
                                      />
                                    </AvGroup>
                                  </Col>

                                  {/* Scheduled Slot End */}
                                  <Col md="6">
                                    <AvGroup>
                                      <AvField
                                        type="datetime-local"
                                        id={'journey-scheduledSlotEnd-' + i}
                                        name="scheduledSlotEnd"
                                        label="Scheduled Slot End"
                                        value={journey.scheduledSlotEnd ? convertDateTimeFromServer(journey.scheduledSlotEnd) : ''}
                                        onChange={(e) => {
                                          const updatedJourneys = [...journeys];
                                          updatedJourneys[i].scheduledSlotEnd = e.target.value;
                                          setJourneys(updatedJourneys);
                                        }}
                                        disabled={journey.id && !enableReschedule}
                                        validate={{
                                          required: { value: true, errorMessage: 'This field is required.' }
                                        }}
                                      />
                                      <AvField
                                        name="scheduledSlotEndValidation"
                                        value={journey.scheduledSlotEnd}
                                        type="hidden"
                                        validate={{
                                          custom: (value) => validateScheduledSlotEnd(value, journey)
                                        }}
                                      />
                                    </AvGroup>
                                  </Col>

                                  {/* Start Village */}
                                  <Col md="6">
                                    <AvGroup>
                                      <Label id={'startVillageLabel-' + i} for={'journey-startVillage-' + i}>
                                        Start Village
                                      </Label>
                                      {journey.id && !enableReschedule ?
                                        <AvField
                                          type="text"
                                          id={'journey-startVillage-text-' + i}
                                          name="startVillageText"
                                          value={journey.startVillage?.name ? journey.startVillage?.name : 'Home Village'}
                                          readOnly
                                          disabled={true}
                                        />
                                        :
                                        <VillageAvTypeahead
                                          labelKey="name"
                                          valueKey="id"
                                          data={villageOptionList}
                                          placeholder={journey.startVillageId ? getVillageName(journey.startVillageId, villages).full : 'Home Village'}
                                          id={'journey-startVillage-' + i}
                                          loading={villagesLoading}
                                          disabled={journey.id && !enableReschedule}
                                          onChange={(data) => {
                                            if (data) {
                                              // set start village id in journey
                                              journeys.find(j => j.id === journey.id).startVillageId = data;
                                              setJourneys([...journeys]);

                                              // set start location in pathLocation
                                              const locData = villageOptionList.find(v => v.id === data);
                                              const locationData = { ...pathLocation };
                                              locationData.startLocation.latitude = locData.latitude;
                                              locationData.startLocation.longitude = locData.longitude;
                                              locationData.startLocation.villageId = locData.id;
                                              setPathLocation(locationData);
                                            } else {
                                              // set start village id in journey
                                              journeys.find(j => j.id === journey.id).startVillageId = null;
                                              setJourneys([...journeys]);

                                              // set start location in pathLocation
                                              const locationData = { ...pathLocation };
                                              locationData.startLocation.latitude = 0;
                                              locationData.startLocation.longitude = 0;
                                              locationData.startLocation.villageId = null;
                                              setPathLocation(locationData);
                                            }
                                          }}
                                          input={{
                                            id: 'journey-startVillage',
                                            name: 'startVillageId',
                                            required: true
                                          }}
                                        />
                                      }
                                      <AvFeedback>This field is required.</AvFeedback>
                                    </AvGroup>
                                  </Col>

                                  {/* End Health Facility */}
                                  {journeys[currentJourneyIndex]?.type === JourneyType.PHYSICAL_TRANSPORT ?
                                    <Col md="6">
                                      <AvGroup>
                                        <Label id={'endHealthcareFacilityLabel-' + i}
                                               for={'journey-endHealthcareFacility-' + i}>
                                          End Health Facility
                                        </Label>
                                        {journey.id && !enableReschedule ?
                                          <AvField
                                            type="text"
                                            id={'journey-endHealthcareFacility-text-' + i}
                                            name="endHealthcareFacilityText"
                                            value={journey.endHealthcareFacility?.name ? journey.endHealthcareFacility?.name : 'Health Facility'}
                                            readOnly
                                            disabled={true}
                                          />
                                          :
                                          <VillageAvTypeahead
                                            labelKey="name"
                                            valueKey="id"
                                            data={hfOptionList}
                                            placeholder={journey.endHealthcareFacilityId ? getHfName(journey.endHealthcareFacilityId, allHealthcareFacilities).full : 'Health Facility'}
                                            id={'journey-endHealthcareFacility-' + i}
                                            loading={healthcareFacilitiesLoading}
                                            onChange={(data) => {
                                              if (data) {
                                                // set end health facility id in journey
                                                journeys.find(j => j.id === journey.id).endHealthcareFacilityId = data;
                                                setJourneys([...journeys]);

                                                // set end location in pathLocation
                                                const locData = hfOptionList.find(v => v.id === data);
                                                const locationData = { ...pathLocation };
                                                locationData.endLocation.latitude = locData.latitude;
                                                locationData.endLocation.longitude = locData.longitude;
                                                locationData.endLocation.healthcareFacilityId = locData.id;
                                                setPathLocation(locationData);
                                              } else {
                                                // set end health facility id in journey
                                                journeys.find(j => j.id === journey.id).endHealthcareFacilityId = null;
                                                setJourneys([...journeys]);

                                                // set end location in pathLocation
                                                const locationData = { ...pathLocation };
                                                locationData.endLocation.latitude = 0;
                                                locationData.endLocation.longitude = 0;
                                                locationData.endLocation.healthcareFacilityId = null;
                                                setPathLocation(locationData);
                                              }
                                            }}
                                            input={{
                                              id: 'journey-endHealthcareFacility',
                                              name: 'endHealthcareFacilityId',
                                              required: true
                                            }}
                                          />
                                        }
                                        <AvFeedback>This field is required.</AvFeedback>
                                      </AvGroup>
                                    </Col>
                                    :
                                    <Col md="6"></Col>
                                  }

                                  {/* Driver */}
                                  <Col md="6">
                                    <AvGroup>
                                      <Label id={'driverLabel-' + i} for={'journey-driver-' + i}>
                                        Driver&nbsp;
                                        {(!journey.id || enableReschedule) && journey.startVillageId &&
                                          <>
                                            <a className="text-primary" href="#"
                                               onClick={(e) => {
                                                 e.preventDefault();
                                                 toggleNearestDriverModal();
                                               }}>
                                              (Nearest Drivers)
                                            </a>
                                            <NearestDriverModal isOpen={nearestDriverModalOpen}
                                                                toggle={toggleNearestDriverModal}
                                                                villageId={journey.startVillageId}
                                                                onDriverSelect={(driverId) => {
                                                                  // set driver id in journey
                                                                  const selectedDriver = drivers.find(d => d.id === driverId);
                                                                  const currentJourney = journeys.find(j => j.id === journey.id);
                                                                  currentJourney.driverId = driverId;
                                                                  currentJourney.driver = selectedDriver;

                                                                  setJourneys([...journeys]);

                                                                  // set driver location in pathLocation
                                                                  const driver = drivers.find(d => d.id === driverId);
                                                                  const locationData = { ...pathLocation };
                                                                  locationData.driverLocation.latitude = driver.homeVillage.locationLat;
                                                                  locationData.driverLocation.longitude = driver.homeVillage.locationLon;
                                                                  locationData.driverLocation.homeVillageId = driver.homeVillageId;
                                                                  setPathLocation(locationData);
                                                                }}
                                            />
                                          </>
                                        }
                                      </Label>
                                      {journey.id && !enableReschedule ?
                                        <>
                                          <AvField
                                            type="text"
                                            id={'journey-driver-text-' + i}
                                            name="driverText"
                                            value={journey.driver?.firstName && journey.driver?.lastName ? `${journey.driver?.firstName} ${journey.driver?.lastName}` : 'Driver'}
                                            readOnly
                                            disabled={true}
                                          />
                                          {/* hidden field for driver id */}
                                          <AvField type="hidden"
                                                   id={'journey-driver-' + i}
                                                   name="driverId"
                                                   value={journey.driverId}
                                          />
                                        </>
                                        :
                                        <>
                                          <VillageAvTypeahead
                                            labelKey="full"
                                            valueKey="id"
                                            data={driverOptionList}
                                            placeholder={journey.driver?.firstName && journey.driver?.lastName ? `${journey.driver?.firstName} ${journey.driver?.lastName}` : 'Driver'}
                                            id={'journey-driver-' + i}
                                            loading={props.driverLoading}
                                            onChange={(data) => {
                                              if (data) {
                                                // set driver id in journey
                                                journeys.find(j => j.id === journey.id).driverId = data;
                                                setJourneys([...journeys]);

                                                // set driver location in pathLocation
                                                const driver = drivers.find(d => d.id === data);
                                                const locationData = { ...pathLocation };
                                                locationData.driverLocation.latitude = driver.homeVillage.locationLat;
                                                locationData.driverLocation.longitude = driver.homeVillage.locationLon;
                                                locationData.driverLocation.homeVillageId = driver.homeVillageId;
                                                setPathLocation(locationData);
                                              } else {
                                                // set driver id in journey
                                                journeys.find(j => j.id === journey.id).driverId = null;
                                                setJourneys([...journeys]);

                                                // set driver location in pathLocation
                                                const locationData = { ...pathLocation };
                                                locationData.driverLocation.latitude = 0;
                                                locationData.driverLocation.longitude = 0;
                                                locationData.driverLocation.homeVillageId = null;
                                                setPathLocation(locationData);
                                              }
                                            }}
                                            input={{
                                              id: 'journey-driver',
                                              name: 'driverId'
                                            }}
                                          />
                                          <span className="schedule-calendar-icon">
                                            {journey.driverId &&
                                              <FontAwesomeIcon
                                                icon="calendar"
                                                onClick={() => toggleDriverScheduleModal()}
                                              />
                                            }
                                          </span>
                                        </>
                                      }
                                    </AvGroup>
                                  </Col>

                                  <Col md="6">
                                    <AvGroup>
                                      <Label id={'driverFeeLabel-' + i} for={'driverFee-' + i}>
                                        Driver Fee
                                      </Label>
                                      <AvField id={'driverFee-' + i}
                                               type="number"
                                               className="form-control"
                                               name="driverFee"
                                               value={journey.driverFee ? (journey.driverFee / 100).toFixed(2) : 0}
                                               readOnly
                                               disabled={true} />
                                    </AvGroup>
                                  </Col>
                                </>
                              }

                              {/* Physician */}
                              <Col md="6">
                                <AvGroup>
                                  <Label id={'physicianLabel-' + i} for={'journey-physician-' + i}>
                                    Physician
                                  </Label>
                                  {journey.id && !enableReschedule ?
                                    <>
                                      <AvField
                                        type="text"
                                        id={'journey-physician-text-' + i}
                                        name="physicianText"
                                        value={journey.physician?.firstName && journey.physician?.lastName ? `${journey.physician?.firstName} ${journey.physician?.lastName}` : 'Physician'}
                                        readOnly
                                        disabled={true}
                                      />

                                      {/* hidden field for physician id */}
                                      <AvField type="hidden"
                                               id={'journey-physician-' + i}
                                               name="physicianId"
                                               value={journey.physicianId}
                                      />
                                    </>
                                    :
                                    <>
                                      <VillageAvTypeahead
                                        labelKey="full"
                                        valueKey="id"
                                        data={physicianOptionList}
                                        placeholder={journey.physician?.firstName && journey.physician?.lastName ? `${journey.physician?.firstName} ${journey.physician?.lastName}` : 'Physician'}
                                        id={'journey-physician-' + i}
                                        loading={props.physicianLoading}
                                        onChange={(data) => {
                                          if (data) {
                                            journeys.find(j => j.id === journey.id).physicianId = data;
                                            setJourneys([...journeys]);
                                          } else {
                                            journeys.find(j => j.id === journey.id).physicianId = null;
                                            setJourneys([...journeys]);
                                          }
                                        }}
                                        input={{
                                          id: 'journey-physician',
                                          name: 'physicianId'
                                        }}
                                      />
                                      <span className="schedule-calendar-icon">
                                            {journey.physicianId &&
                                              <FontAwesomeIcon
                                                icon="calendar"
                                                onClick={() => togglePhysicianScheduleModal()}
                                              />
                                            }
                                          </span>
                                    </>
                                  }
                                </AvGroup>
                              </Col>

                              <Col md="6">
                                <AvGroup>
                                  <Label id={'physicianFeeLabel-' + i} for={'physicianFee-' + i}>
                                    Physician Fee
                                  </Label>
                                  <AvField id={'physicianFee-' + i}
                                           type="number"
                                           className="form-control"
                                           name="physicianFee"
                                           value={journey.physicianFee ? (journey.physicianFee / 100).toFixed(2) : 0}
                                           readOnly
                                           disabled={true} />
                                </AvGroup>
                              </Col>

                              {journey.type !== JourneyType.BYPASS &&
                                <>
                                  {/* MapBox Derived Fields */}
                                  {(mapboxError.path1 || mapboxError.path2) && <Col md="12" className="mt-3 mb-3">
                                    <h6 className="text-danger">Could not find all the routes via mapbox</h6>
                                  </Col>}
                                  <Col md="6">
                                    <AvGroup>
                                      <Label id={'totalDistanceLabel-' + i} for={'journey-totalDistance-' + i}>
                                        Total Distance (km)
                                      </Label>
                                      <AvField
                                        id={'journey-totalDistance-' + i}
                                        type="text"
                                        className="form-control"
                                        name="totalDistance"
                                        value={journey.totalDistance?.toFixed(1)}
                                        readOnly
                                        disabled={true}
                                      />
                                    </AvGroup>
                                  </Col>

                                  <Col md="6">
                                    <AvGroup>
                                      <Label id={'totalDurationMinutesLabel-' + i}
                                             for={'journey-totalDurationMinutes-' + i}>
                                        Duration (Minutes)
                                      </Label>
                                      <AvField
                                        id={'journey-totalDurationMinutes-' + i}
                                        type="number" className="form-control"
                                        name="totalDurationMinutes"
                                        value={convertSecondsToMinutes(journey.totalDurationSeconds)?.toFixed(2)}
                                        readOnly
                                        disabled={true} />
                                    </AvGroup>
                                  </Col>

                                  {/* Journey Reschedule Reason */}
                                  {journey.id && enableReschedule && rescheduleReasonEntity !== null &&
                                    <>
                                      <Col md="6">
                                        <AvGroup>
                                          <AvField
                                            type="select"
                                            id={'journey-rescheduleReason-' + i}
                                            name="rescheduleReason"
                                            label="Reschedule Reason"
                                            validate={{
                                              required: { value: true, errorMessage: 'This field is required.' }
                                            }}
                                            onChange={(e) => {
                                              if (e.target.value && e.target.value !== '') {
                                                const selected = rescheduleReasonEntity.watotoCareEntityDetails.find((entity: IWatotoCareEntityDetail) => entity.id === e.target.value);
                                                setSelectedRescheduleReason(selected);
                                              }
                                            }}
                                          >
                                            <option disabled selected value="">-- select an option --</option>
                                            {rescheduleReasonEntity?.watotoCareEntityDetails
                                              .filter((entity: IWatotoCareEntityDetail) => entity.isActive)
                                              .sort((a: IWatotoCareEntityDetail, b: IWatotoCareEntityDetail) => a.displayOrder - b.displayOrder)
                                              .map((reason: IWatotoCareEntityDetail, j: number) => (
                                                <option key={`reschedule-reason-${j}`} value={reason.id}>
                                                  {reason.enDescription}
                                                </option>
                                              ))}
                                          </AvField>
                                        </AvGroup>
                                      </Col>

                                      {/* Reschedule Reason Description */}
                                      {selectedRescheduleReason && selectedRescheduleReason.displayOrder === 99 &&
                                        <Col md="6">
                                          <AvGroup>
                                            <AvField
                                              type="text"
                                              id={'journey-rescheduleReasonComment-' + i}
                                              name="rescheduleReasonComment"
                                              label="Comment"
                                              validate={{
                                                required: { value: true, errorMessage: 'This field is required.' }
                                              }}
                                            />
                                          </AvGroup>
                                        </Col>
                                      }
                                    </>
                                  }
                                </>
                              }
                            </Row>
                            {journey.status === JourneyStatus.CANCELED &&
                              <>
                                <h5 className="text-danger text-center mt-3">Journey
                                  Cancelled {journey.lastModifiedAt ? `on ${moment(convertDateTimeFromServer(journey.lastModifiedAt)).format('MMMM Do, YYYY')}` : ''}</h5>
                                {/* <h5 className="text-danger text-center mt-3">Incident Report: { */}
                                {/*  incidentReportEntity.watotoCareEntityDetails*/}
                                {/*    .find((entity: IWatotoCareEntityDetail) => entity.id === journey.incidentReportId)*/}
                                {/* } {journey.incidentReportDescription}</h5> */}
                              </>
                            }
                            {journey.status === JourneyStatus.COMPLETE &&
                              <Row className="p-3">
                                {!journey.patientVisitId ?
                                  <>
                                    <Col md="9">
                                      <h5 className="text-success text-right mt-1">Journey Completed
                                        {journey.completedAt ? `on ${moment(convertDateTimeFromServer(journey.completedAt)).format('MMMM Do, YYYY')}` : ''}</h5>
                                    </Col>
                                    <Col md="3">
                                      <Button tag={Link}
                                              to={`/patient-visit/create?patientBabyId=${patientBabyEntity.id}&journeyId=${journey.id}`}
                                              className="mr-1 float-right" id={'add-conditions-' + i} color="info"
                                              disabled={
                                                disableButtons ||
                                                journeys.filter(j =>
                                                  (j.visitCount === journey.visitCount && j.status !== JourneyStatus.CANCELED)
                                                ).length !==
                                                journeys.filter(j =>
                                                  (j.visitCount === journey.visitCount && j.status === JourneyStatus.COMPLETE)
                                                ).length
                                              }
                                      >
                                        &nbsp;
                                        <span className="d-none d-md-inline">Add Patient Conditions</span>
                                      </Button>
                                    </Col>
                                  </>
                                  : <>
                                    <Col md="9">
                                      <h5 className="text-success text-right mt-1">All Done, Journey Completed
                                        {journey.completedAt ? `on ${moment(convertDateTimeFromServer(journey.completedAt)).format('MMMM Do, YYYY')}` : ''}</h5>
                                    </Col>
                                    <Col md="3">
                                      <Button tag={Link}
                                              to={`/patient-visit/create?patientBabyId=${patientBabyEntity.id}&journeyId=${journey.id}&visitCount=${journey.patientVisit?.visitCount}`}
                                              className="mr-1 float-right" id={'view-conditions-' + i} color="info"
                                              disabled={disableButtons}>
                                        &nbsp;
                                        <span className="d-none d-md-inline">View Patient Conditions</span>
                                      </Button>
                                    </Col>
                                  </>
                                }
                              </Row>
                            }
                            {(!journey.id || (journey.status !== JourneyStatus.CANCELED && journey.status !== JourneyStatus.COMPLETE)) &&
                              <Row className="p-3">
                                {/* Save and Cancel Buttons */}
                                <Col md="6">
                                  {!journey.id ?
                                    <>
                                      <Button className="mr-1" id={'revert-create-' + i} color="danger"
                                              onClick={removeJourney(i)}>
                                        <span className="d-none d-md-inline">Revert</span>
                                      </Button>
                                      {journey.type !== JourneyType.BYPASS &&
                                        <Button className="mr-1" id={'bypass-journey-' + i} color="info"
                                                onClick={(e) => {
                                                  const updatedJourneys = [...journeys];
                                                  updatedJourneys[currentJourneyIndex].type = JourneyType.BYPASS;
                                                  setJourneys(updatedJourneys);
                                                }}
                                        >
                                          <span className="d-none d-md-inline">Skip/Bypass Journey</span>
                                        </Button>
                                      }
                                      {journey.type === JourneyType.BYPASS &&
                                        <Button className="mr-1" id={'bypass-skip-payment-' + i} color="secondary"
                                                onClick={(e) => {
                                                  const updatedJourneys = [...journeys];
                                                  if (journey.physicianFee === 0) {
                                                    updatedJourneys[currentJourneyIndex].physicianFee = watotocareSettings.physicianVisitFee * 100;
                                                  } else {
                                                    updatedJourneys[currentJourneyIndex].physicianFee = 0;
                                                  }
                                                  setJourneys(updatedJourneys);
                                                }}
                                        >
                                          <span
                                            className="d-none d-md-inline">{journey.physicianFee === 0 ? 'Add Physician Fee' : 'Skip Physician Fee'}</span>
                                        </Button>
                                      }
                                    </>
                                    :
                                    <>
                                      <Button className="mr-1" id={'cancel-popup-' + i} color="danger"
                                              disabled={enableReschedule} onClick={showCancelPopup(i)}>
                                        &nbsp;
                                        <span className="d-none d-md-inline">Cancel Journey</span>
                                      </Button>
                                      {journey.status === JourneyStatus.SCHEDULED && !enableReschedule &&
                                        <Button className="mr-1" id={'reschedule-journey-' + i} color="info"
                                                onClick={(e) => setEnableReschedule(true)}>
                                          &nbsp;
                                          <span className="d-none d-md-inline">Reschedule Journey</span>
                                        </Button>
                                      }
                                    </>
                                  }
                                </Col>
                                <Col md="6">
                                  <div className="float-right">
                                    {journey.status === JourneyStatus.SCHEDULED &&
                                      <Button className="mr-1" id={'start-journey-' + i} color="info" type="submit"
                                              disabled={disableButtons || enableReschedule}>
                                        &nbsp;
                                        <span className="d-none d-md-inline">Start Journey</span>
                                      </Button>
                                    }

                                    {journey.status === JourneyStatus.IN_PROGRESS &&
                                      <Button className="mr-1" id={'finish-journey-' + i} color="info"
                                              disabled={disableButtons} onClick={showFinishPopup(i)}>
                                        &nbsp;
                                        <span className="d-none d-md-inline">Finish Journey & Process Payment</span>
                                      </Button>
                                    }

                                    {/* hide save button when journey is in progress */}
                                    {journey.status !== JourneyStatus.IN_PROGRESS &&
                                      <Button className="mr-1" color="primary" id={'save-entity-' + i} type="submit"
                                              disabled={disableButtons || (journey.id && !enableReschedule)}>
                                        <FontAwesomeIcon icon="save" />
                                        &nbsp; Save
                                      </Button>
                                    }
                                  </div>
                                </Col>
                              </Row>
                            }
                          </AvForm>
                        </Collapse>
                      </Card>
                    </Col>
                  </Row>
                ))
              ) : (
                <></>
              )}
            </>
          )}
        </Col>
      </Row>

      <JourneyScheduleModal
        isOpen={driverScheduleModalOpen}
        toggle={toggleDriverScheduleModal}
        countryId={patientEntity.countryId}
        driverId={journeys[currentJourneyIndex]?.driverId}
        scheduledDate={moment(journeys[currentJourneyIndex]?.scheduledSlotStart).format('YYYY-MM-DD')}
      />

      <JourneyScheduleModal
        isOpen={physicianScheduleModalOpen}
        toggle={togglePhysicianScheduleModal}
        countryId={patientEntity.countryId}
        physicianId={journeys[currentJourneyIndex]?.physicianId}
        scheduledDate={moment(journeys[currentJourneyIndex]?.scheduledSlotStart).format('YYYY-MM-DD')}
      />

      {/* Modal for Cancel Journey */}
      <Modal isOpen={modal} toggle={toggleModal}>
        {modalType === ModalType.CANCEL && <ModalHeader toggle={toggleModal}>Cancel Journey</ModalHeader>}
        {modalType === ModalType.FINISH && <ModalHeader toggle={toggleModal}>Finish Journey</ModalHeader>}
        <ModalBody>
          <AvForm onSubmit={saveEntity} beforeSubmitValidation={beforeSubmit}>
            <AvField type="hidden" name="id" value={journeys[currentJourneyIndex]?.id} />
            <AvField type="hidden" name="status" value={JourneyStatus.CANCELED} />

            {/* Incident Report */}
            {incidentReportEntity !== null &&
              <>
                <AvGroup>
                  <AvField
                    label={incidentReportEntity.entityName}
                    type="select"
                    id="journey-incidentReport"
                    name="incidentReportId"
                    validate={modalType === ModalType.CANCEL ? {
                      required: {
                        value: true,
                        errorMessage: 'This field is required.'
                      }
                    } : {}}
                    value={journeys[currentJourneyIndex]?.incidentReportId}
                    onChange={(e) => {
                      if (e.target.value && e.target.value !== '') {
                        const selected = incidentReportEntity.watotoCareEntityDetails.find((entity: IWatotoCareEntityDetail) => entity.id === e.target.value);
                        setSelectedIncidentReport(selected);
                      }
                    }}
                  >
                    <option disabled selected
                            value="">{modalType === ModalType.CANCEL ? '-- select an option --' : '-- select an option if needed--'}</option>
                    {incidentReportEntity?.watotoCareEntityDetails
                      .filter((entity: IWatotoCareEntityDetail) => entity.isActive)
                      .sort((a: IWatotoCareEntityDetail, b: IWatotoCareEntityDetail) => a.displayOrder - b.displayOrder)
                      .map((entity: IWatotoCareEntityDetail) => (
                        <option key={entity.id} value={entity.id}>
                          {entity.enDescription}
                        </option>
                      ))}
                  </AvField>
                </AvGroup>

                {/* Incident Report Description */}
                {selectedIncidentReport && selectedIncidentReport.displayOrder === 99 && (
                  <AvGroup>
                    <AvField
                      type="text"
                      id="journey-incidentReportDescription"
                      name="incidentReportDescription"
                      label="Incident Report Description"
                      validate={{
                        required: { value: true, errorMessage: 'This field is required.' }
                      }}
                    />
                  </AvGroup>
                )}
              </>
            }

            <br />
            {modalType === ModalType.CANCEL &&
              <Button color="primary" id="cancel-journey" type="submit" disabled={disableButtons}>Cancel Journey</Button>
            }
            {modalType === ModalType.FINISH &&
              <Button color="primary" id="finish-journey" type="submit" disabled={disableButtons}>Finish Journey</Button>
            }
          </AvForm>
        </ModalBody>
      </Modal>
    </div>
  )
    ;
};

const mapStateToProps = (storeState: IRootState) => ({
  patientBabyEntity: storeState.patientBaby.entity,
  patientEntity: storeState.patient.entity,
  patientVisits: storeState.patientVisit.entities,
  visitSettings: storeState.patientVisit.settings,
  patientVisitJourneys: storeState.journey.entities,
  loading: storeState.journey.loading,
  updating: storeState.journey.updating,
  fetchSuccess: storeState.journey.fetchSuccess,
  patientVisitFetchSuccess: storeState.patientVisit.fetchSuccess,
  updateSuccess: storeState.journey.updateSuccess,
  errorMessage: storeState.journey.errorMessage,
  watotocareSettings: storeState.watotocareSettings.settingsDetail,
  allHealthcareFacilities: storeState.healthcareFacility.allFacility,
  healthcareFacilitiesLoading: storeState.healthcareFacility.loading,
  villages: storeState.village.entities,
  villagesLoading: storeState.village.loading,
  drivers: storeState.driver.entities,
  driverLoading: storeState.driver.loading,
  physicians: storeState.physician.entities,
  physicianLoading: storeState.physician.loading,
  watotoCareEntityList: storeState.watotoCareEntity.masterEntities
});

const mapDispatchToProps = {
  getVisits,
  getVisitSettings,
  getEntities,
  createEntity,
  updateEntity,
  reset,
  resetVisit,
  resetPatient,
  resetPatientBaby,
  getPatient,
  getPatientBaby,
  getWatotoCareSettingsByCountryID,
  getAllFacility,
  getVillages,
  getDrivers,
  getPhysicians,
  getMasterEntitiesByEnumNames
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientVisitJourney);
