import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Card, CardHeader, Col, Label, Row } from 'reactstrap';
import { hasAnyPermission } from 'app/shared/auth/permissions';
import { IRootState } from 'app/shared/reducers';
import { getEntitiesByMonth } from './journey.reducer';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import VillageAvTypeahead from 'app/shared/layout/menus/village-av-typeahead';
import { getAllFacility } from 'app/entities/healthcare-facility/healthcare-facility.reducer';
import { getAllByType as getCountries } from 'app/entities/region/region.reducer';
import { getVillagesByCountry as getVillages } from 'app/entities/village/village.reducer';
import { getEntities as getDrivers } from 'app/entities/driver/driver.reducer';
import { getEntities as getPhysicians } from 'app/entities/physician/physician.reducer';
import { JourneyType } from 'app/shared/model/enumerations/journey-type.model';
import { JourneyStatus } from 'app/shared/model/enumerations/journey-status.model';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IJourneyProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {
}

export const JourneyCalendar = (props: IJourneyProps) => {
  const {
    journeyList,
    totalItems,
    loading,
    isAdmin,
    userCountryId,
    countries,
    drivers,
    driverLoading,
    physicians,
    physicianLoading
  } = props;
  const [filterList, setFilterList] = useState({
    driverId: '',
    physicianId: ''
  });
  const [activeCountryId, setActiveCountryId] = useState(null);
  const [activeMonth, setActiveMonth] = useState(moment().format('YYYY-MM'));
  const [patientPopoverOpen, setPatientPopoverOpen] = useState(null);
  const [driverPopoverOpen, setDriverPopoverOpen] = useState(null);
  const [physicianPopoverOpen, setPhysicianPopoverOpen] = useState(null);
  const [filteredJourneyList, setFilteredJourneyList] = useState(null);
  const [journeyMonth, setJourneyMonth] = useState(null);

  useEffect(() => {
    props.getCountries();
  }, []);

  useEffect(() => {
    if (countries.length > 0 && userCountryId !== '') {
      const country = countries.find(c => c.id === userCountryId);
      if (country) {
        setActiveCountryId(country.id);
      }
    }
  }, [countries, userCountryId]);

  useEffect(() => {
    if (activeCountryId && activeCountryId !== '') {
      props.getDrivers(undefined, undefined, undefined, activeCountryId);
      props.getPhysicians(activeCountryId);
    }
  }, [activeCountryId]);

  useEffect(() => {
    if (activeCountryId && activeMonth) {
      props.getEntitiesByMonth(activeCountryId, activeMonth);
    }
  }, [activeCountryId, activeMonth]);


  useEffect(() => {
    const stringifiedFilterList = JSON.stringify(filterList);
    // update the filtered journey list based on filters
    if (journeyList && journeyList.length > 0) {
      const filteredJourneys = journeyList.filter(journey => {
        let include = true;
        if (filterList.driverId && filterList.driverId !== '' && journey.driverId !== filterList.driverId) {
          include = false;
        }
        if (filterList.physicianId && filterList.physicianId !== '' && journey.physicianId !== filterList.physicianId) {
          include = false;
        }
        return include;
      });
      setFilteredJourneyList(filteredJourneys);
    }
  }, [JSON.stringify(filterList)]);

  const regCountriesList = props.countries.filter((ele) => {
    return isAdmin ? 1 : ele.id === userCountryId;
  });

  const updateFilters = (filters) => {
    if (filters.activePage === undefined) filters.activePage = 0;
    const filterListObj = { ...filterList, ...filters };
    setFilterList(filterListObj);
  };

  useEffect(() => {
    if (journeyList && journeyList.length > 0) {
      setFilteredJourneyList(journeyList);
    }
  }, [journeyList]);

  useEffect(() => {
    if (filteredJourneyList && filteredJourneyList.length > 0) {
      // create 5 x 7 2D array for the month
      const month = [];
      const firstDay = moment(activeMonth).startOf('month');
      const lastDay = moment(activeMonth).endOf('month');
      const firstDayOfWeek = firstDay.day();
      const daysInMonth = lastDay.date();
      let day = 1;
      for (let i = 0; i < 5; i++) {
        const week = [];
        for (let j = 0; j < 7; j++) {
          if (i === 0 && j < firstDayOfWeek) {
            week.push(null);
          } else if (day <= daysInMonth) {
            week.push({
              date: day,
              totalJourneys: filteredJourneyList.filter(journey => moment(journey.scheduledSlotStart).date() === day).length,
              physicalTransportJourneys: filteredJourneyList.filter(journey => journey.type === JourneyType.PHYSICAL_TRANSPORT && moment(journey.scheduledSlotStart).date() === day).length,
              teleconsultationJourneys: filteredJourneyList.filter(journey => journey.type === JourneyType.TELECONSULTATION && moment(journey.scheduledSlotStart).date() === day).length
            });
            day++;
          } else {
            week.push(null);
          }
        }
        month.push(week);
      }
      setJourneyMonth(month);
    }
  }, [filteredJourneyList]);

  const getHfName = (id, hfList) => {
    const hf = hfList.find(v => v.id === id);
    return !hf ?
      { short: '', full: '', council: '', district: '' }
      :
      {
        short: hf.name,
        council: hf?.council?.name,
        district: hf?.council?.parent?.name,
        full: (hf.name + ' / ' + hf?.council?.name + ' / ' + hf?.council?.parent?.name)
      };
  };

  // AvTypeahead Data
  const driverOptionList = drivers ?
    drivers.map(driver => ({
      short: `${driver.firstName} ${driver.lastName}`,
      full: `${driver.firstName} ${driver.lastName}`,
      id: driver.id
    })) : [];


  const physicianOptionList = physicians ?
    physicians.map(physician => ({
      short: `${physician.firstName} ${physician.lastName}`,
      full: `${physician.firstName} ${physician.lastName}`,
      id: physician.id
    })) : [];

  const getJourneyType = (type) => {
    if (type) {
      if (type === JourneyType.TELECONSULTATION) {
        return 'Teleconsultation';
      } else if (type === JourneyType.PHYSICAL_TRANSPORT) {
        return 'Physical Transport';
      } else if (type === JourneyType.BYPASS) {
        return 'Journey Skipped';
      }
    }
    return '-';
  };

  const getJourneyStatus = (status, scheduledSlotStart) => {
    if (status) {
      if (status === JourneyStatus.COMPLETE) {
        return { journeyStatus: 'Needs to update conditions', journeyStatusColor: 'yellowgreen' };
      } else if (status === JourneyStatus.IN_PROGRESS) {
        return { journeyStatus: 'In Progress', journeyStatusColor: 'blue' };
      } else if (status === JourneyStatus.CANCELED) {
        return { journeyStatus: 'Journey Canceled', journeyStatusColor: 'red' };
      } else if (status === JourneyStatus.SCHEDULED) {
        // check if scheduledSlotStart is for today or some other day
        const scheduledSlotStartMoment = moment(scheduledSlotStart);
        const today = moment();
        if (scheduledSlotStartMoment.isSame(today, 'day')) {
          // convert scheduled slot to readable format with am pm
          const start = moment(scheduledSlotStart).format('hh:mm A');
          return { journeyStatus: `Scheduled for today@${start}`, journeyStatusColor: 'orange' };
        } else {
          // Format start time to format like July 15th, 2021
          const start = moment(scheduledSlotStart).format('MMMM Do, YYYY');
          return { journeyStatus: `Scheduled for ${start}`, journeyStatusColor: 'orange' };
        }
      } else {
        return { journeyStatus: 'Needs an appointment', journeyStatusColor: 'red' };
      }
    }
    return { journeyStatus: 'Needs an appointment', journeyStatusColor: 'red' };
  };

  const getJourneyScheduledDate = (scheduledSlotStart) => {
    if (scheduledSlotStart) {
      return moment(scheduledSlotStart).format('MMMM Do, YYYY hh:mm A');
    }
    return '-';
  };

  return (
    <div>
      <h2 id="journey-heading">
        Journey Calendar View
      </h2>
      <br />
      <AvForm>
        {regCountriesList &&
          <AvGroup>
            <Label for="country-filter">Country</Label>
            <AvInput
              type="select"
              name="country-filter"
              id="country-filter"
              onChange={(event) => {
                updateFilters({ countryId: event.target.value });
              }}
              value={activeCountryId}
            >
              {regCountriesList.map((country, i) => (
                <option value={country.id} key={`country-${i}`}>
                  {country.name}
                </option>
              ))}
            </AvInput>
          </AvGroup>
        }

        {/* TypeAhead for Driver */}
        <AvGroup>
          <Label for="driver-filter">Driver</Label>
          <VillageAvTypeahead
            labelKey="name"
            valueKey="id"
            data={driverOptionList}
            id="journey-driverId"
            loading={driverLoading}
            onChange={(id) => {
              updateFilters({ driverId: id });
            }}
            input={{
              id: 'journey-driver',
              name: 'driverId'
            }}
          />
        </AvGroup>

        {/* TypeAhead for Physician */}
        <AvGroup>
          <Label for="physician-filter">Physician</Label>
          <VillageAvTypeahead
            labelKey="name"
            valueKey="id"
            data={physicianOptionList}
            id="journey-physicianId"
            loading={physicianLoading}
            onChange={(id) => {
              updateFilters({ physicianId: id });
            }}
            input={{
              id: 'journey-physician',
              name: 'physicianId'
            }}
          />
        </AvGroup>

        <br />
        {/* Date Filter */}
        <Row>
          {/* Previous Month */}
          <Col md="4" className="text-left align-content-center">
            <FontAwesomeIcon icon={'arrow-left'} color={loading ? 'gray' : 'black'} className="hand" onClick={() => {
              if (!loading) {
                setActiveMonth(moment(activeMonth).subtract(1, 'month').format('YYYY-MM'));
              }
            }}
            />
          </Col>

          <Col md="4" className="text-center">
            <AvGroup>
              <Label for="journey-month">Select a Month</Label>
              <DatePicker
                selected={activeMonth ? moment(activeMonth, 'YYYY-MM').toDate() : null}
                popperPlacement="bottom"
                id="journey-month"
                showMonthYearPicker
                name="month"
                value={activeMonth}
                onChange={(date: any) => {
                  if (date) setActiveMonth(moment(date).format('YYYY-MM'));
                }}
              />
            </AvGroup>
          </Col>

          {/* Next Month */}
          <Col md="4" className="text-right align-content-center">
            <FontAwesomeIcon icon={'arrow-right'} color={loading ? 'gray' : 'black'} className="hand" onClick={() => {
              if (!loading) {
                setActiveMonth(moment(activeMonth).add(1, 'month').format('YYYY-MM'));
              }
            }}
            />
          </Col>
        </Row>

      </AvForm>
      {journeyList && journeyList.length > 0 ? (
        <Row className="pb-5 mr-0 ml-0">
          <Col md="3"></Col>
          <Col md="3" className="d-inline-flex justify-content-center">
            <div style={{ borderRadius: '100%', background: '#009688', height: '25px', width: '25px' }}></div>
            &nbsp;Physical Transport
          </Col>
          <Col md="3" className="d-inline-flex justify-content-center">
            <div style={{ borderRadius: '100%', background: '#1a73e8', height: '25px', width: '25px' }}></div>
            &nbsp;Teleconsultation
          </Col>
          <Col md="3"></Col>
          <Col md="12" className="mt-3 pr-0 pl-0">
            <Card className="p-0">
              <CardHeader>
                <Row>
                  <Col className="text-center">Sun</Col>
                  <Col className="text-center">Mon</Col>
                  <Col className="text-center">Tue</Col>
                  <Col className="text-center">Wed</Col>
                  <Col className="text-center">Thu</Col>
                  <Col className="text-center">Fri</Col>
                  <Col className="text-center">Sat</Col>
                </Row>
              </CardHeader>
              <div>
                {journeyMonth && journeyMonth.length > 0 && journeyMonth.map((week, i) => (
                  <>
                    <Row key={`week-${i}`}>
                      {week.map((day, j) => (
                        <Col key={`day-${j}`} className="p-0">
                          {day && (
                            <div
                              className="hand"
                              onClick={() => {
                                props.history.push(`/journey?scheduledDate=${moment(activeMonth).date(day.date).format('YYYY-MM-DD')}`);
                              }}
                              style={{ border: '1px solid #f0f0f0', height: '100px', padding: '20px' }}
                            >
                              <h4 className="text-left" style={{ color: 'darkslategray' }}>{day.date}</h4>
                              <div className="float-right">
                                {/* red circle with physical transport count */}
                                <div
                                  className="d-inline-flex align-items-center justify-content-center rounded-circle m-1"
                                  style={{
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#009688',
                                    fontSize: '14px',
                                    color: 'white'
                                  }}
                                >
                                  {day.physicalTransportJourneys}
                                </div>
                                {/* blue circle with teleconsultation count */}
                                <div
                                  className="d-inline-flex align-items-center justify-content-center rounded-circle"
                                  style={{
                                    width: '25px',
                                    height: '25px',
                                    backgroundColor: '#1a73e8',
                                    fontSize: '14px',
                                    color: 'white'
                                  }}
                                >
                                  {day.teleconsultationJourneys}
                                </div>
                              </div>
                            </div>
                          )}
                        </Col>
                      ))}
                    </Row>
                  </>
                ))
                }
              </div>
            </Card>
          </Col>
        </Row>
      ) : (
        !loading && <div className="alert alert-warning">No Journeys found</div>
      )}
    </div>
  );
};

const mapStateToProps = ({ journey, authentication, region, driver, physician }: IRootState) => ({
  journeyList: journey.calendarEntities,
  loading: journey.loading,
  totalItems: journey.totalItems,
  userCountryId: authentication.userCountryId,
  countries: region.countries,
  isAdmin: hasAnyPermission(authentication.account.roles, ['ADMIN']),
  drivers: driver.entities,
  driverLoading: driver.loading,
  physicians: physician.entities,
  physicianLoading: physician.loading
});

const mapDispatchToProps = {
  getEntitiesByMonth,
  getAllFacility,
  getVillages,
  getDrivers,
  getPhysicians,
  getCountries
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(JourneyCalendar);
