import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Col, Label, Row } from 'reactstrap';
import { AvGroup, AvInput } from 'availity-reactstrap-validation';
import { WatotoCareEntityType } from 'app/shared/model/enumerations/watotocare-entity-type.model';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';
import { IWatotoCareEntityMaster } from 'app/shared/model/watotocare-entity-master.model';
import { IWatotoCareEntityDto } from 'app/shared/model/watotocare-entity-dto.model';

interface WatotocareEntityFormGroupProps {
  prefix: string;
  entities: IWatotoCareEntityMaster[];
  initialValues: IWatotoCareEntityDto[];
  onChange: (data: IWatotoCareEntityDto[]) => void;
  disabled?: boolean;
}

const WatotocareEntityFormGroup = (props: WatotocareEntityFormGroupProps) => {
  const { entities, initialValues, onChange, disabled, prefix } = props;

  const [multiSelectEntityIds, setMultiSelectEntityIds] = useState<{ [key: string]: boolean }>({});
  const [dropDownEntityIds, setDropDownEntityIds] = useState<{ [key: string]: string }>({});
  const [dropDownEntityComments, setDropDownEntityComments] = useState<{ [key: string]: string }>({});
  const [textEntityComments, setTextEntityComments] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (!initialValues || !initialValues.length || !entities || !entities.length) {
      return;
    }
    const initialMultiSelectEntityIds: { [key: string]: boolean } = {};
    const initialDropDownEntityIds: { [key: string]: string } = {};
    const initialDropDownEntityComments: { [key: string]: string } = {};
    const initialTextEntityComments: { [key: string]: string } = {};

    initialValues.forEach(value => {
      if (value.entityDetail) {
        const detail = value.entityDetail;
        if (detail.entityMaster.type === WatotoCareEntityType.MULTISELECT) {
          initialMultiSelectEntityIds[detail.id] = true;
        } else if (detail.entityMaster.type === WatotoCareEntityType.DROPDOWN) {
          initialDropDownEntityIds[detail.entityMaster.id] = detail.id;
          initialDropDownEntityComments[detail.id] = value.comment ?? '';
        } else if (detail.entityMaster.type === WatotoCareEntityType.TEXT) {
          initialTextEntityComments[detail.id] = value.comment ?? '';
        }
      }
    });

    entities.filter(entity => entity.type === WatotoCareEntityType.MULTISELECT).forEach(masterEntity => {
      masterEntity.watotoCareEntityDetails.forEach(val => {
        if (!initialMultiSelectEntityIds[val.id]) {
          initialMultiSelectEntityIds[val.id] = false;
        }
      });
    });

    setMultiSelectEntityIds(initialMultiSelectEntityIds);
    setDropDownEntityIds(initialDropDownEntityIds);
    setDropDownEntityComments(initialDropDownEntityComments);
    setTextEntityComments(initialTextEntityComments);
  }, [entities, initialValues]);

  useEffect(() => {
    let postData = [];

    // set values for multi select entities
    postData = Object.keys(multiSelectEntityIds)
      .filter(key => multiSelectEntityIds[key] === true)
      .map(entityDetailId => ({
        entityDetailId,
        comment: null
      }));

    // set values for dropdown entities
    Object.keys(dropDownEntityIds).forEach(key => {
      postData.push({
        entityDetailId: dropDownEntityIds[key],
        comment: dropDownEntityComments[dropDownEntityIds[key]] || null
      });
    });

    // set values for text entities
    Object.keys(textEntityComments).forEach(entityDetailId => {
      postData.push({
        entityDetailId,
        comment: textEntityComments[entityDetailId]
      });
    });

    onChange(postData);
  }, [multiSelectEntityIds, dropDownEntityIds, dropDownEntityComments, textEntityComments]);

  const handleMultiSelectChange = (id: string, value: boolean) => {
    setMultiSelectEntityIds({
      ...multiSelectEntityIds,
      [id]: value
    });
  };

  const handleDropdownChange = (id: string, value: string) => {
    setDropDownEntityIds({
      ...dropDownEntityIds,
      [id]: value
    });
  };

  const handleDropdownCommentChange = (id: string, value: string) => {
    setDropDownEntityComments({
      ...dropDownEntityComments,
      [id]: value
    });
  };

  const handleTextChange = (id: string, value: string) => {
    setTextEntityComments({
      ...textEntityComments,
      [id]: value
    });
  };

  return (entities && entities.length > 0 ?
      <>
        <Row className="mt-3"></Row>
        <Row className="justify-content-left">
          {entities
            .filter(entity => entity.type === WatotoCareEntityType.MULTISELECT)
            .map((entity: IWatotoCareEntityMaster) => (
              entity.watotoCareEntityDetails
                .filter((detail: IWatotoCareEntityDetail) => detail.isActive)
                .sort((a: IWatotoCareEntityDetail, b: IWatotoCareEntityDetail) => a.displayOrder - b.displayOrder)
                .map((detail: IWatotoCareEntityDetail) => (
                  <Col md="12" key={detail.id} className="mt-3">
                    <AvGroup>
                      <Label>{detail.enDescription}</Label>
                      <ButtonGroup style={{ marginLeft: '30px' }}>
                        <Button
                          color={multiSelectEntityIds[detail.id] === true ? 'primary' : 'secondary'}
                          onClick={() => handleMultiSelectChange(detail.id, true)}
                          disabled={disabled}
                        >
                          Yes
                        </Button>
                        <Button
                          color={multiSelectEntityIds[detail.id] === false ? 'primary' : 'secondary'}
                          onClick={() => handleMultiSelectChange(detail.id, false)}
                          disabled={disabled}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </AvGroup>
                  </Col>
                ))
            ))
          }
        </Row>

        <Row className="justify-content-left">
          {entities
            .filter(entity => entity.type === WatotoCareEntityType.DROPDOWN)
            .map(entity => (
              <>
                <Col md="6" className="mt-3" key={entity.id}>
                  <AvGroup>
                    <Label id={entity.id} for={`${prefix}-select-${entity.id}`}>
                      {entity.entityName}
                    </Label>
                    <AvInput
                      id={`${prefix}-select-${entity.id}`}
                      type="select"
                      disabled={disabled}
                      className="form-control"
                      name={`${prefix}-select-${entity.id}`}
                      value={dropDownEntityIds[entity.id] || ''}
                      onChange={(e) => handleDropdownChange(entity.id, e.target.value)}
                    >
                      <option disabled selected value="">-- select an option --</option>
                      {entity.watotoCareEntityDetails
                        .filter((detail: IWatotoCareEntityDetail) => detail.isActive)
                        .sort((a: IWatotoCareEntityDetail, b: IWatotoCareEntityDetail) => a.displayOrder - b.displayOrder)
                        .map((detail: IWatotoCareEntityDetail) => (
                          <option value={detail.id} key={detail.id}>
                            {detail.enDescription}
                          </option>
                        ))}
                    </AvInput>
                  </AvGroup>
                </Col>
                {entity.watotoCareEntityDetails.find((detail: IWatotoCareEntityDetail) => detail.id === dropDownEntityIds[entity.id])?.displayOrder === 99 && (
                  <Col md="6" className="mt-3">
                    <AvGroup>
                      <Label id={`${prefix}-other-label`}
                             for={`${prefix}-other-comment-${dropDownEntityIds[entity.id]}`}>
                        If Other, Please Specify
                      </Label>
                      <AvInput
                        id={`${prefix}-other-comment-${dropDownEntityIds[entity.id]}`}
                        type="text"
                        name={`${prefix}-other-comment-${dropDownEntityIds[entity.id]}`}
                        value={dropDownEntityComments[dropDownEntityIds[entity.id]] || ''}
                        disabled={disabled}
                        onChange={(e) => handleDropdownCommentChange(dropDownEntityIds[entity.id], e.target.value)}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>
                )}
              </>
            ))
          }
        </Row>

        <Row className="justify-content-left">
          {entities
            .filter(entity => entity.type === WatotoCareEntityType.TEXT)
            .map(entity => (
              entity.watotoCareEntityDetails
                .filter((detail: IWatotoCareEntityDetail) => detail.isActive)
                .sort((a: IWatotoCareEntityDetail, b: IWatotoCareEntityDetail) => a.displayOrder - b.displayOrder)
                .map((detail: IWatotoCareEntityDetail) => (
                  <Col md="6" key={detail.id} className="mt-3">
                    <AvGroup>
                      <Label id={detail.id} for={`${prefix}-comment-${detail.id}`}>
                        {detail.enDescription}
                      </Label>
                      <AvInput
                        id={`${prefix}-comment-${detail.id}`}
                        type="text"
                        className="form-control"
                        name={`${prefix}-comment-${detail.id}`}
                        value={textEntityComments[detail.id] || ''}
                        disabled={disabled}
                        onChange={(e) => handleTextChange(detail.id, e.target.value)}
                      />
                    </AvGroup>
                  </Col>
                ))
            ))
          }
        </Row>
      </>
      :
      <></>
  );
};

export default WatotocareEntityFormGroup;
