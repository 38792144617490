import './route-search-bar.scss';

import axios from 'axios';
import { ILocation, ILocationSearchResponse } from '../../shared/model/location.model';

import React, { useEffect, useState } from 'react';

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Button, Col, InputGroupAddon, Row } from 'reactstrap';

export interface IRouteSearchBarProps {
  test: string,
  activeCountry?: string,
  onQuery: (fromLocation: ILocation, toLocation: ILocation) => void
}

const RouteSearchBar = (props: IRouteSearchBarProps) => {
  const { activeCountry } = props;
  const [isFromLoading, setIsFromLoading] = useState(false);
  const [isToLoading, setIsToLoading] = useState(false);
  const [fromLocations, setFromLocations] = useState([]);
  const [toLocations, setToLocations] = useState([]);
  const [selectedFromLocation, setSelectedFromLocation] = useState(undefined);
  const [selectedToLocation, setSelectedToLocation] = useState(undefined);
  useEffect(() => {
    setToLocations([]);
    setFromLocations([]);
  }, []);

  const locationNames = (includeEmpty = false, data: any) => {
    const locationItems = data.map(item => ({
      short: item.name,
      council: item?.council?.name,
      district: item?.council?.parent?.name,
      full: (item.name + ' / ' + item?.council?.name + ' / ' + item?.council?.parent?.name),
      id: item.id,
      name: item.name,
      locationType: item.locationType
    }));
    let result = (includeEmpty) ? ([{ short: '', full: '', id: undefined, name: '', locationType: '' }]) : [];
    result = result.concat(locationItems);
    // console.log("RESULTSSS" ,result)
    return result;
  };

  const handleFromSearch = (query: string) => {
    const activeCountryQ = activeCountry ? activeCountry : 0;
    axios.get<ILocationSearchResponse>(`api/search/locations/${activeCountryQ}/${query}`)
      .then((response) => {
          console.log(response);
          setFromLocations(locationNames(false, response.data.locationResults));
          // setFromLocations(response.data.locationResults)
        },
        (error) => {
          console.log(error);
        });
  };

  const handleToSearch = (query: string) => {

    const activeCountryQ = activeCountry ? activeCountry : 0;
    // axios.get<ILocationSearchResponse>(`api/search/locations/${activeCountryQ}/${query}?excludeVillages=true`)
    axios.get<ILocationSearchResponse>(`api/search/locations/${activeCountryQ}/${query}`)
      .then((response) => {
          console.log(response);
          setToLocations(locationNames(false, response.data.locationResults));
          // setToLocations(response.data.locationResults)
        },
        (error) => {
          console.log(error);
        });
  };

  const handleOnQuery = () => {
    props.onQuery(selectedFromLocation, selectedToLocation);
  };

  const isQueryEnabled = selectedToLocation || selectedFromLocation;
  console.log('activeCountry', activeCountry);
  return (
    <div className="route-search-container">
      {/* <InputGroup> */}
      <Row>
        <Col md="4" style={{ paddingRight: 0 }}>
          <AsyncTypeahead
            filterBy={() => true}
            id="from-async"
            labelKey="full"
            minLength={1}
            placeholder="From:"
            onSearch={handleFromSearch}
            options={fromLocations}
            onChange={(selected: [ILocation]) => {
              const fromLocation = selected.length > 0 ? selected[0] : undefined;
              setSelectedFromLocation(fromLocation);
            }}
          />
        </Col>
        <Col md="4" style={{ padding: 0 }}>
          <AsyncTypeahead
            filterBy={() => true}
            id="to-async"
            labelKey="full"
            minLength={1}
            placeholder="To:"
            onSearch={handleToSearch}
            options={toLocations}
            onChange={(selected: [ILocation]) => {
              const to = selected.length > 0 ? selected[0] : undefined;
              setSelectedToLocation(to);
            }}
          />
        </Col>
        <Col md="4" style={{ paddingLeft: 0 }}>
          <InputGroupAddon addonType="append">
            <Button
              disabled={!isQueryEnabled}
              color="primary"
              onClick={handleOnQuery}
            >
              Query
            </Button>
          </InputGroupAddon>
        </Col>
      </Row>
      {/* </InputGroup> */}
    </div>
  );
};

export default RouteSearchBar;
