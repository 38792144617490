import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Map from 'app/shared/model/Map';
import { IRootState } from 'app/shared/reducers';
import { getEntity, updateEntity } from './route.reducer';
import {
  getWatotoCareSettingsByCountryID
} from 'app/modules/administration/watotocare-settings/watotocare-settings.reducer';
import { displaySecondsAsHoursMinutes } from 'app/shared/util/date-utils';
import { IRoute } from 'app/shared/model/route.model';
import { RouteForm } from './route-update-modal';
import mapboxgl from 'mapbox-gl';
import { MAPBOX_ACCESS_TOKEN } from 'app/config/constants';

export interface IRouteDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
}

export const RouteDetail = (props: IRouteDetailProps) => {

  const { routeEntity, watotocareSettings } = props;
  const [updatingRoute, setUpdatingRoute] = useState(false);
  const [mapboxRouteObject, setMapboxRouteObject] = useState({});
  const [showRoute, setShowRoute] = useState(false);
  const [pathLocation, setPathLocation] = useState({
    startLocation: { latitude: 0, longitude: 0 },
    endLocation: { latitude: 0, longitude: 0 }
  });
  mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

  function calculateRoutePrice(distance: number): number {
    return distance * watotocareSettings.costKm;
  }

  const handleSaveRoute = (route: IRoute) => {
    props.updateEntity(route);
    setUpdatingRoute(false);
  };

  const handleUpdateModalClose = () => {
    setUpdatingRoute(false);
  };

  const handleUpdateRoute = () => {
    setUpdatingRoute(true);
  };

  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  useEffect(() => {
    const locationData = { ...pathLocation };
    locationData.startLocation.latitude = routeEntity.startType === 'VILLAGE' ? routeEntity.startVillage?.locationLat : routeEntity.startFacility?.locationLat;
    locationData.startLocation.longitude = routeEntity.startType === 'VILLAGE' ? routeEntity.startVillage?.locationLon : routeEntity.startFacility?.locationLon;
    locationData.endLocation.latitude = routeEntity.endType === 'VILLAGE' ? routeEntity.endVillage?.locationLat : routeEntity.endFacility?.locationLat;
    locationData.endLocation.longitude = routeEntity.endType === 'VILLAGE' ? routeEntity.endVillage?.locationLon : routeEntity.endFacility?.locationLon;
    setPathLocation(locationData);

    if (routeEntity.startVillage?.council?.parent?.parent?.parent.id) {
      props.getWatotoCareSettingsByCountryID(routeEntity.startVillage?.council?.parent?.parent?.parent.id);
    } else if (routeEntity.startFacility?.council?.parent?.parent?.parent.id) {
      props.getWatotoCareSettingsByCountryID(routeEntity.startFacility?.council?.parent?.parent?.parent.id);
    }
  }, [routeEntity]);

  const getRouteDistance = async () => {
    const query = await fetch(
      `https://api.mapbox.com/directions/v5/mapbox/driving/${pathLocation.startLocation.longitude},${pathLocation.startLocation.latitude};${pathLocation.endLocation.longitude},${pathLocation.endLocation.latitude}?alternatives=true&geometries=geojson&language=en&overview=full&steps=true&access_token=${mapboxgl.accessToken}`,
      { method: 'GET' }
    );

    const json = await query.json();
    setMapboxRouteObject(json.routes[0]);
  };

  useEffect(() => {
    if (showRoute) {
      if (pathLocation.startLocation.latitude && pathLocation.endLocation.latitude &&
        pathLocation.startLocation.latitude !== 0 && pathLocation.endLocation.latitude !== 0) {
        getRouteDistance();
      }
    }
  }, [showRoute]);

  return (
    <div>
      {updatingRoute && (
        <RouteForm opened={true} routeEntity={routeEntity} watotocareSettings={watotocareSettings}
                   onSave={handleSaveRoute} toggle={handleUpdateModalClose} />
      )}
      <Row>
        <Col md="4">
          <h2>
            Route
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="distance">Distance</span>
            </dt>
            <dd>{routeEntity.distance}</dd>
            <dt>
              <span id="durationSeconds">Duration (Minutes)</span>
            </dt>
            <dd>{displaySecondsAsHoursMinutes(routeEntity.durationSeconds)}</dd>
            <dt>
              <span id="computedPrice">Computed Price</span>
            </dt>
            <dd>
              {calculateRoutePrice(routeEntity.distance)}
            </dd>
            <dt>
              <span id="overridePrice">Override Price</span>
            </dt>
            <dd>{routeEntity.overridePrice}</dd>
            {/* <dt>
              <span id="description">Description</span>
            </dt>
            <dd>{routeEntity.description}</dd> */}
            <dt>
              <span id="isActive">Active</span>
            </dt>
            <dd>{routeEntity.isActive ? 'true' : 'false'}</dd>
            <dt>
              <span id="startType">Start Type</span>
            </dt>
            <dd>{routeEntity.startType}</dd>
            <dt>
              <span id="endType">End Type</span>
            </dt>
            <dd>{routeEntity.endType}</dd>
            <dt>{routeEntity.startVillage ? 'Start Village' : ''}</dt>
            <dd>{routeEntity.startVillage ? routeEntity.startVillage.name : ''}</dd>
            <dt>{routeEntity.endVillage ? 'End Village' : ''}</dt>
            <dd>{routeEntity.endVillage ? routeEntity.endVillage.name : ''}</dd>
            <dt>{routeEntity.startFacility ? 'Start Facility' : ''}</dt>
            <dd>{routeEntity.startFacility ? routeEntity.startFacility.name : ''}</dd>
            <dt>{routeEntity.endFacility ? 'End Facility' : ''}</dt>
            <dd>{routeEntity.endFacility ? routeEntity.endFacility.name : ''}</dd>
          </dl>

          <Button tag={Link} to="/route" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              handleUpdateRoute();
            }}
            color="primary"
          >
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
          &nbsp;
          <Button
            onClick={() => setShowRoute(true)}
          >
            <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Show Route</span>
          </Button>
        </Col>
        <Col md="8">
          <Map pathLocation={pathLocation} mapboxRouteObject={mapboxRouteObject} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ route, watotocareSettings }: IRootState) => ({
  routeEntity: route.entity,
  watotocareSettings: watotocareSettings.settingsDetail
});

const mapDispatchToProps = {
  getEntity,
  getWatotoCareSettingsByCountryID,
  updateEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RouteDetail);
