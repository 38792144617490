import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, ButtonGroup, Col, Label, Row } from 'reactstrap';
import { AvCheckbox, AvCheckboxGroup, AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntity as getPatientBaby } from '../patient-baby/patient-baby.reducer';
import { createEntity, getEntities, reset } from '../patient-visit/patient-visit.reducer';
import { getEntity as getPatient } from 'app/entities/patient/patient.reducer';
import { getEntities as getSymptoms } from 'app/entities/symptom/symptom.reducer';
import { EmergencyType } from 'app/shared/model/enumerations/emergency-type.model';
import { RiskProfile } from 'app/shared/model/enumerations/risk-profile.model';
import './patient-visit-update.scss';
import {
  getWatotoCareSettingsByCountryID
} from 'app/modules/administration/watotocare-settings/watotocare-settings.reducer';
import {
  getMasterEntitiesByEnumNames,
  getMasterEntitiesByGroupNames
} from 'app/entities/watotocare-entity/watotocare-entity.reducer';
import { IWatotoCareEntityMaster } from 'app/shared/model/watotocare-entity-master.model';
import { IPatientVisit } from 'app/shared/model/patient-visit.model';
import { daysSince } from 'app/shared/util/date-utils';
import WatotocareEntityFormGroup from 'app/shared/components/watotocare-entity-form/watotocare-entity-form-group';
import { IWatotoCareEntityDto } from 'app/shared/model/watotocare-entity-dto.model';

export interface IPatientVisitUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{
  patientBabyId: string;
}> {
}

// eslint-disable-next-line complexity
export const PatientVisitUpdate = (props: IPatientVisitUpdateProps) => {
  const {
    patientBabyEntity,
    patientVisitEntities,
    patientEntity,
    symptoms,
    watotocareSettings,
    watotoCareEntitiesByGroup,
    watotoCareEntityList,
    loading,
    updating
  } = props;
  const [isNew, setIsNew] = useState(true);
  const [visitNumber, setVisitNumber] = useState(null);
  const [currentVisit, setCurrentVisit] = useState<IPatientVisit>(null);
  const [maternalSymptoms, setMaternalSymptoms] = useState([]);
  const [neonatalSymptoms, setNeonatalSymptoms] = useState([]);
  const [checkboxMaternalSymptoms, setCheckboxMaternalSymptoms] = useState([]);
  const [checkboxNeonatalSymptoms, setCheckboxNeonatalSymptoms] = useState([]);
  const [previousRiskProfile, setPreviousRiskProfile] = useState(null);
  const [overallRiskProfile, setOverallRiskProfile] = useState('');
  const [selectedData, setSelectedData] = useState<any>({
    babySymptomIds: [],
    motherSymptomIds: []
  });
  const [previousVisitData, setPreviousVisitData] = useState<any>({
    babySymptomIds: [],
    motherSymptomIds: []
  });
  const [followupCompleted, setFollowupCompleted] = useState<boolean>(null);
  const [incompleteReasonEntity, setIncompleteReasonEntity] = useState<IWatotoCareEntityMaster>({});
  const [selectedIncompleteReason, setSelectedIncompleteReason] = useState(null);
  const [patientVisitMasterEntities, setPatientVisitMasterEntities] = useState<IWatotoCareEntityMaster[]>([]);
  const [entityFormInitialValues, setEntityFormInitialValues] = useState<IWatotoCareEntityDto[]>([]);
  const [entityFormSelectedValues, setEntityFormSelectedValues] = useState<IWatotoCareEntityDto[]>([]);


  const WATOTOCARE_ENTITY_GROUP_NAME = 'PATIENT_VISIT';
  const WATOTOCARE_ENTITY_ENUM_NAMES = ['FOLLOWUP_INCOMPLETE_REASON'];
  const queryParams = new URLSearchParams(props.location.search);

  const getPatientBabyId = () => {
    if (!queryParams.get('patientBabyId')) {
      alert('Invalid Baby Id');
      props.history.push('/patient');
      return null;
    }
    return queryParams.get('patientBabyId') || null;
  };

  const getVisitCountParam = () => {
    const visitCountParam = queryParams.get('visitCount');
    if (visitCountParam && isNaN(parseInt(visitCountParam, 10))) {
      alert('Invalid Visit Number');
      props.history.push('/patient');
      return null;
    }
    return parseInt(visitCountParam, 10) || null;
  };

  const getJourneyId = () => {
    return queryParams.get('journeyId') || null;
  };

  const handleClose = () => {
    props.history.push('/patient');
  };

  useEffect(() => {
    const patientBabyId = getPatientBabyId();
    props.getSymptoms();
    props.getPatientBaby(patientBabyId);
  }, []);

  useEffect(() => {
    if (patientBabyEntity && patientBabyEntity.patientCase) {
      props.getEntities(patientBabyEntity.patientCase.id);
    }
  }, [patientBabyEntity]);

  useEffect(() => {
    if (watotoCareEntityList.length > 0) {
      // set watotoCareEntity for each entity in watotoCareEntityList
      const incompleteReasonEntityMaster = watotoCareEntityList.find(entity => entity.enumName === 'FOLLOWUP_INCOMPLETE_REASON');
      if (incompleteReasonEntityMaster) {
        setIncompleteReasonEntity(incompleteReasonEntityMaster);
      }
    }
  }, [watotoCareEntityList]);

  const calculateOverallRiskProfile = (selectedSymptomIds: string[]) => {
    if (selectedSymptomIds.length > 0) {
      let finalRiskProfile = 'NONE';
      const riskProfileWeight = {
        'NONE': 0,
        [RiskProfile.LOW]: 1,
        [RiskProfile.MED]: 2,
        [RiskProfile.HIGH]: 3
      };
      for (let i = 0; i < selectedSymptomIds.length; i++) {
        const symptom = symptoms.find(s => s.id === selectedSymptomIds[i]);
        if (riskProfileWeight[finalRiskProfile] < riskProfileWeight[symptom.riskProfile]) {
          finalRiskProfile = symptom.riskProfile;
        }
      }
      return finalRiskProfile;
    }
  };

  const updateFinalRiskProfile = () => {
    if (symptoms.length > 0 && (selectedData?.babySymptomIds?.length > 0 || selectedData?.motherSymptomIds?.length > 0)) {
      const allSelectedSymptoms = [...selectedData.babySymptomIds, ...selectedData.motherSymptomIds];
      setOverallRiskProfile(calculateOverallRiskProfile(allSelectedSymptoms));
    } else if (selectedData?.babySymptomIds?.length === 0 && selectedData?.motherSymptomIds?.length === 0) {
      setOverallRiskProfile('');
    }
  };

  useEffect(() => {
    updateFinalRiskProfile();
  }, [selectedData, symptoms]);

  useEffect(() => {
    if (patientBabyEntity.patientId) {
      props.getPatient(patientBabyEntity.patientId);
    }
  }, [patientBabyEntity]);

  useEffect(() => {
    if (patientEntity.countryId) {
      props.getWatotoCareSettingsByCountryID(patientEntity.countryId);
      props.getMasterEntitiesByGroupNames([WATOTOCARE_ENTITY_GROUP_NAME], patientEntity.countryId);
      props.getMasterEntitiesByEnumNames(WATOTOCARE_ENTITY_ENUM_NAMES, patientEntity.countryId);
    }
  }, [patientEntity]);

  useEffect(() => {
    if (Object.keys(watotoCareEntitiesByGroup).length > 0) {
      setPatientVisitMasterEntities(watotoCareEntitiesByGroup[WATOTOCARE_ENTITY_GROUP_NAME]);
    }
  }, [watotoCareEntitiesByGroup]);

  useEffect(() => {
    if (watotocareSettings.patientVisitLimit && visitNumber !== null) {
      if (visitNumber > watotocareSettings.patientVisitLimit) {
        alert('Maximum number of visits reached');
        handleClose();
        return;
      }
    }
  }, [watotocareSettings, visitNumber]);

  const setEntityValues = (visitData: IPatientVisit) => {
    if (visitData && Object.keys(visitData).length > 0 && patientVisitMasterEntities && patientVisitMasterEntities.length > 0) {
      setEntityFormInitialValues(visitData.patientVisitEntities);
    }
  };

  useEffect(() => {
    // set entity values for previous patient visit if it exists
    if (!isNew && currentVisit && Object.keys(currentVisit).length > 0
      && patientVisitMasterEntities && patientVisitMasterEntities.length > 0
      && incompleteReasonEntity && incompleteReasonEntity.watotoCareEntityDetails?.length > 0
    ) {
      setEntityValues(currentVisit);
      setFollowupCompleted(!currentVisit.incompleteReason);
      if (currentVisit.incompleteReason) {
        setSelectedIncompleteReason(incompleteReasonEntity.watotoCareEntityDetails.find(entity => entity.id === currentVisit.incompleteReason));
      }
    }
  }, [currentVisit, patientVisitMasterEntities, isNew, incompleteReasonEntity]);

  useEffect(() => {
    if (symptoms.length > 0) {
      const riskOrder = { [RiskProfile.HIGH]: 0, [RiskProfile.MED]: 1, [RiskProfile.LOW]: 2 };
      let filteredMaternalSymptoms = symptoms.filter(symptom => symptom.emergencyType === EmergencyType.MATERNAL && symptom.isActive)
        .sort((a, b) => {
          return riskOrder[a.riskProfile] - riskOrder[b.riskProfile];
        });
      let filteredNeonatalSymptoms = symptoms.filter(symptom => symptom.emergencyType === EmergencyType.NEONATAL && symptom.isActive)
        .sort((a, b) => {
          return riskOrder[a.riskProfile] - riskOrder[b.riskProfile];
        });
      setMaternalSymptoms(filteredMaternalSymptoms);
      setNeonatalSymptoms(filteredNeonatalSymptoms);

      let lastVisitNumber = 0;
      if (patientVisitEntities.length > 0) {
        let latestVisit = patientVisitEntities[patientVisitEntities.length - 1];

        if (getVisitCountParam()) {
          const visitCountParam = getVisitCountParam();
          if (visitCountParam < 1 || visitCountParam > patientVisitEntities.length) {
            alert('Invalid Visit Number Parameter');
            props.history.push('/patient');
            return;
          }
          setIsNew(false);
          latestVisit = patientVisitEntities[visitCountParam - 1];
          lastVisitNumber = visitCountParam === 1 ? 0 : patientVisitEntities[visitCountParam - 2].visitCount;
          setPreviousRiskProfile(latestVisit.riskProfile);
          setCurrentVisit(patientVisitEntities[visitCountParam - 1]);

          // append inactive symptoms also to maternal and neonatal symptoms
          // this is done to show symptoms which were selected but are inactive now
          const allMaternalSymptoms = symptoms.filter(symptom => symptom.emergencyType === EmergencyType.MATERNAL);
          const allNeonatalSymptoms = symptoms.filter(symptom => symptom.emergencyType === EmergencyType.NEONATAL);
          filteredMaternalSymptoms = [...allMaternalSymptoms];
          filteredNeonatalSymptoms = [...allNeonatalSymptoms];
          setMaternalSymptoms(filteredMaternalSymptoms);
          setNeonatalSymptoms(filteredNeonatalSymptoms);
        } else {
          lastVisitNumber = latestVisit.visitCount;
          setPreviousRiskProfile(latestVisit.riskProfile);
          setIsNew(true);
        }

        // filter baby symptoms based on previous visit risk profile and static symptoms logic
        const previousVisitBabySymptoms = latestVisit.babySymptoms
          .map(symptom => {
            return symptom.symptom.id;
          });
        const previousVisitBabyOverallRiskProfile = calculateOverallRiskProfile(previousVisitBabySymptoms);

        const staticBabySymptoms = latestVisit.babySymptoms
          .filter(symptom => symptom.symptom.isStatic === true)
          .map(symptom => {
            return symptom.symptom.id;
          });
        if (previousVisitBabyOverallRiskProfile === RiskProfile.HIGH) {
          if (staticBabySymptoms.length > 0) {
            filteredNeonatalSymptoms = filteredNeonatalSymptoms
              .filter(symptom => ((symptom.riskProfile === RiskProfile.HIGH || symptom.riskProfile === RiskProfile.MED) && symptom.isStatic === false) || staticBabySymptoms.includes(symptom.id));
          } else {
            filteredNeonatalSymptoms = filteredNeonatalSymptoms
              .filter(symptom => (symptom.riskProfile === RiskProfile.HIGH || symptom.riskProfile === RiskProfile.MED) && symptom.isStatic === false);
          }
        } else if (latestVisit.riskProfile === RiskProfile.MED) {
          filteredNeonatalSymptoms = filteredNeonatalSymptoms
            .filter(symptom => (symptom.riskProfile === RiskProfile.HIGH || symptom.riskProfile === RiskProfile.MED) && symptom.isStatic === false);
        } else if (watotocareSettings.allowLowRiskVisit === true && latestVisit.riskProfile === RiskProfile.LOW) {
          filteredNeonatalSymptoms = filteredNeonatalSymptoms
            .filter(symptom => (symptom.riskProfile === RiskProfile.HIGH || symptom.riskProfile === RiskProfile.MED || symptom.riskProfile === RiskProfile.LOW) && symptom.isStatic === false);
        } else if (latestVisit.riskProfile === RiskProfile.LOW) {
          filteredNeonatalSymptoms = [];
        }

        // filter mother symptoms based on previous visit risk profile and static symptoms logic
        const previousVisitMotherSymptoms = latestVisit.motherSymptoms
          .map(symptom => {
            return symptom.symptom.id;
          });
        const previousVisitMotherOverallRiskProfile = calculateOverallRiskProfile(previousVisitMotherSymptoms);
        const staticMotherSymptoms = latestVisit.motherSymptoms
          .filter(symptom => symptom.symptom.isStatic === true)
          .map(symptom => {
            return symptom.symptom.id;
          });
        if (previousVisitMotherOverallRiskProfile === RiskProfile.HIGH) {
          if (staticMotherSymptoms.length > 0) {
            filteredMaternalSymptoms = filteredMaternalSymptoms
              .filter(symptom => ((symptom.riskProfile === RiskProfile.HIGH || symptom.riskProfile === RiskProfile.MED) && symptom.isStatic === false) || staticMotherSymptoms.includes(symptom.id));
          } else {
            filteredMaternalSymptoms = filteredMaternalSymptoms
              .filter(symptom => (symptom.riskProfile === RiskProfile.HIGH || symptom.riskProfile === RiskProfile.MED) && symptom.isStatic === false);
          }
        } else if (latestVisit.riskProfile === RiskProfile.MED) {
          filteredMaternalSymptoms = filteredMaternalSymptoms
            .filter(symptom => (symptom.riskProfile === RiskProfile.HIGH || symptom.riskProfile === RiskProfile.MED) && symptom.isStatic === false);
        } else if (watotocareSettings.allowLowRiskVisit === true && latestVisit.riskProfile === RiskProfile.LOW) {
          filteredMaternalSymptoms = filteredMaternalSymptoms
            .filter(symptom => (symptom.riskProfile === RiskProfile.HIGH || symptom.riskProfile === RiskProfile.MED || symptom.riskProfile === RiskProfile.LOW) && symptom.isStatic === false);
        } else if (latestVisit.riskProfile === RiskProfile.LOW) {
          filteredMaternalSymptoms = [];
        }

        setSelectedData({
          babySymptomIds: [...previousVisitBabySymptoms],
          motherSymptomIds: [...previousVisitMotherSymptoms]
        });
        setPreviousVisitData({
          babySymptomIds: [...previousVisitBabySymptoms],
          motherSymptomIds: [...previousVisitMotherSymptoms]
        });
      }

      setVisitNumber(lastVisitNumber + 1);
      setCheckboxMaternalSymptoms(filteredMaternalSymptoms);
      setCheckboxNeonatalSymptoms(filteredNeonatalSymptoms);
    }
  }, [patientVisitEntities, symptoms, watotocareSettings]);

  useEffect(() => {
    if (props.updateSuccess) {
      props.reset();
      if (visitNumber === 1 && overallRiskProfile !== RiskProfile.LOW) {
        confirm('Triage Done! Do you want to schedule a follow-up visit?')
          ? props.history.push(`/patient-visit/journey?patientBabyId=${getPatientBabyId()}`)
          : handleClose();
      } else {
        handleClose();
      }
    } else {
      if (props.errorMessage) {
        alert(props.errorMessage);
        props.reset();
      }
    }
  }, [props.updateSuccess, props.errorMessage]);

  useEffect(() => () => {
    props.reset();
  }, []);

  const updateBabySymptoms = (e) => {
    e.persist();
    const symptomId = e?.target?.value;
    const updatedSelectedData = { ...selectedData };
    const babySymptomIds = updatedSelectedData.babySymptomIds;
    if (symptomId) {
      if (e.target.checked) {
        if (!babySymptomIds.includes(symptomId)) {
          babySymptomIds.push(symptomId);
        }
      } else {
        const i = babySymptomIds.indexOf(symptomId);
        if (i !== -1) {
          babySymptomIds.splice(i, 1);
        }
      }
      updatedSelectedData.babySymptomIds = babySymptomIds;
      setSelectedData(updatedSelectedData);
    }
  };

  const updateMotherSymptoms = (e) => {
    e.persist();
    const symptomId = e?.target?.value;
    const updatedSelectedData = { ...selectedData };
    const motherSymptomIds = updatedSelectedData.motherSymptomIds;
    if (symptomId) {
      if (e.target.checked) {
        if (!motherSymptomIds.includes(symptomId)) {
          motherSymptomIds.push(symptomId);
        }
      } else {
        const i = motherSymptomIds.indexOf(symptomId);
        if (i !== -1) {
          motherSymptomIds.splice(i, 1);
        }
      }
      updatedSelectedData.motherSymptomIds = motherSymptomIds;
      setSelectedData(updatedSelectedData);
    }
  };

  const getClassByRiskProfile = (riskProfile) => {
    switch (riskProfile) {
      case 'HIGH':
        return 'risk-high'; // Class for high risk
      case 'MED':
        return 'risk-medium'; // Class for medium risk
      case 'LOW':
        return 'risk-low'; // Class for low risk
      default:
        return ''; // Default class
    }
  };

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0 && (selectedData.babySymptomIds.length > 0 || selectedData.motherSymptomIds.length > 0) && isNew) {
      const patientBabyId = getPatientBabyId();
      const journeyId = getJourneyId();
      if (patientVisitEntities.length > 0 && !journeyId) {
        alert('Invalid Journey Id');
        props.history.push('/patient');
      }
      const babySymptoms = symptoms.filter(symptom => selectedData.babySymptomIds.includes(symptom.id));
      const motherSymptoms = symptoms.filter(symptom => selectedData.motherSymptomIds.includes(symptom.id));

      const entity = {
        ...patientVisitEntities,
        ...values,
        patientBabyId,
        journeyId,
        patientVisitEntityIds: entityFormSelectedValues,
        patientCaseId: patientBabyEntity.patientCase.id,
        babySymptomIds: babySymptoms.map(symptom => symptom.id),
        motherSymptomIds: motherSymptoms.map(symptom => symptom.id),
        riskProfile: overallRiskProfile
      };

      props.createEntity(entity);
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          {visitNumber !== null && <h2 id="watotocareApp.patient.baby.home.createOrEditLabel">
            {visitNumber > 1 ? 'Risk Reassessment For Follow-Up Visit #' + (visitNumber - 1) : 'Newborn Triage'}
          </h2>
          }
        </Col>
      </Row>
      <br />
      <Row className="justify-content-center">
        <Col md="4">
          <h6 className="text-primary">Mother Details</h6>
          <div>
            watotoCare ID: {patientEntity.wcId}
            <br />
            Mother Name: {`${patientEntity.firstName} ${patientEntity.lastName}`}
          </div>
        </Col>
        <Col md="4">
          <h6 className="text-primary">Baby Details</h6>
          <div>
            Date of Birth: {patientBabyEntity.birthDate} (Age in Days: {daysSince(patientBabyEntity.birthDate)})
            <br />
            Baby
            Name: {patientBabyEntity.firstName ? `${patientBabyEntity.firstName} ${patientBabyEntity.lastName}` : '-'}
          </div>
        </Col>
      </Row>
      <br />
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm onSubmit={saveEntity}>
              {visitNumber !== 1 &&
                <Row className="justify-content-left">
                  <Col md="12">
                    <AvGroup>
                      <Label id="followup-completed-label" for="followup-completed">
                        Was the Follow-Up Completed?
                      </Label>
                      <ButtonGroup style={{ marginLeft: '30px' }}>
                        <Button
                          color={followupCompleted === true ? 'primary' : 'secondary'}
                          disabled={!isNew}
                          onClick={() => {
                            setFollowupCompleted(true);
                            if (followupCompleted === false) {
                              // set entity values to empty if followup is completed
                              setEntityFormInitialValues([]);
                            }
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          color={followupCompleted === false ? 'primary' : 'secondary'}
                          disabled={!isNew}
                          onClick={() => {
                            setFollowupCompleted(false);
                            // set selected data to previous visit data if followup is not completed
                            setSelectedData({
                              babySymptomIds: [...previousVisitData.babySymptomIds],
                              motherSymptomIds: [...previousVisitData.motherSymptomIds]
                            });
                            // set entity values for previous patient visit if it exists
                            if (patientVisitEntities.length > 0) {
                              setEntityValues(patientVisitEntities[patientVisitEntities.length - 1]);
                            }
                          }}
                        >
                          No
                        </Button>
                      </ButtonGroup>
                    </AvGroup>
                  </Col>

                  {followupCompleted === false &&
                    <>
                      {/* Patient Visit Incomplete Follow-Up Reason */}
                      {incompleteReasonEntity && incompleteReasonEntity?.watotoCareEntityDetails?.length > 0 &&
                        <>
                          <Col md="6" className="mt-3">
                            <AvGroup>
                              <Label id="followup-incomplete-reason-label" for="followup-incomplete-reason">
                                {incompleteReasonEntity.entityName}
                              </Label>
                              <AvInput
                                id="followup-incomplete-reason"
                                type="select"
                                name="incompleteReason"
                                value={currentVisit?.incompleteReason}
                                onChange={(e) => {
                                  if (e.target.value && e.target.value !== '') {
                                    setSelectedIncompleteReason(incompleteReasonEntity.watotoCareEntityDetails.find(entity => entity.id === e.target.value));
                                  }
                                }}
                              >
                                <option disabled selected value="">-- select an option --</option>
                                {incompleteReasonEntity.watotoCareEntityDetails
                                  .filter(entity => entity.isActive)
                                  .sort((a, b) => a.displayOrder - b.displayOrder)
                                  .map(entity => (
                                    <option value={entity.id} key={entity.id}>
                                      {entity.enDescription}
                                    </option>
                                  ))}
                              </AvInput>
                            </AvGroup>
                          </Col>

                          {/* Follow-Up Incomplete Reason Comment */}
                          {selectedIncompleteReason && selectedIncompleteReason.displayOrder === 99 &&
                            <Col md="6" className="mt-3">
                              <AvGroup>
                                <AvField
                                  id="followup-incomplete-reason-comment"
                                  type="text"
                                  name="incompleteReasonComment"
                                  value={currentVisit?.incompleteReasonComment}
                                  label="If Other, Please Specify"
                                  validate={{
                                    required: { value: true, errorMessage: 'This field is required.' }
                                  }}
                                />
                              </AvGroup>
                            </Col>
                          }
                        </>
                      }
                    </>
                  }
                  {followupCompleted === false &&
                    <Col md="12" className="mt-3">
                      <h5 className="text-danger">Bypassing the follow-up will carry forward all existing
                        conditions</h5>
                    </Col>
                  }
                </Row>
              }

              {(followupCompleted !== null || visitNumber === 1) &&
                <div className="mt-3">
                  <Row className="justify-content-left">
                    {(watotocareSettings.allowLowRiskVisit || previousRiskProfile !== RiskProfile.LOW) && isNew &&
                      <Col md="6">
                        <AvGroup>
                          <h6 className="text-primary">Mother Conditions</h6>
                          <AvCheckboxGroup
                            id="mother-symptoms"
                            name="symptomsId"
                            className="form-control"
                            style={{ overflowY: 'auto', height: (visitNumber > 1 && isNew) ? '200px' : '350px' }}
                            value={selectedData.motherSymptomIds}
                          >
                            {checkboxMaternalSymptoms.length > 0 && checkboxMaternalSymptoms.map(item => (
                              <AvCheckbox
                                value={item.id}
                                key={item.enCondition}
                                label={item.enCondition}
                                onChange={updateMotherSymptoms}
                                checked={selectedData.motherSymptomIds.includes(item.id)}
                                disabled={!isNew || followupCompleted === false || (visitNumber !== 1 && previousVisitData.motherSymptomIds.includes(item.id) && item.isStatic)}
                                className={getClassByRiskProfile(item.riskProfile)} />
                            ))}
                          </AvCheckboxGroup>
                          { /* {errorObj && <FormText color="danger" >{errorObj.symptomsId}</FormText>} */}
                        </AvGroup>
                      </Col>}
                    {(visitNumber !== 1 || !isNew) && <Col md="6">
                      <h6 className="text-primary">{isNew ? 'Previous ' : ''}Mother Conditions</h6>
                      <br />
                      {maternalSymptoms.length > 0 && maternalSymptoms.map(item => (
                        previousVisitData.motherSymptomIds.includes(item.id) &&
                        <div key={item.enCondition}>
                          <FontAwesomeIcon icon="minus" fixedWidth /> &nbsp; {item.enCondition}
                        </div>
                      ))}
                    </Col>
                    }
                    {(watotocareSettings.allowLowRiskVisit || previousRiskProfile !== RiskProfile.LOW) && isNew &&
                      <Col md="6">
                        <AvGroup>
                          <h6 className="text-primary">Baby Conditions</h6>
                          <AvCheckboxGroup
                            id="baby-symptoms"
                            name="symptomsId"
                            className="form-control"
                            style={{ overflowY: 'auto', height: (visitNumber > 1 && isNew) ? '200px' : '350px' }}
                            value={selectedData.babySymptomIds}
                          >
                            {checkboxNeonatalSymptoms.length > 0 && checkboxNeonatalSymptoms.map(item => (
                              <AvCheckbox
                                value={item.id}
                                key={item.enCondition}
                                label={item.enCondition}
                                onChange={updateBabySymptoms}
                                checked={selectedData.babySymptomIds.includes(item.id)}
                                disabled={!isNew || followupCompleted === false || (visitNumber !== 1 && previousVisitData.babySymptomIds.includes(item.id) && item.isStatic)}
                                className={getClassByRiskProfile(item.riskProfile)}
                              />
                            ))}
                          </AvCheckboxGroup>
                          { /* {errorObj && <FormText color="danger" >{errorObj.symptomsId}</FormText>} */}
                        </AvGroup>
                      </Col>}
                    {(visitNumber !== 1 || !isNew) && <Col md="6">
                      <h6 className="text-primary">{isNew ? 'Previous ' : ''}Baby Conditions</h6>
                      <br />
                      {neonatalSymptoms.length > 0 && neonatalSymptoms.map(item => (
                        previousVisitData.babySymptomIds.includes(item.id) &&
                        <div key={item.enCondition}>
                          <FontAwesomeIcon icon="minus" fixedWidth /> &nbsp; {item.enCondition}
                        </div>
                      ))}
                    </Col>
                    }
                  </Row>

                  <br />

                  <Row className="justify-content-left mt-3 mb-3">
                    {(!watotocareSettings.allowLowRiskVisit && previousRiskProfile === RiskProfile.LOW) ?
                      <h2 className="text-center text-success">Low Risk: Follow-up Not Needed</h2>
                      :
                      <>
                        <Col md="4"></Col>
                        <Col md="4" className="justify-content-center">
                          <h2 className="text-center">Risk Profile: <span
                            className="text-danger">{overallRiskProfile}</span></h2>
                        </Col>
                      </>
                    }
                  </Row>

                  {(watotocareSettings.allowLowRiskVisit || previousRiskProfile !== RiskProfile.LOW) &&
                    visitNumber !== 1 && patientVisitMasterEntities && patientVisitMasterEntities.length > 0 &&
                    <WatotocareEntityFormGroup
                      prefix="patient-visit"
                      entities={patientVisitMasterEntities}
                      initialValues={entityFormInitialValues}
                      onChange={(values) => setEntityFormSelectedValues(values)}
                      disabled={!isNew || followupCompleted === false}
                    />
                  }
                </div>
              }
              {(watotocareSettings.allowLowRiskVisit || previousRiskProfile !== RiskProfile.LOW) && (
                <>
                  <br />
                  <Button tag={Link} id="cancel-save" to="/patient" replace color="info">
                    <FontAwesomeIcon icon="arrow-left" />
                    &nbsp;
                    <span className="d-none d-md-inline">Back</span>
                  </Button>
                  &nbsp;
                  {isNew &&
                    <Button color="primary" id="save-entity" type="submit"
                            disabled={updating || (selectedData.babySymptomIds.length === 0 && selectedData.motherSymptomIds.length === 0)}>
                      <FontAwesomeIcon icon="save" />
                      &nbsp; Save
                    </Button>
                  }
                </>
              )}
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  patientBabyEntity: storeState.patientBaby.entity,
  patientEntity: storeState.patient.entity,
  patientVisitEntities: storeState.patientVisit.entities,
  loading: storeState.patientVisit.loading,
  updating: storeState.patientVisit.updating,
  updateSuccess: storeState.patientVisit.updateSuccess,
  errorMessage: storeState.patientVisit.errorMessage,
  symptoms: storeState.symptom.entities,
  watotocareSettings: storeState.watotocareSettings.settingsDetail,
  watotoCareEntitiesByGroup: storeState.watotoCareEntity.masterEntitiesByGroup,
  watotoCareEntityList: storeState.watotoCareEntity.masterEntities
});

const mapDispatchToProps = {
  getEntities,
  getPatient,
  getPatientBaby,
  createEntity,
  reset,
  getSymptoms,
  getWatotoCareSettingsByCountryID,
  getMasterEntitiesByGroupNames,
  getMasterEntitiesByEnumNames
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientVisitUpdate);
