import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Spinner, Table } from 'reactstrap';
import axios from 'axios';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';

interface IJourneyScheduleModalProps {
  isOpen: boolean;
  toggle: () => void;
  countryId: string;
  driverId?: string;
  physicianId?: string;
  scheduledDate: string;
}

const JourneyScheduleModal = (props: IJourneyScheduleModalProps) => {
  const { isOpen, toggle, countryId, driverId, physicianId, scheduledDate } = props;
  const [loading, setLoading] = useState(false);
  const [modalJourneys, setModalJourneys] = useState([]);

  const fetchJourneys = async () => {
    setLoading(true);
    const filter = {
      countryId,
      scheduledDate,
      ...(driverId ? { driverId } : { physicianId }),
      upcomingOnly: true,
      page: 0,
      size: 100,
      sort: 'scheduledSlotStart,asc'
    };
    const response = await axios.get(`/api/journeys?${Object.keys(filter).map(key => `${key}=${filter[key]}`).join('&')}`);
    const data = await response.data;
    setModalJourneys(data);
    setLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      fetchJourneys();
    } else {
      setModalJourneys([]);
    }
  }, [isOpen]);


  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{driverId ? 'Driver' : 'Physician'} Journeys Scheduled
        for {scheduledDate}</ModalHeader>
      <ModalBody>
        {loading ?
          <Spinner />
          :
          <Table>
            <thead>
            <tr>
              <th>Journey Type</th>
              {driverId &&
                <>
                  <th>Driver Home Village</th>
                  <th>Start Village</th>
                </>
              }
              <th>Healthcare Facility</th>
              <th>Scheduled Slot Start</th>
              <th>Scheduled Slot End</th>
            </tr>
            </thead>
            <tbody>
            {modalJourneys.length > 0 ? modalJourneys.map((journey) => (
                <tr key={journey.id}>
                  <td>{journey.type}</td>
                  {driverId &&
                    <>
                      <td>{journey.driver?.homeVillage?.name}</td>
                      <td>{journey.startVillage?.name}</td>
                    </>
                  }
                  <td>{journey.endHealthcareFacility?.name ?? '-'}</td>
                  <td>{convertDateTimeFromServer(journey.scheduledSlotStart)}</td>
                  <td>{convertDateTimeFromServer(journey.scheduledSlotEnd)}</td>
                </tr>
              ))
              :
              <tr>
                <td colSpan={4}>No Journeys Scheduled</td>
              </tr>
            }
            </tbody>
          </Table>
        }
      </ModalBody>
    </Modal>
  );
};


export default JourneyScheduleModal;
