import { ISymptom } from 'app/shared/model/symptom.model';
import { IJourney } from 'app/shared/model/journey.model';
import { IPatient } from 'app/shared/model/patient.model';
import { CaseType } from 'app/shared/model/enumerations/case-type.model';
import { CaseStatus } from 'app/shared/model/enumerations/case-status.model';
import { IPatientBaby } from 'app/shared/model/patient-baby.model';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';


export interface IPatientCase {
  emergency?: boolean;
  bypass?: boolean;
  activated?: any;
  id?: string;
  type?: CaseType;
  caseStatus?: CaseStatus;
  createdAt?: Date;
  motherSymptoms?: ISymptom[];
  babySymptoms?: ISymptom[];
  motherFollowupId?: any;
  babyFollowupId?: any;
  motherConditionId?: any;
  babyConditionId?: any;
  journeys?: IJourney[];
  patient?: IPatient;
  patientBaby?: IPatientBaby;
  patientId?: string;
  patientBabyId?: string;
  patientIsAlive?: boolean;
  patientBabyIsAlive?: boolean;
  patientHasHiv?: boolean;
  babyDischargedAt?: Date;
  babyDischargeCondition?: string;
  babyDischargeConditionEntity?: IWatotoCareEntityDetail;
  babyDischargeNotes?: string;
  patientDischargeNotes?: string;
  outcomeAttempts?: number;
  outcomeCallDate?: Date;
  patientDischargeEntities?: {
    entityDetailId?: string;
    entityDetail?: IWatotoCareEntityDetail;
    comment?: string;
  }[];
}

export const defaultValue: Readonly<IPatientCase> = {};
