import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { createEntity, getEntity, reset, updateEntity } from './patient-baby.reducer';
import { getEntity as getPatient, reset as resetPatient } from 'app/entities/patient/patient.reducer';
import {
  getMasterEntitiesByEnumNames,
  getMasterEntitiesByGroupNames,
  reset as resetWatotoCareEntities
} from 'app/entities/watotocare-entity/watotocare-entity.reducer';
import { IWatotoCareEntityDetail } from 'app/shared/model/watotocare-entity-detail.model';
import { IWatotoCareEntityMaster } from 'app/shared/model/watotocare-entity-master.model';
import { IWatotoCareEntityDto } from 'app/shared/model/watotocare-entity-dto.model';
import WatotocareEntityFormGroup from 'app/shared/components/watotocare-entity-form/watotocare-entity-form-group';

export interface IPatientBabyUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{
  parentId: string,
  id: string
}> {
}

export const PatientBabyUpdate = (props: IPatientBabyUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const {
    patientBabyEntity,
    patientEntity,
    watotoCareEntityList,
    watotoCareEntitiesByGroup,
    loading,
    updating
  } = props;
  const [birthLocation, setBirthLocation] = useState(patientBabyEntity.birthLocation);
  const [outbornType, setOutbornType] = useState(patientBabyEntity.outbornType);
  const [gestationTermEntity, setGestationTermEntity] = useState([] as IWatotoCareEntityDetail[]);
  const params = new URLSearchParams(props.location.search);
  const [pointOfEnrollment, setPointOfEnrollment] = useState(null);
  const [pointOfEnrollmentEntity, setPointOfEnrollmentEntity] = useState<IWatotoCareEntityMaster>(null);
  const [patientBabyEnrollmentMasterEntities, setPatientBabyEnrollmentMasterEntities] = useState<IWatotoCareEntityMaster[]>([]);
  const [entityFormInitialValues, setEntityFormInitialValues] = useState<IWatotoCareEntityDto[]>([]);
  const [entityFormSelectedValues, setEntityFormSelectedValues] = useState<IWatotoCareEntityDto[]>([]);
  const birthLocations = ['INBORN', 'OUTBORN'];
  const outbornTypes = ['OTHER_FACILITY', 'HOME', 'ON_TRANSIT'];
  const WATOTOCARE_ENTITY_ENUM_NAMES = ['BABY_GESTATION_TERM', 'POINT_OF_ENROLLMENT'];
  const WATOTOCARE_ENTITY_GROUP_NAME = 'PATIENT_BABY_ENROLLMENT';

  useEffect(() => {
    document.getElementById('app-view-container').scroll(0, 0);
    if (isNew) {
      props.reset();
      props.getPatient(params.get('parentId'));
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (!isNew) {
      if (patientBabyEntity.patientId) {
        props.getPatient(patientBabyEntity.patientId);
      }
      setBirthLocation(patientBabyEntity.birthLocation);
      setOutbornType(patientBabyEntity.outbornType);
    }
  }, [patientBabyEntity]);

  useEffect(() => {
    if (patientEntity.id) {
      props.getMasterEntitiesByEnumNames(WATOTOCARE_ENTITY_ENUM_NAMES, patientEntity.countryId ? patientEntity.countryId : props.userCountryId);
      props.getMasterEntitiesByGroupNames([WATOTOCARE_ENTITY_GROUP_NAME], patientEntity.countryId ? patientEntity.countryId : props.userCountryId);
    }
  }, [patientEntity]);

  useEffect(() => {
    if (watotoCareEntityList.length > 0) {
      const gestationTermEntityMaster = watotoCareEntityList.find(entity => entity.enumName === 'BABY_GESTATION_TERM');
      if (gestationTermEntityMaster) {
        setGestationTermEntity(gestationTermEntityMaster.watotoCareEntityDetails
          .filter(entity => entity.isActive)
          .sort((a, b) => a.displayOrder - b.displayOrder));
      }

      const pointOfEnrollmentMasterEntity = watotoCareEntityList.find(entity => entity.enumName === 'POINT_OF_ENROLLMENT');
      if (pointOfEnrollmentMasterEntity) {
        setPointOfEnrollmentEntity(pointOfEnrollmentMasterEntity);
      }
    }
  }, [watotoCareEntityList]);

  useEffect(() => {
    if (patientBabyEntity.id) {
      setEntityFormInitialValues(patientBabyEntity.patientBabyEnrollmentEntities);
    }
  }, [patientBabyEntity]);

  useEffect(() => {
    if (Object.keys(watotoCareEntitiesByGroup).length > 0) {
      setPatientBabyEnrollmentMasterEntities(watotoCareEntitiesByGroup[WATOTOCARE_ENTITY_GROUP_NAME]);
    }
  }, [watotoCareEntitiesByGroup]);

  useEffect(() => {
    if (props.updateSuccess) {
      if (isNew) {
        props.history.push(`/patient-baby/${patientBabyEntity.id}/discharge`);
      } else {
        props.history.push('/patient');
      }
    }
  }, [props.updateSuccess]);

  const birthLocationChanged = (event) => {
    const value = event.target.value;
    setBirthLocation(value);
    if (value.toLowerCase() !== 'outborn') {
      setOutbornType(null);
    }
  };

  const saveEntity = (event, errors, values) => {
    let patientId = patientBabyEntity?.patientId;
    if (isNew) {
      patientId = params.get('parentId');
    }


    if (errors.length === 0) {
      const entity = {
        ...patientBabyEntity,
        ...values,
        patientId,
        outbornType,
        patientBabyEnrollmentEntityIds: entityFormSelectedValues
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  useEffect(() => () => {
    props.reset();
    props.resetPatient();
    props.resetWatotoCareEntities();
    setPatientBabyEnrollmentMasterEntities([]);
    setEntityFormInitialValues([]);
    setEntityFormSelectedValues([]);
  }, []);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="watotocareApp.patient.baby.home.createOrEditLabel">
            {isNew ? <>Add Baby Details</> : <>Edit Baby Details [
              <b>
                {patientEntity?.firstName + ' ' + patientEntity?.lastName + ' - ' + patientEntity?.wcId}
              </b>
              ]</>}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : patientBabyEntity} onSubmit={saveEntity}>

              <div className="mt-3">
                <Row className="justify-content-left">
                  <Col md="6">
                    <AvGroup>
                      <Label id="firstName" for="patient-baby-firstName">
                        First Name
                      </Label>
                      <AvField
                        id="patient-baby-firstName"
                        type="text"
                        name="firstName"
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="lastName" for="patient-baby-lastName">
                        Last Name
                      </Label>
                      <AvField
                        id="patient-baby-lastName"
                        type="text"
                        name="lastName"
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="sex" for="patient-baby-sex">
                        Sex
                      </Label>
                      <AvInput
                        id="patient-baby-sex"
                        type="select"
                        name="sex"
                        value={(patientBabyEntity.sex) || ''}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      >
                        <option disabled selected value="">-- select an option --</option>
                        <option value="F">Female</option>
                        <option value="M">Male</option>
                      </AvInput>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="birthWeight" for="patient-baby-birthWeight">
                        Birth Weight (kg)
                      </Label>
                      <AvField
                        id="patient-baby-birthWeight"
                        type="number"
                        min={0}
                        name="birthWeight"
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="gestationTerm" for="patient-baby-gestationTerm">
                        Gestation Term
                      </Label>
                      <AvInput
                        id="patient-baby-gestationTerm"
                        type="select"
                        name="gestationTerm"
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      >
                        <option disabled selected value="">-- select an option --</option>
                        {gestationTermEntity.map(val => (
                          <option value={val.id} key={val.id}>
                            {val.enDescription}
                          </option>
                        ))}
                      </AvInput>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="birthDate" for="patient-baby-birthDate">
                        Date of Birth
                      </Label>
                      <AvField
                        id="patient-baby-birthDate"
                        type="date"
                        name="birthDate"
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="birthCertificateNumber" for="patient-baby-birthCertificateNumber">
                        Newborn Birth Certificate Registration Number
                      </Label>
                      <AvField
                        id="patient-baby-birthCertificateNumber"
                        type="text"
                        name="birthCertificateNumber"
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label for="patient-baby-birthLocation">Birth Location</Label>
                      <AvInput
                        id="patient-birthLocation"
                        type="select"
                        className="form-control"
                        name="birthLocation"
                        onChange={birthLocationChanged}
                        value={patientBabyEntity.birthLocation}
                        validate={{
                          required: { value: true, errorMessage: 'This field is required.' }
                        }}
                      >
                        {isNew && <option disabled selected value="">-- select an option --</option>}
                        {birthLocations.map(val => (
                          <option value={val} key={val}>
                            {val}
                          </option>
                        ))}
                      </AvInput>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label for="patient-baby-outbornType">Specify Outborn</Label>
                      <AvInput
                        id="patient-outbornType"
                        type="select"
                        className="form-control"
                        name="outbornType"
                        disabled={birthLocation?.toLowerCase() !== 'outborn'}
                        onChange={e => {
                          setOutbornType(e.target.value);
                        }}
                        validate={birthLocation?.toLowerCase() === 'outborn' ? {
                          required: { value: true, errorMessage: 'This field is required.' }
                        } : {}}
                        value={outbornType}
                      >
                        {(isNew || birthLocation?.toLowerCase() !== 'outborn' || !outbornType) &&
                          <option disabled selected
                                  value="">{birthLocation?.toLowerCase() === 'outborn' ? '--select an option --' : ''}</option>
                        }
                        {outbornTypes.map(val => (
                          <option value={val} key={val}>
                            {val}
                          </option>
                        ))}
                      </AvInput>
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="outbornComment" for="patient-baby-outbornComment">
                        Name of Other Facility
                      </Label>
                      <AvField
                        id="patient-baby-outbornComment"
                        type="text"
                        name="outbornComment"
                        disabled={birthLocation?.toLowerCase() !== 'outborn' || outbornType !== 'OTHER_FACILITY'}
                        validate={birthLocation?.toLowerCase() === 'outborn' && outbornType === 'OTHER_FACILITY' ? {
                          required: { value: true, errorMessage: 'This field is required.' }
                        } : {}}
                        // placeholder={birthLocation?.toLowerCase() === 'outborn' && outbornType === 'OTHER_FACILITY' ? 'e.g. please enter a reason if OTHER_FACILITY is selected' : ''}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="admissionDate" for="patient-baby-admissionDate">
                        Date of Admission
                      </Label>
                      <AvField
                        id="patient-baby-admissionDate"
                        type="date"
                        name="admissionDate"
                        value={birthLocation?.toLowerCase() === 'outborn' ? patientBabyEntity.admissionDate : ''}
                        disabled={birthLocation?.toLowerCase() !== 'outborn'}
                        validate={birthLocation?.toLowerCase() === 'outborn' ? {
                          required: { value: true, errorMessage: 'This field is required.' }
                        } : {}}
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="admissionWeight" for="patient-baby-admissionWeight">
                        Weight on Admission (kg)
                      </Label>
                      <AvField
                        id="patient-baby-admissionWeight"
                        type="number"
                        min={0}
                        name="admissionWeight"
                        value={birthLocation?.toLowerCase() === 'outborn' ? patientBabyEntity.admissionWeight : ''}
                        disabled={birthLocation?.toLowerCase() !== 'outborn'}
                        validate={birthLocation?.toLowerCase() === 'outborn' ? {
                          required: { value: true, errorMessage: 'This field is required.' }
                        } : {}}
                      />
                    </AvGroup>
                  </Col>

                  {/* Point of Enrollment */}
                  {pointOfEnrollmentEntity !== null &&
                    <>
                      <Col md="6">
                        <AvGroup>
                          <AvField
                            type="select"
                            id={'patient-baby-pointOfEnrollment'}
                            name="pointOfEnrollment"
                            label={pointOfEnrollmentEntity.entityName}
                            validate={{
                              required: { value: true, errorMessage: 'This field is required.' }
                            }}
                            onChange={(e) => {
                              if (e.target.value && e.target.value !== '') {
                                const selected = pointOfEnrollmentEntity.watotoCareEntityDetails.find((entity: IWatotoCareEntityDetail) => entity.id === e.target.value);
                                setPointOfEnrollment(selected);
                              }
                            }}
                          >
                            <option disabled selected value="">-- select an option --</option>
                            {pointOfEnrollmentEntity?.watotoCareEntityDetails
                              .filter((entity: IWatotoCareEntityDetail) => entity.isActive)
                              .sort((a: IWatotoCareEntityDetail, b: IWatotoCareEntityDetail) => a.displayOrder - b.displayOrder)
                              .map((reason: IWatotoCareEntityDetail, j: number) => (
                                <option key={`pointOfEnrollment-${j}`} value={reason.id}>
                                  {reason.enDescription}
                                </option>
                              ))}
                          </AvField>
                        </AvGroup>
                      </Col>

                      {/* Point of Enrollment Comment */}
                      {pointOfEnrollment && pointOfEnrollment.displayOrder === 99 &&
                        <Col md="6">
                          <AvGroup>
                            <AvField
                              type="text"
                              id={'patient-baby-pointOfEnrollmentComment'}
                              name="pointOfEnrollmentComment"
                              label="Comment"
                              validate={{
                                required: { value: true, errorMessage: 'This field is required.' }
                              }}
                            />
                          </AvGroup>
                        </Col>
                      }
                    </>
                  }

                  <Col md="6">
                    <AvGroup>
                      <Label id="temperature" for="patient-baby-temperature">
                        Temperature on Admission (°C)
                      </Label>
                      <AvField
                        id="patient-baby-temperature"
                        type="number"
                        min={0}
                        step={0.1}
                        name="temperature"
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="oxygenLevel" for="patient-baby-oxygenLevel">
                        O2 Saturation on Admission (%)
                      </Label>
                      <AvField
                        id="patient-baby-oxygenLevel"
                        type="number"
                        min={0}
                        step={0.1}
                        name="oxygenLevel"
                      />
                    </AvGroup>
                  </Col>
                  <Col md="6">
                    <AvGroup>
                      <Label id="bloodSugarLevel" for="patient-baby-bloodSugarLevel">
                        Blood Sugar on Admission (mg/dL)
                      </Label>
                      <AvField
                        id="patient-baby-bloodSugarLevel"
                        type="number"
                        min={0}
                        step={0.1}
                        name="bloodSugarLevel"
                      />
                    </AvGroup>
                  </Col>

                  <WatotocareEntityFormGroup
                    prefix="patient-baby-enrollment"
                    entities={patientBabyEnrollmentMasterEntities}
                    initialValues={entityFormInitialValues}
                    onChange={(values) => setEntityFormSelectedValues(values)}
                  />
                </Row>
              </div>

              <br />
              <Button tag={Link} id="cancel-save" to="/patient" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Save
              </Button>
              {!isNew && (
                <>
                  &nbsp;
                  <Button tag={Link} id="discharge-link" to={`/patient-baby/${patientBabyEntity.id}/discharge`} replace
                          color="info">
                    <FontAwesomeIcon icon="arrow-right" />
                    &nbsp; Discharge
                  </Button>
                </>
              )}
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  patientBabyEntity: storeState.patientBaby.entity,
  patientEntity: storeState.patient.entity,
  loading: storeState.patientBaby.loading,
  updating: storeState.patientBaby.updating,
  updateSuccess: storeState.patientBaby.updateSuccess,
  userCountryId: storeState.authentication.userCountryId,
  watotoCareEntityList: storeState.watotoCareEntity.masterEntities,
  watotoCareEntitiesByGroup: storeState.watotoCareEntity.masterEntitiesByGroup
});

const mapDispatchToProps = {
  getEntity,
  getPatient,
  updateEntity,
  createEntity,
  reset,
  resetPatient,
  resetWatotoCareEntities,
  getMasterEntitiesByEnumNames,
  getMasterEntitiesByGroupNames
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PatientBabyUpdate);
